/*
Carousel Version 4

Markup: carousel-v4.hbs

Styleguide Carousel.Carousel Version 4
*/


.ay-hp-carousel-v4 {
  display: flex;
  flex-direction: column;
  height: calc(95vh - 142px);
  justify-content: center;
  max-width: $desktop-large;
  margin: auto;
  max-height: 570px;
  min-height: 570px;
  position: relative;
  @media screen and (max-width: 768px) {
    align-items: center;
  }
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
  .ay-hp-caroysel-v4--backgrounds {
    height: calc(95vh - 142px);
    max-height: 570px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    li {
      background-size: cover;
      background-position: center;
      height: calc(95vh - 142px);
      left: 0;
      opacity: 0;
      max-height: 570px;
      min-height: 570px;
      position: absolute;
      top: 0;
      transition: opacity .5s;
      width: 100%;
      z-index: 1;
      &.active{
        opacity: 1;
        z-index: 2;
        a {
          display: block;
        }
      }
      a {
        display: none;
        &.ay-cs-edit-link { /* for liferay edit link on slides */
          display: block;
          position: absolute;
          background-color: $ay-white;
          color: $ay-black;
          right: 20px;
          bottom:30px;
          border: 1px solid $ay-black;
          padding: 5px;
        }
      }
      video {
        height: 100%;
        object-fit: cover;
        width: 100%;
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
  }
  .ay-hp-caroysel-v4--holder {
    display: flex;
    flex-direction: column;
    height: 90%;
   // min-height: 575px;
    width: 515px;
    @media screen and (max-width: 768px) {
      width: 410px;
    }
    z-index: 3;
    /* @media screen and (max-width: 600px) {
      width: 100%;
    }*/
   .ay-hp-caroysel-v4--holder__nav {
     background: $ay-white;
      display: flex;
      margin-left: 105px;
     // padding-top: 16px;
     padding-bottom: 30px;
      &:empty {
        display: none;
      }
      @media screen and (max-width: 768px) {
        justify-content: center;
        margin-left: 0;
      }
      li {
        height: 32px;
        margin-left: 17px;
        width: 32px;
        &:first-child {
          margin-left: 34px;
          @media screen and (max-width: 768px) {
            margin-left: 0;
          }
        }
        &.active a {
          background: $ay-black;
        }
        a{
          background: rgba(126,156,209,0);
          border: 4px solid $ay-black;
          display: block;
          height: 100%;
          transition: all .5s;
          width: 100%;
          &:hover {
            background: $ay-black;
          }
        }
      }
    }
  }
  .ay-hp-caroysel-v4--holder-rail {
    background: $ay-white;

    color: $ay-black;
    margin-left: 105px;
    overflow: hidden;
    padding: 10px 0;//10px 35px;
    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
   /* @media screen and (max-width: 600px) {
      margin-left: 15px;
      margin-right: 15px;
    } */
    .ay-hp-caroysel-v4--slide-title {
      font-family: $header-font;
      align-items: flex-end;
      display: flex;
      font-family: $header-font;
      font-size: 32.67px;
      height: 130px;

      @media screen and (max-width: 768px) {
        text-align: center;
      }
      width: 100%;
    }
    .ay-hp-caroysel-v4--slide-content{
      align-self: flex-start;
      font-size: 24.53px;
      margin-top: 38.4px;
      width: 100%;
      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }
    .ay-slide-v4 {
      align-items: stretch;
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 464px;
      position: relative;
      justify-content: flex-start;

      &:before {
        border-top: 9px solid $ay-white;//#EF6528;
        content: "";
        display: block;
        width: calc(100% + 0px);
        height: 1px;
        left: 100px;
        position: absolute;
        top: -10px;
        transition: all .5s;

      }
      &.active:before{
        left: 0;
        width: calc(100% + 35px);
      }
      &:first-child:before{
        left: 0;
      }
      &.ay-slide-v4-noborder:before {
        border-top: 9px solid transparent;
      }
    }
    .ay-hp-caroysel-v4--slide-cta-holder {
      margin: auto 0 63px;
      @media screen and (min-height: 790px) {
         margin: auto 0 63px;
      }
    }
    .ay-hp-caroysel-v4--holder__content{
      display: flex;
      width: calc((515px) * 8);
      transition: all .5s;
      &.noAnimation {
        transition: none;
      }

      li {
       // margin-left: 34px;
       // padding-left: 34px;
       // padding-right: 34px;
        width: 375px;
        /* @media screen and (max-width: 768px) {
          width:481px;
        } */
        .ay-hp-caroysel-v4--slide-title,
        .ay-hp-caroysel-v4--slide-content,
        .ay-hp-caroysel-v4--slide-cta-holder {
          margin-left: 35px;
          margin-right: 35px;
          max-width: calc(100% - 35px);
          width: 100%;
          @media screen and (max-width: 768px) {
            text-align: center;
          }
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
    .ay-hp-caroysel-v4--holder__content
    .ay-hp-caroysel-v4--slide-cta {
      background: transparent;
      border: 2px solid $ay-black;
      color: $ay-black;
      font-size: 20.53px;
      padding: 10px;
      text-decoration: none;
      text-transform: uppercase;
      transition: all .5s;

      &:hover {
        background: $ay-black;
        color: $ay-white;
      }
    }
  }
  &.rtl {

    .ay-hp-caroysel-v4--holder{
      margin-left: 0;
      margin-right: 105px;
      .ay-hp-caroysel-v4--holder__nav {
        li:first-child {
          margin-left: 17px;
          margin-right: 34px;
        }
      }
    }
    .ay-hp-carousel-v4.rtl .ay-hp-caroysel-v4--holder {
      margin-left: unset;
      align-self: end;
      margin-right: unset;
  }
  @media screen and (max-width:768px) {
    .ay-hp-carousel-v4.rtl .ay-hp-caroysel-v4--holder {
      align-self: unset;
    }
  }
  
  }
}
