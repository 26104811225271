// Contact
//
// Contact Elements
//
// Styleguide Contact

/*
Contact List

Markup: contact.hbs

Styleguide Contact.List
*/

/*
Contact Us - Numbers

Markup: contact-us.hbs

Styleguide Contact.Contact-Us
*/

/*
Contact Us - Numbers - Right to Left

Markup: contact-us-rtl.hbs

Styleguide Contact.Contact-Us.Contact-Us Right to Left
*/

/*
Contact Us - Form

Markup: contact-form.hbs

Styleguide Contact.Contact-Form
*/

.ay-contact-main {
  display: flex;
  flex-direction: column;
  position: relative;

  .ay-subtitle {
    font-family: $header-font;

    @media screen and (max-width: $tablet-portrait) {
      text-align: center;
      font-size: 26px;

      &:after {
        content: "";
        background: url(images/arrow-right-black.svg) no-repeat;
        display: inline-block;
        height: 20px;
        left: 8px;
        position: relative;
        top: 3px;
        width: 15px;
      }

    }

    // Flip arrow on mobile touch
    &--mobile {
      @media screen and (max-width: $tablet-portrait) {
        &:after {
          content: "";
          background: url(images/arrow-right-blue.svg) no-repeat;
          display: inline-block;
          height: 20px;
          left: 8px;
          position: relative;
          top: 3px;
          transform: rotate(90deg);
          width: 15px;
        }

        &+.ay-contact{
          display:block;
        }
      }
    }
  }
}

.ay-contact {
  color: $ay-black;
  margin-bottom: 50px;
  -moz-column-count: 4;
  -webkit-column-count: 4;
  column-count: 4;
  display: block;
  width: 100%;

  @media screen and (max-width: $tablet-landscape) {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    text-align: left;
  }

  @media screen and (max-width: $tablet-portrait) {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    text-align: left;
  }

  @media screen and (max-width: $phone) {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
    border-bottom: 1px solid $ay-primary-color--cloud-grey-dark;
    margin-bottom: 10px;
    text-align: left;
  }

  &-subtitle {
    font-weight: bold;
    color: $ay-black;
    font-size: 16px;
  }
}

.ay-contact-location {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.ay-contact-info {
  list-style: none;
  margin: 0;
  padding: 0 0 10px 0;

  a {
    color: $ay-black;
    font-weight: 700;
    text-decoration: underline;
  }

  li:nth-child(2) {
    @media screen and (max-width: $tablet-portrait) {
      a {
        color: #6D6D6B;
        text-decoration: none;
      }
    }
  }
}

.ay-contact-list {
  h3.ay-subtitle {
    font-family: $header-font;
    color: $ay-black;
  }
  @media screen and (max-width: $tablet-portrait) {
    order: 1;
    cursor: pointer;
  }

  @media screen and (max-width: $phone) {
    .ay-subtitle {
      line-height: 1.3;
      font-size: 35px;
    }
  }
}

// Boiler plate
.ay-contact-boiler {
  background-color: $ay-black;
  color: $ay-white;
  margin-bottom: 40px;
  padding: 1.875rem;

  &__title {
    color: $ay-white;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: $semibold;

    @media screen and (max-width: $tablet-portrait) {
      text-align: left;
      font-size: 18px;
    }

    @media print {
      font-size: 18px;
    }

  }

  &__contact {
    display: flex;
    width: 100%;
    font-size: 20px;
    justify-content: space-between;

    @media screen and (max-width: $tablet-portrait) {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 14px;
    }

    @media print {
      font-size: 16px;
    }

    a{
      color: $ay-white !important;
      transition: all .5s;
       &:hover {
         color: $ay-white !important;
         text-decoration: underline;
       }
    }

    &__person {
      list-style: none;
      margin: 0 10px 0 0;
      padding: 0;

      @media screen and (max-width: $tablet-portrait) {
        margin: 0 0 10px 0;
        text-align: center;
      }

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

// Contact Button
.ay-contact-cta {
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: $tablet-portrait) {
    order: 2;
    justify-content: center;
    margin-top: 20px;
  }
}

.ay-contact-button {
  max-width: 250px;
  font-size: 18px;
  transform: translateX(0);
  &:after {
    top: 2px;
  }

  @media screen and (max-width: $tablet-portrait) {
    font-size: 14px;
    text-align: center;
  }
}

.ay-button__primary--arrow:after {
  @media screen and (max-width: $tablet-portrait) {
    height: 18px;
  }
}

// Contact Footnote
.ay-contact-footnote {
  font-size: 0.75rem;
  color: $ay-black;

  @media screen and (max-width: $tablet-portrait) {
    display: none;
  }
}

// Form

.ay-contact-popup {
  background-color: $ay-white;
  box-shadow: $ay-shadow;
  max-width: 540px;
  padding: 30px 20px;
  position: relative;
  width: 100%;

  &__close {
    color: #CCC;
    font-size: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
    top: 10px;
    text-decoration: none;
  }
}

.ay-contact-wrapper {
  @media screen and (max-width: $tablet-portrait) {
    height: 510px;
    overflow-y: auto;
  }
}

.ay-contact-form {
  margin: 20px 0;
  display: flex;
  flex-direction: column;

  input[type="text"] {
    border: none;
    border-bottom: 1px solid $ay-black;
    font-size: 14px;
    padding: 20px 0 0 0;
    position: relative;
    margin-bottom: 10px;

    &::placeholder {
      color: $ay-primary-color--cloud-grey-dark;
      opacity: 1;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  textarea {
    color: $ay-black;
    font-size: 14px;
    margin-top: 20px;
    padding: 20px 10px;

    &:focus {
      outline: none;
    }
  }

  select {
    appearance: none;
    background: $ay-white url(images/arrow-select-black.svg) no-repeat 97% center;
    background-size: 15px;
    border: 1px solid $ay-black;
    border-radius: 0;
    color: $ay-black;
    font-size: 0.8125rem;
    outline: none;
    padding: 0.75rem;

    &::-moz-focus-inner {
      border: 0;
    }

    option {
      &::-moz-focus-inner {
        border: 0;
      }
    }
  }

  .ay-button__primary {
    font-size: 14px;
    font-weight: $semibold;
    margin: 20px 0;
    max-width: 40%;
    padding: 10px 5px;
    text-transform: uppercase;
    transform: translateX(0);
  }
}

// Primary Country Contact

.ay-primary-countrycontacts {
  margin: 25px 0 50px 0;
  @media screen and (max-width: $phone) {
    margin:  10px 0;
  }
  &__item {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    margin-bottom: 15px;
  }
}

// Lightbox
.ay-lightbox {
  display: none;

  &--on {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: $phone) {
      padding: 50px 0 0 0;
    }
  }

  .ay-contact-popup {
    display: none;
  }
}
