/*
Subpage Banner

Markup: subpage-banner.hbs

Styleguide Subpage Banner
*/

.ay-subpage-banner {
  background: url(images/subpage-banner.jpg) no-repeat;
  background-size: cover;
  display: flex;
  height: 238px;
  overflow: hidden;
  position: relative;
  //max-width: 1300px;
  margin: 0 auto 18px auto;
  width: 100%;
  &.ay-subpage-banner-refresh{
    margin-bottom: 0;
  }

  @media screen and (max-width: $tablet-portrait) {
    height: 175px;
  }

  @media screen and (max-width: $phone) {
    height: 124px;
  }

  @media print {
    height: 0;
  }
}
