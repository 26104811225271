/*
News Releases

Markup: news-releases.hbs

Styleguide News Releases
*/

/*
News Releases - Right to Left

Markup: news-releases-rtl.hbs

Styleguide News Releases.News Releases Left to Right
*/

.ay-news-release {
  margin: 20px auto;
  position: relative;
  min-height: 480px;

  @media print {
    width: 98%;
  }

  @media screen and (max-width: $phone) {
    width: 100%;
  }

  .h2, h2 {
    font-weight: bold;
    line-height: 120%;
    margin-bottom: 15px;

    @media screen and (max-width: $tablet-portrait) {
      font-size: 32px;
      margin-bottom: 25px;
    }

    @media screen and (max-width: $phone) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .h3, h3 {
    font-weight: bold;
    line-height: 120%;
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
    max-height: 345px;
  }

  &__content > h3.ay-news-release__subtitle {
    font-family: $base-font;
    font-size: 1.375rem;
    color: $ay-black;
    font-weight: bold;
    margin: 0.875rem 0;

    @media screen and (max-width: $phone) {
      font-size: 1.25rem;
    }
  }

  &__boiler {
    border-top: 1px solid #CCC;
    padding: 1.875rem 0;
    margin-top: 2em;
  }

  small {
    font-weight: $semibold;
    font-size: 0.75rem;
    color: $ay-black;
    margin-bottom: 0.625rem;
    display: block;
    text-transform: uppercase;
  }

  p {
    margin-top: 0;
  }

  &__content {
    position: relative;
    a {
      color: $ay-black !important;
      //text-decoration: underline !important;
      @extend .ay-updated-content-link-style;
    }
    @media screen and (max-width: $phone) {
         padding-left: 20px;
         padding-right: 20px;
    }

  }

  &__social {
    align-items: center;
    border-top: 1px solid #C4C4C4;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: -85px;
    padding-top: 1.25rem;
    position: absolute;
    top: 10px;
    width: 55px;

    @media print{
      display:none;
    }

    @media screen and (max-width: $phone) {
      align-items: flex-start;
      flex-direction: row;
      left: 0;
      width: 100%;
      position: relative;
      margin-bottom: 10px;
      top: 0;
    }

    &--icon {
     /* color: $ay-primary-color--cloud-grey-dark; */
      display: block;
      fill: transparent;
      height: 30px;
      margin-bottom: 12px;
      width: 100%;
      stroke: $ay-black;
     /* &:hover {
        color: $ay-black;
      } */
    }
    &--item:first-child {
      .ay-news-release__social--icon {
        height: 25px;
      }
    }
  }
}
