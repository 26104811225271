/*
Timeline

Markup: timeline.hbs

Styleguide Timeline
*/

.ay-timeline {
  background-color: $ay-primary-colors--light;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: $tablet-portrait) {
    margin: 0 10px 0 0;
  }

  &__event {
    color: $ay-black;
    font-size: 14px;
    margin: 8px auto;
    max-width: 960px;
    padding: 40px 0 0 170px;
    position: relative;
    width: 100%;
    overflow: hidden;

    @media screen and (max-width: $tablet-portrait) {
      margin: 10px 0;
      padding: 50px 20px;
      width: auto;
    }

    &:before {
      border-top: 3px solid $ay-black;
      content: '';
      display: inline-block;
      height: 3px;
      left: 100px;
      max-width: 880px;
      position: absolute;
      top: 20px;
      width: 100%;
    }

    h4 {
      background-color: $ay-black;
      background-size: 92px;
      background-position: 0 2px;
      color: #fff;
      font-size: 18px;
      font-weight: $semibold;
      height: 40px;
      line-height: 1.875rem;
      left: 30px;
      padding: 2px 15px;
      position: absolute;
      top: 0;

      @media screen and (max-width: $tablet-portrait) {
        left: 20px;
      }
    }
  }
}
