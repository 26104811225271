// Services
//
// Services elements
//
// Styleguide Services

/*
Services Banner

Markup: services.hbs

Styleguide Services.Banner
*/

.ay-services-banner {
  margin: 0 auto .4em auto;
  max-width: 1300px;
  position: relative;
  overflow: hidden;
  width: 100%;

  @media screen and (max-width: $tablet-portrait) {
    margin: 0 auto;
  }

  &__image {
    max-width: 100%;
    display: block;

    @media screen and (max-width: 1024px) {
      display: none;
    }
    @media print {
      display: none;
    }
  }

  &__main {
   // position: absolute;
    //top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;

    @media screen and (max-width: 1024px) {
      align-items: center;
      display: flex;
      height: auto;
      justify-content: space-around;
      max-height: 100%;
      position: relative;
      width: 100%;
      z-index: 10;
      flex-direction: column;
      justify-content: flex-start;
      margin: .5em 0 0 0;
      width: 100%;
    }
  }

  &__link {
    align-items: center;
    background: $ay-white;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    margin: 0;
    padding: 0;
    position: relative;
    text-decoration: none;
    top: 0;
    width: calc(33.3% - 20px); //calc(33.3% - 7px);
    &:after{
      background: $ay-color-accent-light-orange;
      bottom: 0;
      content: "";
      position: absolute;
      height: 10px;
      transition: all .5s;
      width: 100%;
    }
    &:hover {
      text-decoration: none;
    }
    &:hover:after{
      background: darken($ay-color-accent-light-orange, 8%);
    }
    &:first-child:after {
      background:  $ay-color-accent-light-amethyst;
    }
    &:hover:first-child:after {
      background:  darken($ay-color-accent-light-amethyst, 8%);
    }
    &:last-child:after {
      background:  $ay-color-accent-light-mint;
    }
    &:hover:last-child:after {
      background:  darken($ay-color-accent-light-mint, 8%);
    }
    /* &:hover{
      &:after{
        height: 100%;
      }
    } */
    &:before {
      background:$ay-color-accent-light-orange;
      bottom: 0;
      content: "";
      position: absolute;
      height: 10px;
      transition: all .5s;
      width: 100%;
    }
    &:first-child:before {
      background: $ay-color-accent-light-amethyst;
    }
    &:last-child:before {
      background: $ay-color-accent-light-mint;
    }
    &:hover {
      &:before {
        height: 100%;
      }
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-bottom: 15px;
    }
    h3,
    .ay-services-banner-tt {
      //transform: skew(38.5deg);

      color: $ay-black;
      display: flex;
      flex-direction: column;
      //font-size: 2vw; //50px;
      font-size: 40px;
        line-height: 46.67px;
      @media screen and (max-width: 1024px) {
        font-size: 40px;
        line-height: 46.67px;
        padding-top: 20px;
      }
      font-weight: 500;
      justify-content: center;
      min-height: 250px; //171px;
      //padding-top: 42px;
      position: relative;
      text-align: center;
      transition: color .5s .03s;
      z-index: 3;
     /* &:after {
        background: $ay-black;
        content: "";
        display: block;
        height: 3px;
        margin: 30px auto;
        position: relative;
        transition: background .5s .05s;
        width: 25%;
        z-index: 3;
      } */
    }
    &:hover h3,
    &:hover .ay-services-banner-tt {
      color: $ay-white;
      //padding-top: 20px;
      &:after {
        background: $ay-white;
      }
    }
    div {
      color: transparent;
      height: 0;
      transition: all .5s 0s;

      @media screen and (max-width: 1024px) {
        color: $ay-black;
        height: 22px;

      }
      margin-bottom: 20px;
      position: relative;
      text-transform: uppercase;
      transition: color .5s .05s;
      z-index: 3;
    }
    &:hover div {
      color: $ay-white;
      height: 22px;

    }

    &__1 {

    }

    &__2 {

    }

    &__3 {

    }
  }
  &__general{
    color: $ay-white;
    background: $ay-black;
    font-size: 23px;
    font-weight: 700;
    margin-top: 11px;
    padding: 15px 0;
    width: calc(50% - 5px);
    &:after {
      display:none;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-bottom: 15px;
      margin-top: 0;
    }
  }
}

.ay-service {
  align-self: center;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  text-decoration: none;
  width: 230px;

  &:first-child {
    left: 30px;
    position: relative;

    @media screen and (max-width: 900px) {
      left: 0;
    }
  }

  &:nth-child(2) {
    align-items: center;
    margin-left: 80px;
    width: 200px;
  }

  &:nth-child(3) {
    width: 320px;
    align-items: center;
    margin-right: 1.875rem;

    @media screen and (max-width: $desktop) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 1024px) {
    margin: .5em 0;
    width: 100%;
    justify-content: flex-end;
    height: 115px;

    &:first-child {
      background: url(images/services1.jpg) no-repeat;
      background-size: cover;
      max-width: 100%;
      position: relative;
      width: 100%;
      left: 0;

      &:before {
        background: rgba(134, 181, 92, 0.8);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
      }
    }

    &:nth-child(2) {
      background: url(images/services2.jpg) no-repeat;
      background-size: cover;
      max-width: 100%;
      position: relative;
      width: 100%;
      margin-left: 0;
      align-items: flex-start;

      &:before {
        background: rgba(244, 139, 59, 0.8);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
      }
    }

    &:nth-child(3) {
      align-items: flex-start;
      background: url(images/services3.jpg) no-repeat;
      background-size: cover;
      max-width: 100%;
      position: relative;
      width: 100%;

      &:before {
        background: rgba(50, 184, 223, 0.8);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
      }
    }
  }

  @media screen and (max-width: $tablet-portrait) {
    height: auto;
    padding: 65px 0 10px 0;
  }

  h3 {
    font-size: 40px;
    font-weight: 300;
    color: $ay-white;
    position: relative;
    padding-bottom: 10px;
    z-index: 5;

    @media screen and (max-width: 1100px) {
      font-size: 35px;
    }

    @media screen and (max-width: 900px) {
      left: 30px;
      z-index: 3;
    }

    @media screen and (max-width: 1024px) {
      bottom: 20px;
      font-size: 32px;
      left: 0;
      padding-left: 20px;

      br {
        display: none;
      }
    }

    @media screen and (max-width: $phone) {
      font-size: 25px;
      bottom: 10px;
    }

    &:after {
      background: $ay-white;
      bottom: 0;
      content: "";
      display: block;
      height: 5px;
      position: absolute;
      width: 40px;
    }
  }

  &:first-child h3 {
    position: relative;
    left: 50px;

    @media screen and (max-width: 1024px) {
      left: 0;
      padding-left: 20px;
    }
  }
}

/*
Services Wrapper

Markup: services-wrapper.hbs

Styleguide Services.Services-Wrapper
*/

.ay-services-main {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
  width: 100%;

  @media screen and (max-width: $tablet-portrait) {
    margin-top: 0.625rem;
    padding: 1em;
  }

  .ay-flex-grid {
    max-width: 1300px;
    width: 100%;
    margin: 10px 0;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    @media screen and (max-width: $tablet-portrait) {
      margin-top: 0;
    }

    .ay-button__primary {
      background: $ay-white;
      border: none;
      color: $ay-black !important;
      display: flex;
      font-weight: 600;
      padding-top:30px;
      padding-bottom: 30px;
      text-align: left;
      margin-left: 0 !important;
      transform: translateX(0) !important;
      width: 50%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
      span {
        display: inline-block;
        font-size: 18px;
        text-transform: uppercase;
        width: 100%;

      }
      div {
        background: url(images/person-homepage-icon-rs.png) no-repeat left center;
        width: calc(100% - 80px);
        background-size: 50px;
        background-position: left 69%;

        font-size: 28px;
        margin: auto;
        padding-left: 70px;
        text-decoration-skip: objects;
        text-decoration: underline;
        text-decoration-color: #fff;
        transition: all .5s;

        @media screen and (max-width: 459px) {
          padding-left: 100px;
        }
      }
      &:hover {
        text-decoration: underline;
        div{
          text-decoration-skip: objects;
            text-decoration: underline;
            text-decoration-color: #000;
          }

      }
    }

    .ay-button__primary:first-child {
      margin-right: 30px;
      div {
        background: url(images/clipboard-gear-homepage-icon-rs.png) no-repeat left center;
        background-size: contain;
        margin: auto;
      }

      @media screen and (max-width: 1024px) {
        margin: 0 0 8px 0;
      }
    }
  }
}

/*
Services Case Study

Markup: services-case-study.hbs

Styleguide Services.Case-Study
*/

.ay-case-study-full-wrap {
  max-width: 1300px;
  margin: auto;

  .owl-dots.disabled {
    display: none;
  }
}

.ay-case-study-slider-main-outer {
  width: calc( 100%);

  //margin:auto;
  max-width: 1215px;
  padding-left: 85px;

  @media screen and (max-width: $tablet-portrait) {
    padding-left: 0;
  }

  @media print {
    padding-left: 0;
  }
}

.ay-case-study-slider-main {
  width: 100%;

  .owl-stage-outer {
    margin-bottom: 10px;
  }

  #sync1 {
    .cs-item {
      height: 450px;
      position: relative;

      @media screen and (max-width: $tablet-portrait) {
        height: 250px;
      }


      .ay-case-study-slider__caption {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 3;

        @media print {
          display: none;
        }

      }

      img {
        display: block;
        height: 100%;
        object-fit: cover;
        max-height: 100%;
        z-index: 2;
        position: relative;

        @media screen and (max-width: $tablet-portrait) {
          max-width: 100%;
        }

        @media print {
          max-width: 600px;
        }
      }
    }
  }

  #sync2 {


    .cs-item {
      height: 100px;
      filter: grayscale(1);
	    transition: filter .5s;


      @media screen and (max-width: $tablet-portrait) {
        height: 45px;
      }

      &:hover {
        filter: grayscale(.35);
        cursor: pointer;
      }

      img {
        display: block;
        height: 100%;
        max-height: 100%;
      }
    }
    .current .cs-item {
      filter: grayscale(0);
    }
  }
}

.ay-case-study--single-image {
  max-width: 1300px;
  width: 100%;
  max-height: 450px;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  padding: 0 0 0 75px;
  margin: 0;
  position: relative;

  @media screen and (max-width: $tablet-portrait) {
    padding: 0;
  }

  @media print {
    padding-left: 0;
    max-width: 650px;
  }

  img {
    width: calc( 100%);
  }

  img[src=""] {
    //helps out IE11 size the area right
    height: 65px;
    visibility: hidden;

    //helps out ie11 and mac issue with no images

  }

  & + .ay-case-study {
    margin-top: 20px;
  }

  figcaption {
    margin: 0;
    position: absolute;
    align-self: flex-start;
    width: auto;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 50px;
    top: 0;

    @media print {
      display: none;
    }

    //helps IE11 on single images put the title in the correct area
    .ay-case-study--title-blue-bg {
      background: $ay-black;
      color: #fff;
      font-size: 24px;
      padding: 0 30px 0 50px;
      height: 70px;
      display: flex;
      align-items: center;
    }

    .ay-title-edge-blue__end {
      background-size: 77px;
      margin-left: 0;
      width: 77px;
      height: 70px;
      display: inline-block;
    }
  }
}

.ay-case-study {
  margin: 35px 0 60px;
  max-width: 1300px;
  position: relative;
  width: 100%;
  display: flex;

  @media screen and (max-width: $tablet-portrait) {
    margin-top: 15px;
    padding-top: 0px;
    margin: 0 auto;
  }

  .ay-case-study--columns-holder {
    width: 100%;

    img {
      max-width: 15%;
      min-width: 10%;

      @media screen and (max-width: 1000px) {
        min-width: 30%;
      }

      display: block;
      margin-bottom: 15px;
      width: 100%;
    }

    &__inner {
      width: 100%;
      display: flex;
      max-width: 1300px;
      margin: auto;

      @media screen and (max-width: 1000px) {
        flex-direction: column;
      }

      @media print {
        width: 100%;
        flex-direction: column;
      }
    }
  }

  &--columns {
    width: 100%;
    display: flex;

    @media screen and (max-width: $tablet-portrait) {
      flex-direction: column;
    }

    &__column {
      width: calc( 50%);

      @media screen and (max-width: 1000px) {
        width: 100%;
      }

      @media print {
        width: 100%;
      }

      &.ay-case-study-col-1 {
        color: $ay-black;
        font-size: 115%;
        line-height: inherit;
        padding-right: 15px;

        @media print {
          font-size: 16px;
        }


        li {
          padding-left: 0;
          margin-left: 0;

          &:before {
            display: none;
          }
        }
      }
    }
  }
}

.ay-side__social {
  border-top: 1px solid #C4C4C4;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 30px;
  padding-top: 1.25rem;
  width: 55px;
  margin-right: 30px;

  @media screen and (max-width: $tablet-portrait) {
    align-items: flex-start;
    flex-direction: row;
    left: 0;
    width: 30px;
    height: auto;
    border-top: none;
    border-bottom: 1px solid #C4C4C4;
    margin-bottom: 15px;
    padding-top: 0;

    a {
      margin-right: 10px;
    }
  }

  @media print {
    display: none;
  }

  &--icon {
    display: block;
    color: #C4C4C4;
    fill: transparent;
    height: 30px;
    margin-bottom: 12px;
    max-width: 40px;
    width: 30px;

    &:hover {
      color: $ay-primary-colors--medium;
    }
  }
}

/*
Services Projects

Markup: services-projects.hbs

Styleguide Services.Services-Projects
*/

.ay-projects-carousel {
  margin: 20px auto;
  width: 100%;
  max-width: 900px;

  @media screen and (max-width: $tablet-portrait) {
    max-width: 350px;
    width: 100%;
    padding: 0 10px;
  }

  .owl-stage-outer {
    margin-bottom: 15px;
  }

  .owl-nav {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 50px;
    pointer-events: visible;
  }

  .ay-project {
    display: flex;
    flex-direction: column;
    height: 250px;
    max-width: 300px;
    position: relative;
    width: 100%;

    @media screen and (max-width: $tablet-portrait) {
      max-width: 350px;
      height: 300px;
    }

    &__thumb {
      display: flex;
      overflow: hidden;
      justify-content: space-between;
      flex-direction: column;
    }

    &__details {
      background-color: $ay-black;
      color: $ay-white;
      height: 65px;
      padding: 0 12px;
      justify-content: center;
      flex-direction: column;
      display: flex;
      transition: .2s all;
      width: 100%;
      z-index: 3;

      h3 {
        color: $ay-white;
        font-size: 16px;
        font-weight: normal;
        margin: 0;
        padding: 0;

        &:first-child {
          margin-bottom: 4px;
          font-weight: $semibold;
        }
      }
    }
  }
}

// Title Styles
.ay-title-edge {
  display: flex;

  h2 {
    color: $ay-black;
    font-size: 24px;
    height: 70px;
    padding: 18px 20px 0 20px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: $tablet-portrait) {
      height: 60px;
      padding: 14px 20px 0 30px;
      font-size: 22px;
    }
  }
}

.ay-title-edge-blue {
  display: flex;

  h2 {
    background: $ay-black;
    color: $ay-white;
    font-size: 24px;
    height: 70px;
    padding: 18px 30px 0 50px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: $tablet-portrait) {
      height: 60px;
      padding: 14px 20px 0 30px;
      font-size: 22px;
    }
  }

  &__end {
    background-size: 77px;

    @media screen and (max-width: $tablet-portrait) {
      background-size: 66px;
    }

    left: -1px;
    position: relative;
    width: 77px;
  }
}

/*
Services Case Study Single Link

Markup: services-case-study-single-link.hbs

Styleguide Services.Services-Case-Study-Single-Link

*/

#fifty:target .ay-services-scs-link {
  width: 50%;
}

#seventyfive:target .ay-services-scs-link {
  width: 75%;
}

.ay-title-edge + .ay-services-scs-link {
  margin-top: 20px;
}

.ay-services-scs-link {
  text-decoration: none;
  display: block;
  width: 33%;

  figure {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 20px 0;
    padding: 0;

    span {
      display: block;
    }

    img {
      width: 100%;
    }

    figcaption {
      position: absolute;
      bottom: 0;
      background-color: $ay-black;
      color: $ay-white;
      height: 65px;
      padding: 0 12px;
      justify-content: center;
      flex-direction: column;
      display: flex;
      transition: .2s all;
      width: 100%;
      z-index: 3;
    }
  }
}

/*
Services Forms

Markup: services-forms.hbs

Styleguide Services.Services-Forms
*/

.ay-services-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  padding: .2em;

  &__title {
    font-size: 20px;
    color: $ay-black;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__subtitle {
    display: flex;
    align-self: flex-start;
    border-bottom: 1px solid $ay-secondary-colors--extra-dark;
    color: $ay-secondary-colors--extra-dark;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 25px;
    padding-bottom: 5px;
    width: 100%;
  }

  &__main {
    align-items: flex-end;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: $tablet-portrait) {
      width: 100%;
      align-self: flex-start;
    }

    input[type="text"],
    input[type="email"] {
      border: 1px solid $ay-black;
      font-size: 14px;
      max-width: 300px;
      padding: 2px 10px;
      width: 100%;
      height: 32px;

      @media screen and (max-width: $tablet-portrait) {
        padding: 6px 10px;
        font-size: 16px;
        max-width: 100%;
      }

      &:focus {
        outline: none;
      }
    }

    textarea {
      border: 1px solid $ay-black;
      font-size: 14px;
      height: 100px;
      padding: 2px 10px;
      max-width: 300px;
      width: 100%;

      @media screen and (max-width: $tablet-portrait) {
        padding: 6px 10px;
        font-size: 16px;
        max-width: 100%;
      }

      &:focus {
        outline: none;
      }
    }

    select {
      appearance: none;
      background: $ay-secondary-colors--medium-medium url(images/arrow-select.svg) no-repeat 97% center;
      background-size: 15px;
      border: 1px solid #CCC;
      border-radius: 0;
      color: $ay-secondary-colors--extra-dark;
      font-size: 0.8125rem;
      outline: none;
      padding: 0.5rem;
      max-width: 300px;
      width: 100%;
      height: max-content;

      @media screen and (max-width: $tablet-portrait) {
        padding: 6px 10px;
        font-size: 16px;
        max-width: 100%;
      }

      &::-moz-focus-inner {
        border: 0;
      }

      option {
        &::-moz-focus-inner {
          border: 0;
        }
      }
    }

     span.ay-services-form__submit {
      justify-content: space-between;
      width: 47%;
      @media screen and (max-width: 768px){
        flex-direction: row;
        width: 100%;
      }
      .ay-services-form__button{
        max-width: 150px;
        margin-right: 0;
        text-transform: uppercase;
        &:last-child{
          margin-left: 10px;
        }
        &:only-child {
          margin-left: 0;
        }
      }
    }
  }

  &__button {
    align-items: center;
    background-color: $ay-black;
    border: none;
    color: $ay-primary-colors--light;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: .3s all;
    width: 100%;
    max-width: 100px;
    margin-right: 180px;

    @media screen and (max-width: $tablet-portrait) {
      margin: 0;
      align-items: flex-start;
      max-width: 150px;
      padding: 15px;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $ay-black;
      cursor: pointer;
    }
  }

  &__label {
    color: $ay-black;
    display: flex;
    font-size: 13px;
    font-weight: bold;
    justify-content: flex-end;
    margin-right: 5px;
    min-width: 185px;
    width: 100%;
    text-align: right;

    @media screen and (max-width: $tablet-portrait) {
      justify-content: flex-start;
      min-width: 0;
      margin: 0 0 10px 0;
      font-size: 16px;
    }
  }

  span {
    display: flex;
    margin-bottom: 25px;
    max-width: 550px;
    width: 100%;
    justify-content: flex-end;

    @media screen and (max-width: $tablet-portrait) {
      justify-content: flex-start;
      flex-direction: column;
    }
  }

  &__inputs {
    display: flex;
    width: 100%;
    max-width: 300px;

    @media screen and (max-width: $tablet-portrait) {
      max-width: 100%;
    }

    input[type="text"],
    input[type="email"] {
      width: 50%;
      margin-right: 10px;
    }

    select {
      width: 50%;
    }
  }
}

// Case Studies Table
.ay-services--case-studies-list {
  td {
    width: 16.666%;
    vertical-align: top;
    display: table-cell;
    word-wrap: break-word;

    &:nth-child(2) {
      white-space: inherit !important;
    }

    @media screen and (max-width: $tablet-landscape) {
      font-size: 14px;
      display: block;
      word-wrap: inherit;
      width: auto;
    }
  }

  .ay-search-proff-table__header {
    @media screen and (max-width: 900px) {
      td {
        font-size: 13px;
      }
    }
  }

  @media screen and (max-width: $tablet-landscape) {
    .ay-search-proff-table__header {
      display: none;
    }
  }
}

/*
Services Case Study Version 3

Markup: services-case-study-version-3.hbs

Styleguide Services.Services-Case-Version-3

*/

/*
Services Case Study Version 3 3 Across

Markup: services-case-study-version-3-3a.hbs

Styleguide Services.Services-Case-Version-3-3a

*/

.ay-case-study__version-3{
  background:$ay-white;
  max-width: 900px;
  width: 100%;
  margin-bottom:1.5625em;
  .ay-title-edge{

    h2{
      @media screen and (max-width: 768px){
        padding:14px 0 0 30px;
      }
    }
    .ay-case-study-view-all-listings{
      align-self:flex-end;
      color: $ay-black;
      font-size:14px;
      font-weight:600;
      margin-bottom:21px;
      margin-left:auto;
      margin-right: 0;
      display:flex;
      max-height:36px;
      align-content:center;
      text-decoration:none;
      text-transform:uppercase;
      img{
        max-width:36px;
        width:36px;
      }
      span{
        align-self:center;
        color:$ay-black;
        min-width:140px;
        padding-right: 10px;
        text-align: right;
      }
    }
  }
  &--grouping{
    margin:0;
    padding:0 36px;
    width: 100%;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li{
      position:relative;
      width:50%;
      margin-bottom:15px;
      @media screen and (max-width: 1300px) {
        width:100%;
      }
      @media screen and (max-width: 931px) {
        width:50%;
      }
      @media screen and (max-width: 670px) {
        width:100%;
      }
    }
    a{
      text-decoration: none;
      color:$ay-white !important;
      position:relative;
      display:block;
      width:calc(100% - 15px);
      &[data-status]:before{
        background: $ay-black;
        content:attr(data-status);
        position:absolute;
        text-transform:uppercase;
       // background:#da322a;//da322a
        font-size:11px;
        font-weight:600;
        opacity: 0;
        padding:5px 22px 5px 22px;
        display:flex;
        justify-content:flex-start;
        align-items:flex-start;
        top:0;
        left:0;
        height:auto;
        color:$ay-white;
        margin-left: -8px;
        margin-top: 30px;
        transition: opacity .5s;
      }
      &.green[data-status]:before{
       // border-top: 54px solid transparent; //
      }
      &.orange[data-status]:before{
         border-top: none !important;
       }
      &[data-status]:after{
       // content:attr(data-status);
        text-transform:uppercase;
        padding:15px 0 15px 25px;
        font-size:18px;
        font-weight:600;
	      position:absolute;
	      z-index:1;
	      top:0;
	      left:0;

      }
      &[data-status]:hover:before{
        opacity: 1;
      }
      &:hover{
        .ay-case-study__version-3--cover{
          opacity:0;
        }
        figure figcaption{
          opacity:1;
        }
      }
    }

  }
  &--cover{
      padding:15px;
      margin:0;
      background:rgba(0, 0, 0, .5);
      opacity:1;
      position:absolute;
      width:100%;
      height:100%;
      transition: opacity .5s;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index:10;
      @media screen and (max-width: 850px) {
        justify-content: center;
      }
      @media screen and (max-width: 670px) {
        justify-content: space-between;
      }
      @media screen and (max-width: 470px) {
        justify-content: center;
      }
      dl{
        margin:0;
        padding:0;
        dt{
          color: $ay-white;
        }
      }
      dt,dd{
        margin:0;
        padding:0;
      }
      dd{
        &:first-child{
          dt{
            color:$ay-white;
            font-size:25px;
            line-height:35px;
            font-weight: 700;
            h3 {
              color:$ay-white;
              font-family: $base-font;
              font-size:25px;
              font-weight: 700;
              line-height:35px;
            }
          }
          dd{
            font-size:25px;
            font-weight: 700;
            line-height:35px;
          }
        }
        &:last-child{
          @media screen and (max-width: 850px) {
            display: none;
          }
          @media screen and (max-width: 670px) {
            display: block;
          }
          @media screen and (max-width: 470px) {
            display: none;
          }
          dt,dd{
            display: inline;
          }
          dt {
            font-weight: $semibold;
          }
          dt:after {
            content: ":";
          }
          dd:after{
            content:"";
            display:block;
            margin-bottom:5px;
          }
        }
      }
    }
    figure{
      padding:0;
      margin:0;
      img{
        width:100%;
      }
      figcaption{
        background:$ay-black;
        color: $ay-white;
        position:absolute;
        bottom:0;
        width:100%;
        opacity: 0;
        padding:5px 15px;
        text-align: right;
        transition: opacity .5s;
        font-size: 12px;
        display: flex;
        align-items:center;
        justify-content: flex-end;
        &:after{
          content:"";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 0 6px 8px;
          border-color: transparent transparent transparent $ay-white;
          display:inline-block;
          margin:0 0 0 10px;
        }
      }
    }
}

.ay-column.ay-column__single.ay-column__single--blank.portlet-column.portlet-column-only{
  .ay-case-study__version-3{
    max-width: 1300px;
    &--grouping {
      justify-content:flex-start;
      li{

        width:33.33%;
        @media screen and (max-width: 1285px){
          width:50%;
        }
        @media screen and (max-width: 840px){
          width:100%;
        }
      }
    }
    &--cover {
      @media screen and (max-width: 840px){
        justify-content: space-between;
      }
      @media screen and (max-width: 505px){
        justify-content: center;
      }
      dd:last-child{

      justify-content: space-between;
      @media screen and (max-width: 840px){
       display:block;

      }

      @media screen and (max-width: 505px){
       display:none;

      }
     }
    }
  }
}

//updates for canadian service line useage
.ay-main.ay-main-white,
.ay-main.ay-main-grey {
  .ay-case-study__version-3 {
    background: transparent;
    @media screen and (max-width: 1372px) {
      margin-left: 42px;
      margin-right: 30px;
      width: calc(100% - 72px);
    }
    .ay-title-edge{

      h2{
        padding:14px 0 0 0;
      }
    }
    .ay-case-study__version-3--grouping {
      margin: 0 0 0 9px;
      padding: 0;
       li {
        & > a {
          margin-right: 15px;
          width: auto;
          @media screen and (max-width: 700px) {
            margin-right: 10px;
          }
        }
        &:nth-child(n+2) {
          @media screen and (max-width: 930px) {
            margin-right: -5px;
          }
          @media screen and (max-width: 700px) {
            margin-right: 2px;
          }
        }
        &:nth-child(n+3) {
          & > a {
            margin-right: 10px;
            @media screen and (max-width: 930px) {
              margin-right: 15px;
            }
            @media screen and (max-width: 700px) {
              margin-right: 9px;
            }
          }
        }
      }
    }
  }
}
//adds updates for feature properties and meet the team buttons
@media screen and (max-width: 929px){
  #ay .ay-column__triple--col2.portlet-column .ay-multiple-button-wrap, #ay .ay-column__triple--col2.portlet-column .ay-multiple-button-wrap a{
    width:auto;
  }

}
@media screen and (max-width: 580px){
 #ay .ay-case-study__version-3 .ay-title-edge h2{
   font-size:18px;
   height: 50px;
 }
}
 @media screen and (max-width: 530px){
 #ay .ay-case-study__version-3 .ay-title-edge h2{
   font-size:16px;
 }
}

// add functionality for three wide case studies on version 3 on services page

.ay-lts-row-layout{
  .ay-main-grey,
  .ay-main-white {
    .ay-case-study__version-3 {
      max-width: $desktop-large;
      &--grouping li {
        width: 33.33%;
        @media screen and (max-width: 930px) {
          width: 50%;
        }
        @media screen and (max-width: 700px) {
          width: 100%;
        }
      }
    }
  }
}
// add functionality for three wide case studies on various
.aui #ay .span12 .ay-case-study__version-3--grouping li {
  width: 33.3%;
  @media screen and (max-width: 930px) {
      width: 50%;
  }
  @media screen and (max-width: 700px) {
      width: 100%;
  }
}

.aui #ay .ay-lts-row-layout .ay-column {
  width: 100%;
}

/*
Services Case Study Version 3 Rigth to left

Markup: services-case-study-version-3-rtl.hbs

Styleguide Services.Services-Case-Version-3.Right to Left

*/
// for new property search page
.ay-column-3060 {
  .ay-column__triple--col2 {
    .ay-case-study__version-3--grouping {
      padding: 0 19px;
      li {
        @media screen and (max-width: $desktop-large) {
          width: 50%;
        }
        @media screen and (max-width: 1180px) {
          width: 100%;
        }
        @media screen and (max-width: $tablet-portrait) {
          width: 50%;
        }
        @media screen and (max-width: $phone) {
          width: 100%;
        }
      }
    }
  }
}

/*
Services Case Study Version 4

Markup: services-case-studies-version-4.hbs

Styleguide Services.Services-Case-Version-4

*/

.ay-blog-tiles-wrap.ay-feature-property-tiles {
  h3.ay-blog-tile-title{
    font-family: $base-font;
    font-size: 20px;//26px;
    line-height: 26px;//32px;
    margin-bottom: 32px;
  }
  h4.ay-blog-tile-blurb {
    font-size: 20px;//26px;
    line-height: 26px;//32px;
  }
  a:hover h4.ay-blog-tile-blurb {
    text-decoration: underline;
  }
  ul li a:after {
    border-bottom: 3px solid $ay-color-accent-dark-perwinkle;
  }
}
