// Office
//
// Office elements
//
// Styleguide Office

/*
Office Page

Markup: office.hbs

Styleguide Office.Office
*/

/*
Office Carousel

Markup: office-carousel.hbs

Styleguide Office.Office-Carousel
*/

/*
Office Info

Markup: office-info.hbs

Styleguide Office.Office-Info
*/

/*
Office Welcome

Markup: office-welcome.hbs

Styleguide Office.Office-Welcome
*/

/*
Office Copy

Markup: office-copy.hbs

Styleguide Office.Office-Copy
*/

/*
Office Services

Markup: office-services.hbs

Styleguide Office.Office-Services
*/

/*
Office Info Updated

Markup: office-info-updated.hbs

Styleguide Office.Office-Info-Updated
*/

/*
Office Intro Multi Office

Markup: office-intro-multi.hbs

Styleguide Office.Office-Multi-Office
*/

/*
Office Intro Additional Link

Markup: office-info-link-bottom.hbs

Styleguide Office.Office-Intro-Link-Bottom
*/

/*
Office Intro Mini-Site

Markup: office-intro-mini-site.hbs

Styleguide Office.Office-Intro-Mini-Site
*/

// Override owl carousel styles

.ay-slider {
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  .owl-dots {
    counter-reset: inherit;
    height: 40px;
    margin-top: 10px;
    pointer-events: auto;
    position: relative;
    top: 0;
    width: 100%;

    &:after {
      content: '';
      display: inline-block;
    }
  }

  .owl-dot {
    background: $ay-white;
    border: 1px solid $ay-black;
    border-radius: 100%;
    counter-increment: inherit;
    height: 20px;
    margin: 0 10px;
    width: 20px;

    &.active {
      background: $ay-black;

      &:before {
        content: '';
        padding: 0 5px 0 0;
        display: inline-block;
      }
    }

    span {
      display: block;
    }
  }

  .owl-stage-outer {
    margin: 0;
  }
}

.ay-slider .item {
  display: flex;
  height: 600px;
  margin: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 600px;

  @media screen and (max-width: $tablet-portrait) {
    flex-direction: column;
  }

  .ay-slider__img {
    display: block;
    height: 100%;
    overflow: hidden;
    vertical-align: middle;
    width: 100%;

    @media screen and (max-width: $tablet-portrait) {
      height: 400px;
      width: 100%;
    }

    @media print {
      display: none;
    }

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      overflow: hidden;
      width: 100%;
      vertical-align: middle;
      z-index: 10;
    }
  }

  .ay-slider__caption {
    background: $ay-black;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding: 40px 20px 30px 40px;
    position: relative;
    width: 35%;
    z-index: 20;

    @media screen and (max-width: $tablet-portrait) {
      height: auto;
      justify-content: flex-start;
      padding: 30px 15px 30px 20px;
      width: 100%;
    }

    h3 {
      color: $ay-white;
      font-size: 35px;
      text-align: left;

      @media screen and (max-width: $tablet-portrait) {
        font-size: 25px;
      }
    }

    &--link {
      color: $ay-white;
      display: inline-block;
      font-size: 15px;
      padding: 0;
      text-decoration: none;
      margin: 15px 0;
      text-align: left;

      &:after {
        content: '';
        background: url(/images/arrow-right.svg) no-repeat;
        width: 14px;
        height: 14px;
        display: inline-block;
        position: relative;
        left: 10px;
        top: 1px;
      }
    }
  }
}

// Change slide 2 color
.ay-slider .ay-slide-2 .ay-slider__caption {
  background: $ay-black;
}

// Change slide 3 color
.ay-slider .ay-slide-3 .ay-slider__caption {
  background: $ay-black;
}

// Card Info Wrapper
.ay-info-card {
  background-color: $ay-black;
  color: $ay-white;
  display: flex;
  flex-direction: column;
  padding: 1.75rem 1.75rem;
  max-width: $desktop-large;
  margin: auto;

  // Card Info Wrapper Main
  &__main {
    display: flex;

    ul.ay-ul {
      margin: 0 0 10px 0;
    }

    h3 {
      color: $ay-white;
      font-size: 34px;
      font-weight: 300;
      line-height: 1.7em;

      @media screen and (max-width: $tablet-portrait) {
        font-size: 30px;
      }
    }

    h4 {
      color: $ay-white;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;

      @media screen and (max-width: $tablet-portrait) {
        font-size: 16px;
      }
    }

    @media screen and (max-width: $tablet-portrait) {
      flex-direction: column;
    }

    // Card Info Wrapper Main Column 1
    &__col1 {
      border-right: 1px solid $ay-white;
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-left: 15px;

      @media screen and (max-width: $tablet-portrait) {
        width: 100%;
        border: none;
      }

      h4 span {
        color: $ay-white;

        @media screen and (max-width: $tablet-portrait) {
          display: block;
        }
      }

      // Card Info Wrapper Main Column 1 unordered lists
      &__list {
        display: flex;

        @media screen and (max-width: $tablet-portrait) {
          border-bottom: 1px solid $ay-white;
          flex-direction: column;
          margin-bottom: 20px;
          padding-bottom: 40px;
        }

        .ay-ul {
          font-size: 18px;
          line-height: 1.7em;
          margin: 0 0 10px 0;

          &:first-child {
            margin-right: 70px;
          }

          li {
            line-height: 1.5;
          }

          @media screen and (max-width: $tablet-portrait) {
            line-height: 1.8em;
            margin: 0;
            width: 100%;
          }
        }
      }
    }

    // Card Info Wrapper Main Column 2
    &__col2 {
      display: flex;
      flex-direction: column;
      padding: 0 60px 0 40px;
      width: 50%;

      @media screen and (max-width: $tablet-portrait) {
        width: 100%;
        padding: 20px 0 0 0;
      }

      .ay-ul {
        font-size: 24px;
        padding-top: 10px;
        margin-top: 0;
        margin-bottom: 10px;

        @media screen and (max-width: $tablet-portrait) {
          font-size: 18px;
          margin: 0 0 20px 0;
        }

        li {
          line-height: 150%;

          a {
            color: $ay-white;
            text-decoration: none;

            &:after {
              content: '';
              background: url(/images/arrow-right.svg) no-repeat;
              display: inline-block;
              height: 20px;
              left: 10px;
              position: relative;
              top: 1px;
              width: 20px;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      p {
        line-height: 1.5em;
        margin-top: 0;

        a {
          color: $ay-white;
        }
      }
    }
  }

  &__map {
    margin-top: 15px;

    @media screen and (max-width: $tablet-portrait) {
      display: none;
    }

    a {
      color: $ay-white;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }

    &--mobile {
      display: none;

      @media screen and (max-width: $tablet-portrait) {
        display: block;
        margin: 0;
      }
    }
  }
}

.ay-copy-highlight {
  background: $ay-black;
  color: $ay-white;
  padding: 12px 20px 15px 20px;
  margin: 0 0 25px 0;
  font-size: 18px;

  a {
    color: $ay-white;
    text-decoration: underline;
  }
}

.ay-office-services-links {
  a {
    font-size: 1.125rem;
    color: $ay-black;
  }
}

// 3 column element

.ay-tri-column--inner {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;

  @media screen and (max-width: 740px) {
    flex-direction: column;
  }

  .ay-ul {
    margin: 5px 0;
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: 740px) {
      margin-bottom: 15px;
    }

    &:last-child {
      margin: 0;
    }

    li {
      line-height: 200%;
    }
  }
}

.ay-office-intro {
  display: flex;
  margin: 0 auto;
  max-width: 1300px;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media screen and (max-width: $tablet-landscape) {
    flex-direction: column;
    height: auto;
  }

  @media screen and (max-width: $phone) {
    margin-top: 140px;
  }

  a {
    color: $ay-white !important;
    text-decoration: underline solid $ay-white;

    &:hover {
      text-decoration: underline solid $ay-white !important;
    }

  }
  .viewmap a {
    text-decoration: underline !important;
    &:hover {
      text-decoration: underline double $ay-white !important;
    }
  }

  // Image
  &__img {
    display: block;
    flex: 7;
    min-height: 580px;
    overflow: hidden;
    width: 100%;

    @media screen and (max-width: $tablet-landscape) {
      flex: 1;
      height: 300px;
    }

    @media screen and (max-width: $tablet-portrait) {
      min-height: 0;
    }

    img {
      object-fit: cover;
      display: block;
      max-width: 100%;
      width: 100%;
      height: 100%;
      z-index: 8;
    }
  }

  // Sidebar

  &__sidebar {
    background-color: $ay-black;

    &.ay-office-intro__sidebar-green {
      background-color: $ay-black;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 40px 20px 20px 30px;
      width: 420px;

      a {
        color: $ay-white;
        display: block;

        &:hover {
          text-decoration: underline;
        }

        h3 {
          font-size: 27px;
        }

        p {
          margin-top: 0;
        }
      }

      @media screen and (max-width: 1000px) {
        width: 100%;
      }

      h1 {
        font-size: 34px;
        font-weight: 300;
        color: $ay-white;
        @media print {
          font-size: 24px;
        }
        &.titleOnly{
          padding:0 30px;
        }
        &.titleRightOnly{
          padding:0 30px 0 0;
        }

        //padding:0 30px;
        line-height: 120%;
      }
    }

    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 300;
    padding: 80px 20px 30px 30px;
    position: relative;
    z-index: 7;
    width: 420px;//was 450

    a.ay-kit-link {
      text-decoration: none;
    }

    @media screen and (max-width: $tablet-landscape) {
      padding: 30px;
      width: 100%;
    }

    @media print {
      width: 100%;
    }

    .ay-ul {
      line-height: .8;
      color: $ay-white;
      margin: 0 0 12px 0;
    }

    h2 {
      color: $ay-white;
      font-size: 22px;
      margin-bottom: 15px;
    }

    h3 {
      color: $ay-white;
      font-size: 16px;
      margin: 0 0 6px 0;
    }

    h4 {
      color: $ay-white;
      font-size: 16px;
      margin-bottom: 10px;

      span {
        display: block;
      }
    }

    .boiler-subtitle {
      font-size: 22px;
      color: $ay-white;
      font-weight: 300;
      margin-bottom: 5px;
    }

    &__boiler {
      color: $ay-white;
      line-height: 1.5;
      font-size: 14px;
      padding-right: 34px;
    }

    &__map-link {
      color: $ay-white;
      text-decoration: none;
      margin-bottom: 15px;

      &:hover {
        text-decoration: underline;
      }
    }

    &__menu-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 0;
      margin: 20px 0 15px 0;
      list-style: none;
      line-height: 1.5;
      width: 100%;
      max-width: 400px;
      text-align: left;

      @media screen and (max-width: $tablet-landscape) {
        max-width: 100%;
      }

      li {
        padding: 2px 0;
        width: auto;
        margin: 0 10px 0 0;

        &:last-child {
          margin-right: 0;
        }

        a {
          display: inline-block;
          color: $ay-primary-colors--light;
          text-decoration: none;
          position: relative;
          font-weight: $semibold;

          &:after {
            content: '';
            background: url(/images/arrow-right.svg) no-repeat;
            width: 14px;
            height: 14px;
            display: inline-block;
            position: relative;
            left: 5px;
            top: 1px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__address-list {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $tablet-landscape) {
      flex-direction: row;
    }

    @media screen and (max-width: $tablet-portrait) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }

  &__address {
    color: $ay-primary-colors--light;
    list-style: none;
    margin: 5px 0 10px 0;
    padding: 0;

    @media screen and (max-width: $tablet-landscape) {
      &:first-child {
        margin-right: 5%;
      }
    }

    @media screen and (max-width: $tablet-portrait) {
      width: 46%;
    }

    @media screen and (max-width: 700px) {
      width: 100%;
      margin-bottom: 20px;

      &:first-child {
        margin-right: 0;
      }
    }

    .org {
      font-weight: $semibold;
      margin-bottom: 4px;
    }

    .viewmap {
      margin-bottom: 10px;
    }

    .contact-numbers {
      font-weight: $semibold;
      display: flex;

      @media screen and (max-width: $tablet-portrait) {
        flex-direction: column;
      }

      &__phone {
        margin-right: 8px;

        @media screen and (max-width: $tablet-portrait) {
          margin: 0 0 4px 0;
        }
      }
    }

    .tel {
      margin: 0 3px;
    }
  }
}

#ay-office-information {
  .ay-office-info-multiples {
    margin-bottom: 1.5625em;
    @media print {
      display: none;
    }

    &__header {
      background: $ay-black;
      padding: 20px 20px 0 20px;

      h3 {
        color: $ay-white;
        padding-bottom: 20px;

        a {
          color: $ay-white;
          text-decoration: none;

          &:after {
            content: "";
            margin-left: 10px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 0 10px 14px;
            border-color: transparent transparent transparent $ay-white;
            display: inline-block;
            transform: rotate(0deg);
            transition: all .5s;
          }
          &.open{
            &:after{
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    &__quick-links {
      list-style: none;
      margin: 0 0 20px 0;
      padding: 0 0 20px 0;
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid $ay-primary-colors--light;

      li {
        margin: 0;
        padding: 0;
        font-size: 15px;

        a {
          color: $ay-primary-colors--light;
          text-decoration: none;
          text-transform: uppercase;

          &:after {
            content: '';
            background: url(/images/arrow-right.svg) no-repeat;
            display: inline-block;
            height: 14px;
            left: 5px;
            position: relative;
            top: 1px;
            width: 14px;
            margin-right: 20px;
          }
        }
      }
    }

    &__offices-details {
      height: 0;
      overflow: hidden;
      background: $ay-primary-colors--light;
      transition: all .5s;
      &.open{
        height:auto;
      }
      &--rmd {
        color: $ay-black !important;
      }

      &--rmd, &--rmd + dd {
        font-weight: 700;
        font-size: 16px;
      }

      padding: 0 20px 0 20px;

      &--mholder {
        display: flex;
        flex-wrap: wrap;
        .ay-office-info-multiples__offices-details--md {
          &:before {
            content: " (";
            display: inline-block;
            margin-left: 5px;
          }

          &:after {
            content: ")";
          }
          display: flex;
          order: 2;
        }

        .ay-office-info-multiples__offices-details--md + dd {
          order: 1;
        }
      }

      &--rmd, &--md {
        display: inline-block;

        & + dd {
          display: inline-block;
          font-weight: 700;
        }
      }

      &--digits {
        font-weight: normal;
      }

      dl {
        margin: 0;
        padding: 0;

        dt {
          color: $ay-black;
          margin: 20px 0 0 0;
          padding: 0;
          font-weight: 700;

          & + dd {
            a {
              color: $ay-black;
              text-decoration: underline;
            }

            dl {
              dt {
                margin-top: 0;
              }
            }
          }
        }

        dd {
          margin: 0;
          padding: 0;

          a {
            color: $ay-black;
            text-decoration: underline;

            &:hover {
              text-decoration: underline;
            }

            &.tel {
              color: $ay-black;
              font-weight: normal;
            }

            &[href*="google"] {
              margin-bottom: 10px;
              display: block;
              font-weight: normal;
            }
          }
        }
      }
    }
  }

  &:target .ay-office-info-multiples {
    &__header {
      h3 {
        a {
          &:after {
            transform: rotate(90deg);
          }
          &.open{
            &:after {
              transform: rotate(0deg);
            }
          }
        }
      }
    }

    &__offices-details {
      height: auto;
      overflow: hidden;
      padding: 20px;
      &.open{
        height:0;
      }
    }
  }
}

.ay-office-intro-link-bottom{
  border-top:1px solid $ay-white;
  margin-bottom:0;
  margin-top:auto;
  padding-top:15px;
  a{
    text-decoration: none;
    &:hover{
      text-decoration:none;
      strong{
        text-decoration:underline;

      }
    }
    strong{
      display:block;
      font-size: 22px;
      font-weight: 300;
      &:after{
        content:"";
        display:inline-block;
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-left: 10px solid $ay-white;
        border-bottom: 6px solid transparent;
        margin-left:5px;
      }
    }
    span{
      display:block;
      color:$ay-white;
    }
  }

}

.ay-office-intro__sidebar.ay-office-intro__sidebar-green.ay-mini-site-intro{
  justify-content: flex-start;
  h1{
    font-size:26px;
    font-weight:500;
  }
  p{
    color: $ay-white;
    font-size:15px;
    font-weight:500;
    line-height:26px;
    .ay-mini-site-read-more{
      margin-top:10px;
      text-transform:uppercase;
      font-size:14px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      &:after{
        content:"";
        display:inline-block;
        border-top: 5px solid transparent;
        border-left: 6px solid $ay-white;
        border-bottom: 5px solid transparent;
        margin-left:10px;
      }
    }
  }
  .ay-mini-site-contacts-list{
    margin:auto 0 0 0;
    padding:0;
    color:$ay-white;
    font-weight:500;
    dt{
      text-transform:uppercase;
    }
    dd{
      margin:0;
      padding:0;
      text-transform:uppercase;
      font-size:17px;
      font-weight:600;
      &+dt{
        margin-top:16px;
      }
    }
  }
}

/*
Office Updated Header/Hero

Markup: _office-head-revamp.hbs

Styleguide Office.Office-Updated-Header
*/

.ay-office-banner {
  background: #40357E;
  display:flex;
  max-width:1300px;
  margin:auto;
@media only screen and (max-width: 900px) {
  flex-direction: column;
}
.ay-office-content-panel {
  width: 50%;
  display: flex;
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
  }

  .ay-office-text-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 650px;
    margin:25px;
  }
  .ay-office-image-panel {
  width:50%;
  overflow:hidden;
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
  }
  .ay-office-button-zone {
  display:flex;
  flex-flow:row wrap;
  justify-content: flex-start;
  }
   .ay-button__primary {
  padding:10px;
  border:3px solid black;
  background:white;
  color:black;
  text-decoration: none;
  text-transform: uppercase;
  font-weight:600;
  text-align:center;
  margin: 0px 15px 15px 0px;
  max-width:max-content;
  padding: 15px 2em;
  transform: translateX(0);
  @media only screen and (max-width: 900px) {
    font-size: 15px;
   }
  &:hover,
  &:focus {
    background: $ay-black !important;
  }
  }
  h1 {
  color:white !important;
  font-size: 54.6px;
  margin-bottom:25px !important;
  @media only screen and (max-width: 900px) {
    font-size: 44px;
    margin-bottom:  20px !important;
   }
  }
  .ay-office-hero-img {
    height: 100%;
    object-fit: cover !important;
    object-position: center;
    width: 100%;
    @media only screen and (max-width: 900px) {
     max-height: 400px;
    }
  }
}

