
  .ay-header-options {
    margin: 0 0 0 0px;
    position: relative;
    padding: 5px 30px 17px 0;
    &:before {
      display: none;
      border-left: 1px solid #32b8df;
      content: "";
      left: auto;
      right: 15px;
      position: absolute;
      top: -1px;
      height: 100%;
    }
    @media screen and (max-width: 480px){
      width: auto;
      margin-top: -10px;
      .ay-header-options__language-location {
        margin-top: -3px;
        display: block;
      }
    }
  }
  .ay-top-nav__navbar {
    justify-content: space-between;
  }
  .ay-header-search-login__search {
    border-left: 1px solid #cbcbcb;
    border-right: none;
  }
  .ay-top-nav__search {
    margin-left: 0;
  }
  .ay-header-search-login__login {
    background-position: right;
    margin-left: 0;
    margin-right: 8px;
    padding-left: 0;
    padding-right: 38px;
  }
  .ay-quick-links__dd-medium {
    right: -210px;
    &:after {
      right: 230px;
    }
    @media screen and (max-width: 1000px) {
      right: 0;
    }
  }
  .ay-quick-links__dd-wide{
    right: -990px;
    &:after {
      right: 1014px;
    }
    @media screen and (max-width: 1290px) {
      right: -946px;
      &:after {
        right: 1010px;
      }
    }
    @media screen and (max-width: 1068px) {
      right: -748px;
      &:after {
        right: 770px;
      }
    }
    @media screen and (max-width: 1000px) {
      right: 0;
    }
  }
  .ay-quick-links__dd-short {

    &:after {
      left: 84px;
    }
  }
  .ay-top-nav__megamenu--list li,
  .ay-top-nav__megamenu--column-title {
    padding-left: 0;
    padding-right: 20px;
  }

  .ay-country-menu__column .ay-office-menu__global,
  .ay-country-menu__column h4.ay-country-menu__column--title {
    margin-left: 0;
    margin-right: 30px;
  }

  .ay-country-menu__column:nth-child(3) .ay-country-menu__column--list li ul,
  .ay-country-menu__column:nth-child(3) .ay-country-menu__column--list li ul li,
  .ay-quick-links__dd-wide .ay-office-menu__list li ul {
    margin-right: 0;
    padding-right: 0;
  }

  @media screen and (max-width: 1000px) {
    .ay-top-nav__mobile-landing-link,
    .ay-top-nav__item.ay-top-nav__open .ay-top-nav__mobile-landing-link {
      left: 0;
      right: auto;
    }
    .ay-top-nav__tenantlogin {
      right: auto;
      left: 30px;
    }
    .ay-top-nav__pull {
      padding: 0 56px 0 0;
      &:after {
        left: auto;
        right: 20px;
      }
    }
    .ay-header-logo {
      margin-right: 10px !important;
    }
    .ay-header-options {
      margin-left: 15px;
      padding-left: 15px;
    }
  }

  .ay-top-nav .ay-top-nav__langauge-select li a.ay-top-nav-close {
    float: left;
  }

  .ay-select-dropdown {
    background: url(images/arrow-select-white.svg) no-repeat 2% center;
    background-size: 15px;
  }

  .ay-country-menu__column .ay-country-menu__column--list {
    padding: 0 30px 0 0;
  }

@media screen and (max-width: 480px){
  .ay-country-menu__column--list {
      padding-left: 0;
      padding-right: 0;
      float: none;
  }

	.ay-country-menu__column:nth-child(4) .ay-country-menu__column--title {
  	margin-right: 9px;
	}
  .ay-country-menu__column:nth-child(4) .ay-country-menu__column--list {
	    column-count: 2;
	    padding-right: 11px;
  }
  .ay-country-menu__column:nth-child(2) .ay-country-menu__column--list {
      padding-right: 30px;
  }
}
/* updates to office menu making wider, for rtl usage */
.ayui.rtl #ay .ay-quick-links__dd-wide:after {
  right: 80%;
}

@media screen and (min-width: 1300px) {
 .ayui .ay-quick-links__dd-wide {
 max-width: 1256px;
   transform-origin: center 0;
   transform: translateX(calc((1400px - 54px))/2);
  width: 100vw;
 right: -149px;
 }
  .ayui.rtl #ay .ay-quick-links__dd-wide:after {
    right: 80%;
  }

}

@media screen and (min-width: 1000px) {
.ay-quick-links__dd-wide .ay-office-menu.ay-office-menu__us .ay-office-menu__list {
  column-gap: 30px;
}
}

@media screen and (min-width: 1000px) and (max-width: 1299px){
 .ayui .ay-quick-links__dd-wide {
 max-width: unset;
   transform-origin: 0 0;
  width: 95vw;
 right: -159px;
 }
  .ayui .ay-quick-links__dd-wide:after {
   right: 75%;

  }

}

@media screen and (min-width: 1000px) and (max-width: 1199px){
.ayui .ay-quick-links__dd-wide {
  right: -195px;
     width: 98vw;
}
   .ayui .ay-quick-links__dd-wide:after {
   right: 0; /*19%;*/
  left: 80%;
  }
 }

.ay-office-menu.ay-office-menu__us {
  max-width: 48%;
}

.ayui .ay-office-menu.ay-office-menu__other {
display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  min-width: 36%;
}

.ay-office-menu.ay-office-menu__other  .ay-office-menu-sub-column {

    width: 48%
}

@media screen and (max-width: 1000px){
  .ayui .ay-header-wrapper.ay-office-menu__ca {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .ayui .ay-quick-links__dd-wide.ay-quick-links__dd-wide--show {
    overflow-x: hidden;
  }
  .ay-quick-links__dd-wide .ay-office-menu.ay-office-menu__us .ay-office-menu__list, .ay-office-menu__list.ay-office-menu__list--other {
    column-gap: 0;
  }
  .ayui .ay-office-menu__ca .ay-office-menu__other__list:nth-child(3) {
  margin-top: 19px;
  }
  .ayui .ay-quick-links__dd-wide .ay-office-menu {
  flex-direction: row;
  flex-wrap: wrap;
  }
  .ay-office-menu.ay-office-menu__ca .ay-office-menu__other__list,
  .ay-office-menu.ay-office-menu__other .ay-office-menu__other__list {
    width: 33.3%;
  }
  .ay-office-menu.ay-office-menu__ca .ay-office-menu__other__list.ay-office-menu__list--other,
  .ay-office-menu__subtitle, .ay-office-menu__global,
  .ay-office-menu.ay-office-menu__us, .ay-office-menu.ay-office-menu__us .ay-office-menu__list, .ayui .ay-office-menu__other__list[data-office="multi"],
  .ayui .ay-office-menu-sub-column {
  min-width: 100%;
  width: 100vw;
  }
  .ayui .ay-quick-links__dd-wide .ay-office-menu__other, .ayui .ay-office-menu.ay-office-menu__us
   {
  padding-top: 0;
  }
  .ayui .ay-office-menu.ay-office-menu__other,
  .ay-quick-links__dd-wide .ay-office-menu
   {
  flex-direction: column;
  }

  .ay-office-menu.ay-office-menu__other .ay-office-menu-sub-column {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
  }
  .ay-office-menu.ay-office-menu__other .ay-office-menu-sub-column {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .ay-office-menu.ay-office-menu__other .ay-office-menu-sub-column .ay-office-menu__list.ay-office-menu__list--other {
  width: 100%;
  }

}

@media screen and (max-width: 767px){
.ay-office-menu.ay-office-menu__ca .ay-office-menu__other__list,
  .ay-office-menu.ay-office-menu__other .ay-office-menu__other__list {
    width: 50%;
  }
}


html.ayui.rtl[dir="rtl"] #ay .ay-quicklinks-mobile__close {
left: 0;
right: auto;
}

@media screen and (min-width: 1300px) {
html.ayui.rtl[dir="rtl"] #ay  .ay-quick-links__dd-wide {
    left: 0;
  right: -990px;
}

}


@media screen and (min-width: 1000px) and (max-width: 1290px) {
html.ayui.rtl[dir="rtl"] #ay  .ay-quick-links__dd-wide {
    left: auto;
    right: auto;
    width: 100vw;
    max-width: 100vw;
    transform: translateX(73.25vw);
}
 .ayui.rtl #ay .ay-quick-links__dd-wide:after {
    right: 75%;
  }

}

.rtl .ay-lts-media-block-content /* adds context to apply only to rtl content */ {
	padding-right:2%;
}


