// Cards
//
// Cards for Blog postings
//
// Styleguide Cards

/*
Single Card

--black        - black bottom border (default)
--perwinkle    - Perwinkle bottom border
--orange       - Orange bottom border
--amethyst     - Amethyst bottom border
--red          - Red bottom border

Markup: cards-single.hbs

Styleguide Cards.Single-Card
*/

/*
Cards Set

Markup: cards.hbs

Styleguide Cards.Cards-Set
*/

.ay-cards-slider {
  width: 99%;

  .owl-nav {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 50px;
    pointer-events: visible;
  }
}

.ay-cards-single-row {
  .owl-dots {
    margin-top: -32px;
  }
}

.owl-stage-outer {
  margin-bottom: 10px;

  @media screen and (max-width: $phone) {
    margin-bottom: 10px;
  }
}

.owl-next {
  background: url(images/arrow-right-black.svg) no-repeat;
  height: 19px;
  width: 20px;
  margin: 0 26px;

  &:hover {}
}

.owl-prev {
  background: url(images/arrow-right-black.svg) no-repeat;
  height: 19px;
  margin: 0 26px;
  transform: rotate(180deg);
  width: 20px;

  &:hover {
    cursor: pointer;
    background: url(images/arrow-right-black.svg) no-repeat;
    transform: rotate(180deg);
  }
}

.owl-dots {
  counter-reset: slides-num;

  /* Initialize counter. */
  align-items: center;
  color: $ay-black;
  display: flex;
  height: 18px;
  justify-content: center;
  margin-top: -34px;
  pointer-events: none;

  /* position: absolute; */
  /* top: 94.5%; */
  width: 100%;

  &:after {
    content: counter(slides-num);

    /* get total number of items - more info on http://www.sitepoint.com/a-little-known-way-to-replace-some-scripts-with-css-counters/ */
    display: inline-block;
    font-size: 17px;
  }
}

.owl-dot {
  display: inline-block;
  counter-increment: slides-num;

  span {
    display: none;
  }

  &.active {
    &:before {
      content: counter(slides-num) " / ";
      padding: 0 5px 0 0;

      /* Use the same counter to get current item. */
      display: inline-block;
      font-size: 17px;
    }
  }
}

.ay-card-thumb__img {
  display: block;
  height: 160px;
  vertical-align: middle;
  max-width: 160px;

  @media screen and (max-width: $phone) {
    height: 100px;
    width: auto;
    max-width: 100%;
  }
}

.ay-cards {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-family: $base-font;
  justify-content: space-around;
  max-width: 1300px;
  margin: 0 auto;
  overflow: hidden;
  padding: 70px 0 65px 0;
  width: 100%;
}

.ay-card {
  background-color: $ay-white;
  border-bottom: 7px solid $ay-black;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  min-height: 241px;
  flex-direction: column;
  padding: 12px 25px;

  &--perwinkle {
   border-bottom-color: $ay-black;
  }
  &--orange {
    border-bottom-color: $ay-black;
  }
  &--amethyst {
    border-bottom-color: $ay-black;
  }
  &--red {
    border-bottom-color: $ay-black;
  }
  &.single {
    max-width: 650px;
  }

  p {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  @media screen and (max-width: 1000px) {
    min-height: 215px;
    padding: 10px 20px;
    width: auto;
  }

  @media screen and (max-width: $phone) {
    min-height: 150px;
  }

  &-date {
    margin: 0;
    padding: 0;
    color: $ay-black;
    font-weight: bold;
    font-size: 11px;
    line-height: 30px;
    text-transform: uppercase;

    @media screen and (max-width: $phone) {
      line-height: normal;
      padding-top: 5px;
    }
  }

  &-row {
    display: flex;
    margin: 0;
    padding-top: 8px;
    margin-bottom: 15px;

    .ay-card-title {
      font-family: $base-font;
      font-size: 24px;
      color: $ay-black;
      margin-bottom: 10px;
      line-height: 126%;

      /* Fallback for non-webkit */
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: $desktop) {
        font-size: 26px;
        line-height: 32px;
      }

      @media screen and (max-width: $tablet-landscape) {
        margin-top: 0;
      }

      @media screen and (max-width: $tablet-portrait) {
        font-size: 25px;
        line-height: normal;
        overflow: visible;
      }

      @media screen and (max-width: $phone) {
        font-size: 20px;
        line-height: 22px;
        overflow: hidden;
      }

      @media print {
        font-size: 18px;
      }

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      &__link {
        color: $ay-black;
        text-decoration: none;
      }
    }

    .ay-card-post {
      color: $ay-black;
      overflow: hidden;
      font-family: $base-font;
      width: 100%;

      .ay-card-caption {
        display: block;
        font-size: 14px;

        /* Fallback for non-webkit */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding-right: 10px;
        text-overflow: ellipsis;
        @media screen and (max-width: $phone) {
          display: none;
        }
        a {
          color: $ay-black;
          text-transform: uppercase;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &-thumb {
    display: inline-block;
    flex-basis: 50%;
    height: 160px;
    margin-right: 20px;
    position: relative;

    @media screen and (max-width: $phone) {
      display: flex;
      height: auto;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .owl-nav {
    padding: 16px;
  }
}
