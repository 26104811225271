/*
Careers Landing RTL Hebrew

Markup: careers-rtl-iw.hbs

Styleguide Careers.Careers Landing Right to Left

*/

.ay-careers-landing__full-bleed-links {
  .h3 {
    padding: 0 0.9375rem 0 0 !important;
  }
  svg {
    transform: rotate(180deg);
  }
}
