/*
Secondary Button with Arrow Color Right to Left

Markup: secondary-button-arrow-rtl-iw.hbs

Styleguide Buttons.Secondary-Button-Arrow.rtl
*/

.ay-button__arrow {

  &:after{
    left: 0;
    right: auto !important;
    transform: rotate(180deg);
  }
 }
 .journal-content-article>a.ay-button__primary:lang(ar),
 .rtl[lang="ar"] .journal-content-article>a.ay-button__primary{
	-webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
	transform: translateX(50%);
	margin-right: 50%;
}
