/*
Pagination

Markup: pagination.hbs

Styleguide Pagination

*/

.ay-pagination{
	display:block;
	width:100%;
	@media screen and (max-width: $tablet-portrait) {
		margin-top: 20px;
	}

	&--pagination-list{
		list-style: none;
		display: flex;
		align-items:center;
		justify-content:center;
		width:auto;
		margin:0;
		padding:0;
		line-height:0;
		@media screen and (max-width: $tablet-portrait) {
			background:$ay-white;
			justify-content:center;
		}
		li{
			@media screen and (max-width: $tablet-portrait) {
				width: auto;
			}
			margin-left:5px;
			padding-bottom:0;
			margin-bottom:0;
			color:$ay-black;
			&:first-child{
				margin-right:15px;
				margin-left:0;
				align-self: flex-start;
				svg{
					width:30px;
					padding:5px;
					@media screen and (max-width: $tablet-portrait) {
						width:38px;
						padding:10px;
					}
					transform: rotate(180deg);
					background:$ay-primary-color--cloud-grey-dark;
					// support for MS Edge Browser
					@supports (-ms-ime-align:auto) {
						height:0px;
						width: 30px;
						padding:4px 9px 26px 9px;
						transform: translateX(10px) rotate(180deg);
						overflow: hidden;
						fill:#fff;
					}
					// support for MS IE11/10 Browsers
					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						height:0px;
						width: 30px;
						padding:4px 9px 26px 9px;
						transform: translateX(10px) rotate(180deg);
						overflow: hidden;
						fill:#fff;
					}
					use{
						fill:$ay-white;
					}
				}
				a {
					svg{
						background:$ay-black;
					}
				}
			}
			&:last-child{
				margin-left:15px;
				margin-right:0;
				svg{
					width:30px;
					padding:5px;
					@media screen and (max-width: $tablet-portrait) {
						width:38px;
						padding:10px;
					}
					background:$ay-black;
					use{
						fill:$ay-white;
					}
					// support for MS Edge Browser
					@supports (-ms-ime-align:auto) {
						height:0px;
						width: 30px;
						padding:4px 9px 26px 9px;
						transform: translateX(-5px);
						overflow: hidden;
						fill:#fff;
					}
					// support for MS IE11/10 Browsers
					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						height:0px;
						width: 30px;
						padding:4px 9px 26px 9px;
						transform: translateX(-5px);
						overflow: hidden;
						fill:#fff;
					}
				}
				a {
					svg{
						background: $ay-black;
					}
				}
			}
			a{
				color: $ay-primary-color--cloud-grey-dark;
				text-decoration: none;
			}

			&.ay-pagination--pagination-list__ellipsis{
				&:after{
					content:"...";
					color: $ay-primary-color--cloud-grey-dark;
				}
			}
		}
	}
}
