/*
Form Elements

Markup: forms.hbs

Styleguide Forms
*/

.ay-select-field {
  appearance: none;
  background: $ay-white url(images/arrow-select-black.svg) no-repeat 99% center;
  background-size: 15px;
  border-radius: 0;
  border: none;
  color: $ay-black;
  line-height: 1;
  font-size: 0.8125rem;
  outline: none;
  padding: 0.75rem;
  width: 100%;

  &::-moz-focus-inner {
    border: 0;
  }

  option {
    &::-moz-focus-inner {
      border: 0;
    }
  }
}

#ay-search-on-page-mobile {
  width: 100%;
}
// updates for new pardot subscription form
.ay-subscription-holder{
  /* max-width:calc(92% - 30px);
	margin-right: 30px; */
  input[type="text"], input[type="email"]{
    padding: 8px !important;
  }
  input[type="email"]{
    width:100%;
  }
}

// update for blog forms
.ay-lts-row-layout .ay-search-proff-sort {
  display: blog;
  margin-left: auto;
  margin-right: auto;
  max-width: $desktop-large;
  width: 100%;
  @media screen and (max-width: 1372px) and (min-width: 768px),
  screen and (max-width: $phone) {
    margin-left: 36px;
    margin-right: 36px;
    max-width: calc(100% - 72px);
  }
  &__mobile{
    #ay-search-on-page-action-mobile {
      background: $ay-black;
      transition: background .5s;
      width: 100%;
      &:hover {
        background: $ay-primary-colors--medium-light;
      }
    }
  }
}

// updates for list search form on mobile

@media screen and (max-width: 1372px), screen and (max-width: 480px) {
   .ay-lts-row-layout #ay-filter-research-report-list .ay-search-proff-sort {
       margin-left: auto;
       margin-right: auto;
       max-width:  none;
       padding-left: 0;
       padding-right: 0;
    background-color: transparent ;
   }
   #ay-filter-research-report-list .ay-search-proff-sort__mobile   .ay-search {
        display: none;
  }
  #ay-filter-research-report-list .ay-search-proff-sort__mobile  .ay-search__filter-holder {
   background: transparent;
 }
  #ay-filter-research-report-list .ay-search-proff-sort__mobile .ay-button__secondary {
   max-width: 100% !important;
 }

}



/*
Property Launchpad form

Markup: opportunaties-opt-in.hbs

Styleguide Forms.Launchpad

*/

.ay-property-launchpad-form {
  form {
    margin: 0;
    padding: 0;
    width: 85%;
  }
  fieldset {
    border: none;
    margin: 0;
    padding: 0;
    legend {
      border-bottom: none !important;
      color: #000;
      font-family: $header-font;
      font-size: 24px;
      margin-bottom: 15px;
      padding: 18px 20px 0 0;
    }
    .description {
      font-family: "Open Sans",sans-serif;
      font-size: 1.125em;
      color: #000;
      font-weight: 600;
      line-height: 120%;
      margin-bottom: 15px;
      padding: 0;
    }
    .hide {
      display: none;
    }
    label {
      display: block;
      margin-bottom: 5px;
    }
    input {
      border: 2px solid $ay-black !important;
      color: $ay-black !important;
      height: 28px !important;
      width: 99%;
      margin-bottom: 15px;
      &[type="checkbox"]{
        display: inline-block;
        width: auto;
        margin-bottom: auto;
        margin-right: 10px;
        height: auto;
      }
    }
    .input-text-wrapper:not(.lfr-textarea-container) label:after {
      content: "*";
    }
    .input-checkbox-wrapper + p {
      font-size: 10px;
    }
    textarea {
      border: 2px solid #000;
      display: block;
      height: 150px;
      margin-bottom: 15px;
      width: 99%;
    }
    button {
      width: 99%;
      align-items: center;
      background-color: #000 !important;
      background-image: none;
      border: 2px solid #000;
      color: #fff;
      display: inline-block;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      padding: 15px 10px;
      position: relative;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      -webkit-transition: .3s all;
      -o-transition: .3s all;
      transition: .3s all;
      width: 99%;
      &:hover {
        background: $ay-white !important;
        color: $ay-black;
      }
    }
    .formbox {
      border: none;
      background: #B8BCBE;
      margin-bottom: 15px;
      padding: 10px;
    }
  }
  .lfr-textarea-container.input-text-wrapper label {
      font-weight: 600;
    }
  }
