/*
Footer

Markup: footer.hbs

Styleguide Global-Theme.90-Footer
*/

/*
Footer Hebrew

Markup: footer-rtl-he.hbs

Styleguide Global-Theme.90-Footer.Hebrew
*/

.ay-footer {
  background: $ay-black;

  font-size: 13px;
  max-width: 100%;
  padding: 3em calc(50% - 650px);
  width: 100%;
  &-content {
    align-items: center;
    color: $ay-white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  a {
    color: $ay-white !important;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &:active,
    &:focus {
      outline-color: $ay-white;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
    }
  }
  &-top-left {
    //width: 150px;
    width: 118.562px;
    img {
      height: 72px;
    }
  }
  &-top-center {
    .ay-footer-recommented + ul {
      flex-direction: column;
      li{
        text-align: center;
        &:after {
          display: none !important;
        }
      }
    }
    &[data-recommented-links]:before{
      content: attr(data-recommented-links);
      display: block;
      font-weight: 600;
      margin-left: 100px;
      text-align: center;
    }
    .ay-footer-recommented {
      color: $ay-white;
      display: block;
      font-family: "Open Sans",sans-serif;
      font-size: 13px;
      font-weight: 600;
      margin: 0 0 0 100px;
      padding: 0;
      text-align: center;


      @media screen and (min-width: 848px) {
        margin-left: 9.1%;
      }

      @media screen and (min-width: 1400px) {
        margin-left: 10.1%;
      }
      @media screen and (min-width: 1955px) {
        margin-left: 11.1%;
      }
      @media screen and (min-width: 2100px) {
        margin-left: 12.1%;
      }
      @media screen and (min-width: 2500px) {
        margin-left: 13.1%;
      }
      @media screen and (max-width: 847px) {
        margin-left: 0;
      }
    }
    width: calc(100% - 287.562px);
    @media screen and (max-width: 847px) {
      order: 3;
      padding-top: 25px;
      width: 100%;
    }
    ul {
      margin: auto;
      @media screen and (min-width: 847px) {
        transform: translateX(3vw);
      }
      width: 425.219px;

      @media screen and (max-width: 465px) {
        width: 100%;
      }
      justify-content: center;
      li {
        display: block;
        &:after {
          content: " | ";
          display: inline-block;
          font-size: 15px;
          font-weight: 700;
          padding: 0 5px;
        }
        &:last-child:after {
          color: transparent;
        }

      }
    }
  }
  &-top-right {
    width: 169px;
    @media screen and (max-width: 847px) {
      width: calc(100% - 118.562px);
    }
  }
  &-social {

    ul {
      justify-content: flex-end;
      li{
        margin-right: 5px;
        &:first-child {

          margin-bottom: 5px;
          margin-right: 0;
          text-align: right;
          width: 100%;
          a {
            align-items: center;
            display: flex;
            justify-content: flex-end;
            img {
              margin-left: 5px;
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    a {
      &:hover  {
        text-decoration: none;
      }
      img {
        width: 29px;
      }
    }
  }
  &-bottom-left {
    @media screen and (max-width: 847px) {
      order: 4;
    }
    @media screen and (max-width: 534px) {
      order: 5;
    }
    ul {
      li {
        &:after {
          content: " | ";
          display: inline-block;
          font-size: 15px;
          font-weight: 700;
          padding: 0 5px;
        }
        &:last-child:after {
          color: transparent;
        }
      }
    }
  }
  &-bottom-right {
    @media screen and (max-width: 847px) {
      order: 5;
    }
    @media screen and (max-width: 534px) {
      order: 4;
      padding-bottom: 25px;
      width: 100%;
    }
    padding-top: 25px;
    img {
      height: 75px;
      @media screen and (max-width: 534px) {
        margin: 0 auto;
        display: block;

      }
    }
  }
  @media screen and (max-width: 1355px) {
   &-content{
      padding-left: 24px;
      padding-right: 30px;
      width: 100%;
    }
  }
}

