/*
Careers Landing

--border - white background bottom bordered

Markup: careers.hbs

Styleguide Careers.Careers Landing

*/

.ay-careers-landing {
  &__full-bleed-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background: $ay-secondary-colors--medium-medium;
    text-decoration: none;
    margin-bottom: 25px;

    .h3 {
      color: $ay-black;
      padding: 0 0 0 0.9375rem;
      font-family: $header-font;
      font-size: 1.875rem;
      align-self: center;
      margin-bottom: 0;

      //removes bottom margin to keep text vertically centered in space
      @media screen and (max-width: $phone) {
        font-size: 1.25rem;
      }
    }

    svg {
      height: 4.75rem;
      background: $ay-black;
      padding: 23px;
      align-self: flex-start;

      @media screen and (max-width: $phone) {
        padding: 15px;
        height: 3.125rem;
      }

      use {
        height: 1rem;
        fill: #fff;
        stroke: $ay-white;
      }

      // support for MS Edge Browser
      @supports (-ms-ime-align: auto) {
        width: 4.75rem;
        padding: 23px 34px 34px;

        path {
          height: 0.625rem;
          width: 0.625rem;
          fill: $ay-white;
        }
      }

      // support for MS IE11/10 Browsers
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 4.75rem;
        padding: 23px 34px 34px;

        path {
          height: 0.625rem;
          width: 0.625rem;
          fill: $ay-white;
          display: block;
        }
      }
    }
  }

  &__expandable {
    background: $ay-secondary-colors--extra-light;
    margin-bottom: 25px;

    &--header {
      background: $ay-black;
      min-height: 245px;
      display: flex;

      @media screen and (max-width: $tablet-portrait) {
        flex-direction: column;

        &-image {
          width: 100%;
          height: 200px;
        }
      }

      &-image {
        width: 16%;
        min-width: 180px;
        max-width: 180px;
        background-size: cover;
        background-position: center;

        @media screen and (max-width: $tablet-portrait) {
          width: 100%;
          height: 245px;
          max-width: 100%;
        }
      }

      &-text {
        padding: 35px;
        font-size: 1rem;
        line-height: 1.5rem;

        @media screen and (max-width: $phone) {
          padding: 20px;
        }

        a {
          color: $ay-white !important;
        }

        &__readmore {
          text-transform: uppercase;
          text-decoration: none;
          font-size: 0.8125rem;
          font-weight: 400;
          outline: none;
          align-items: center;
          color: $ay-white;

          span {
            padding: 0 0 0 0;

            &:nth-child(2) {
              display: none;
            }
          }

          svg {
            height: 0.9375rem;
            margin-top: 2px;
            transition: all .5s;
            transform: translateY(2px) rotate(0deg);

            // fix for issue with firefox being a jerk about it
            @-moz-document url-prefix() {
              transform: translateY(1px) rotate(0deg);
            }

            // support for MS Edge Browser
            @supports (-ms-ime-align: auto) {
              height: 0.5625rem;
              width: 0.5625rem;
              transform: translateY(-6px) rotate(0deg);
              overflow: visible;

              path {
                fill: $ay-white;
              }
            }

            // support for MS IE11/10 Browsers
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              height: 0.5625rem;
              width: 0.5625rem;
              transform: translateY(-6px) rotate(0deg);
              overflow: visible;

              path {
                fill: $ay-white;
              }
            }

            use {
              height: 0.9375rem;
              fill: $ay-white;
              stroke: transparent;
            }
          }
        }

        h4 {
          color: $ay-white;
          font-size: 1.125rem;
          font-weight: 700;
        }
      }

      p {
        color: $ay-white;
      }

      &--border {
        background: $ay-white;
        .ay-careers-landing__expandable--header-text{
          position: relative;
          width: 100%;
          &:after {
            background: $ay-black;
            border-top: 6px solid $ay-black;
            bottom: 0px;
            content: "";
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
          }
        }
        h4 {
          color: $ay-black;
        }
        a {
          color: $ay-black !important;
        }
        p {
          color: $ay-black;
        }
      }

    }

    //combined opening stuff
    &--open {
      .ay-careers-landing__expandable--header-text__readmore {
        span {
          &:nth-child(1) {
            display: none;
          }

          &:nth-child(2) {
            display: inline;
          }
        }
      }

      svg {
        transform: translateY(2px) rotate(90deg);

        // fix for issue with firefox being a jerk about it
        @-moz-document url-prefix() {
          transform: translateX(5px) rotate(45deg);
        }

        // support for MS Edge Browser
        @supports (-ms-ime-align: auto) {
          transform: translate(8px, -4px) rotate(90deg);
        }

        // support for MS IE11/10 Browsers
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          transform: translate(8px, -4px) rotate(90deg);
        }
      }
    }

    &--body {
      padding: 35px;
      font-size: 1rem;
      line-height: 1.5rem;
      display: none;
    }
  }
}

/*
Testimonial Block

Used in the Career testimonial pages

Markup: careers-testimonials.hbs

Styleguide 01-Basics~03-Typography.40-Careers-Testimonials

*/

.ay-careers-testimonials {
  &--list {
    margin: 0;
    padding: 0;
    font-size: 12px;
    list-style: none;

    &__testimonial-item {

        p {
          color: $ay-black;

          //every 5th li stating at 5th li (dark blue)

        }

        ::selection {
          background: $ay-black;

          /* WebKit/Blink Browsers */
          color: $ay-white;
        }

        ::-moz-selection {
          background: $ay-black;

          /* Gecko Browsers */
          color: $ay-white;
        }




      blockquote {
        padding: 0;
        margin: 0;
        border: none;
      }

      blockquote:before {
        content: "";
      }

      blockquote:after {
        content: "";
      }

      blockquote > p:before {
        content: "";
      }

      blockquote > p:after {
        content: "";
      }

      p {
        font-size: 18px;
        line-height: 1.8;
      }
    }
  }
}

/*
Current Opportunities

Markup: careers-current-opportunities.hbs

Styleguide Careers.Careers-Current-Opportunities

*/

.ay-careers--current-opportunities {
  color: $ay-black;

  a {
    color: $ay-color-accent-light-blue;
    text-decoration: none;
  }

  &__filter-holder {
    margin-bottom: 25px;

    @media screen and (max-width: $tablet-portrait) {
      width: 100%;
      background: $ay-white;
      padding: 1.875rem 0.9375rem;
    }

    label {
      display: block;
      color: $ay-black;
      margin-bottom: 15px;
    }

    select {
      appearance: none;
      outline: none;
      border: none;
      background: $ay-secondary-colors--extra-light url(images/arrow-select-black.svg) no-repeat 97% center;
      background-size: 25px;
      padding: 1.125rem;
      font-size: 0.8125rem;
      color: $ay-black;
      min-width: 300px;
      height: auto;

      // to override set height in aui styles
      &::-moz-focus-inner {
        border: 0;
      }

      option {
        &::-moz-focus-inner {
          border: 0;
        }
      }

      @media screen and (max-width: $tablet-portrait) {
        width: 99%;
      }
    }
  }

  &__table {
    margin-bottom: 50px;
    width: 100%;
    border-collapse: collapse;

    @media screen and (max-width: $tablet-portrait) {
      background: $ay-white;
    }

    @media screen and (max-width: $phone) {
      margin-bottom: 0;
    }

    thead {
      tr {
        background: $ay-black;

        th {
          text-align: left;
          color: $ay-white;
          padding: 12px;
          font-weight: 600;
          margin: 0;
          text-transform: uppercase;
          font-size: 18px;
          outline: none;

          @media screen and (max-width: $phone) {
            font-size: 13px;
            padding: 6px 10px;
          }

          div {
            outline: none;

            &:after {
              content: "";
              background: url(images/arrow-sort.svg) no-repeat 97%;
              width: 20px;
              height: 20px;
              display: inline-block;
              transition: all .5s;
              transform: translateY(1px) translateX(5px) rotate(90deg);
            }
          }

          &.tablesorter-headerUnSorted div:after {
            transform: translateY(2px) rotate(0deg);
          }

          &.tablesorter-headerAsc div:after {
            transform: translateY(6px) rotate(-90deg);
          }
        }
      }
    }

    tbody {
      tr {
        &:hover {
          td {
            background: $ay-secondary-colors--extra-light;
          }
        }

        td {
          transition: background .5s;
          border-bottom: 1px solid $ay-black;
          padding: 20px 14px;
          width: calc(30%);
          font-size: 16px;
          vertical-align: center;

          &:first-child {
            width: calc(50%);
          }

          &:last-child {
            width: calc(20%);
          }

          @media screen and (max-width: $tablet-portrait) {
            width: calc(33.3%);

            &:first-child {
              width: calc(33.3%);
            }

            &:last-child {
              width: calc(33.3%);
            }
          }

          @media screen and (max-width: $phone) {
            padding: 12px 10px;
            font-size: 13px;
            width: auto;
          }

          a {
            color: $ay-black;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }

            @media screen and (max-width: $tablet-portrait) {
              color: $ay-secondary-colors--extra-dark;
            }
          }
        }
      }
    }
  }
}

/*
Careers Job Listing & Form

Markup: careers-listing-form.hbs

Styleguide Careers.Careers-Listing-Form

*/

.ay-careers-post {
  width: 70%;
  margin: 20px auto;
  position: relative;

  @media screen and (max-width: $phone) {
    width: 100%;
  }

  @media print {
    width: 100%;
  }

  .h2, h2 {
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 120%;

    @media screen and (max-width: $tablet-portrait) {
      font-size: 32px;
      margin-bottom: 25px;
    }
  }

  .h3, h3 {
    font-weight: bold;
    margin: 30px 0 5px 0;
    font-size: 18px;
  }

  .ay-ul__unstyle {
    margin: 0;
    padding: 0;
  }

  &__subtitle {
    font-size: 1.375rem;
    color: #797979;
    font-weight: bold;
    margin-bottom: 0.875rem;

    @media screen and (max-width: $phone) {
      padding-top: 80px;
      font-size: 1.25rem;
    }
  }

  &__boiler {
    border-top: 1px solid #CCC;
    padding: 1.875rem 0;
    margin-top: 2em;
  }

  small {
    font-weight: $semibold;
    font-size: 0.75rem;
    color: $ay-black;
    margin-bottom: 0.625rem;
    display: block;
  }

  p {
    margin-top: 0;
  }

  &__content {
    position: relative;

    @media screen and (max-width: $phone) {
      padding-top: 80px;
    }
  }

  &__social {
    border-top: 1px solid $ay-black;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    left: -85px;
    padding-top: 1.25rem;
    position: absolute;
    top: 10px;
    width: 55px;

    @media screen and (max-width: $phone) {
      align-items: flex-start;
      flex-direction: row;
      left: 0;
      width: 100%;
    }

    @media print {
      display: none;
    }

    &--icon {
      //color: #C4C4C4;
      display: block;
      fill: transparent;
      height: 30px;
      margin-bottom: 12px;
      max-width: 50px;
      position: relative;
      stroke: $ay-black;
      width: 100%;

      /* &:hover {
        color: $ay-secondary-colors--something-dark;
      } */
    }
  }

  .ay-jobform {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    input {
      border: 1px solid $ay-secondary-colors--medium-medium;
      color: $ay-secondary-colors--extra-dark;
      max-width: 480px;
      margin-bottom: 15px;
      padding: .2em .5em;
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    label {
      margin-bottom: 5px;
      width: auto;
    }
  }

  .ay-resume {
    width: 100%;
    max-width: 480px;
    padding: 1em 0;

    span {
      margin-bottom: 5px;
    }

    &__upload {
      display: flex;
      justify-content: space-between;
      padding: .5em 0;
      width: 100%;

      @media screen and (max-width: $phone) {
        flex-direction: column;
      }
    }

    .ay-form-button {
      border: 1px solid $ay-black;
      color: $ay-white;
      cursor: pointer;
      max-width: 160px;
      padding: .3em;
      text-transform: uppercase;
      transition: all .5s;
      width: 100%;

      @media screen and (max-width: $phone) {
        max-width: 100%;
        margin: .3em 0;
        padding: 1em;
      }

      &__browse-resume {
        background: $ay-black;
        color: $ay-white;
        &:hover {
          background: $ay-white;
          color: $ay-black;
        }
      }

      &__submit-resume {
        background: $ay-white;
        color: $ay-black;
        &:hover {
          background: $ay-black;
          color: $ay-white;
        }
      }
    }
  }
}

// Icon descriptions
.ay-job__email-share {
  position: relative;

  /* &:hover {
    &:before {
      content: attr(data-title);
      position: absolute;
      line-height: normal;
      font-size: 10px;
      margin-top: 8px;
      top: 0;
      left: -75px;
      width: 100px;
      color: $ay-secondary-colors--something-dark;
    }
  } */
}

.ay-job__twitter-share {
  position: relative;

 /*  &:hover {
    &:before {
      content: attr(data-title);
      position: absolute;
      font-size: 10px;
      line-height: normal;
      margin-top: 8px;
      top: 0;
      left: -82px;
      width: auto;
      color: $ay-secondary-colors--something-dark;
    }
  } */
}

.ay-job__fb-share {
  position: relative;

 /*  &:hover {
    &:before {
      content: attr(data-title);
      position: absolute;
      font-size: 10px;
      line-height: normal;
      margin-top: 8px;
      top: 0;
      left: -92px;
      width: auto;
      color: $ay-secondary-colors--something-dark;
    }
  } */
}

.ay-job__googleplus-share {
  position: relative;
/*
  &:hover {
    &:before {
      content: attr(data-title);
      position: absolute;
      font-size: 10px;
      line-height: normal;
      margin-top: 8px;
      top: 0;
      left: -104px;
      width: auto;
      color: $ay-secondary-colors--something-dark;
    }
  }*/
}

.ay-job__linkedin-share {
  position: relative;

  /*&:hover {
    &:before {
      content: attr(data-title);
      position: absolute;
      font-size: 10px;
      line-height: normal;
      margin-top: 8px;
      top: 0;
      left: -90px;
      width: auto;
      color: $ay-secondary-colors--something-dark;
    }
  } */
}
