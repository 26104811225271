// Color Palette
//
// The Avison Young color palette used online
//
// Style guide: 01-Basics~01-Colors


// Primary Colors
//
// The fundamental, primary color options
//
// Colors:
// White:                   #fff - $ay-white
// Black:                   #000 - $ay-black
// CloudGrey:               #f1f2f2 - $ay-primary-color--cloud-grey-light
// CloudGreyDark:           #B8BCBE - $ay-primary-color--cloud-grey-dark
//
// Style guide: 01-Basics~01-Colors.01PrimaryColors

$ay-white: #fff;
$ay-black: #000;
$ay-primary-color--cloud-grey-light: #f1f2f2;
$ay-primary-color--cloud-grey-dark: #B8BCBE;

// old primary colors will need to be removed once updates are complete
$ay-general: #797979;
$ay-primary-colors--light: #fff;
$ay-primary-colors--medium-light: #32b8df;
$ay-primary-colors--medium: #da291c;
$ay-primary-colors--medium-dark: #a9a19c;
$sa-primary-colors--medium-medium-dark: #4a4a4a;

// following colors left to prevent breakage with updates removed kss display of these colors

$ay-secondary-colors--extra-light: #f6f6f6;
$ay-secondary-colors--secondary-extra-light: #f5f5f5;
$ay-secondary-colors--light: #f48b3b;
$ay-secondary-colors--medium: #6fab37;
$ay-secondary-colors--medium-medium: #e9e9e9;
$ay-secondary-colors--medium-medium-dark: #8f8f8f;
$ay-secondary-colors--extra-dark: #797979;
$ay-secondary-colors--something-dark: #dc260a;

// Accent Colors
//
// The array of accent colors available
//
// Colors:
// AccentBlue:              #191640 - $ay-color-accent-light-blue
// AccentDarkBlue:               #110B37 - $ay-color-accent-dark-blue
// AccentOrange:            #EF6528 - $ay-color-accent-light-orange
// AccentDarkOrange:             #BC561D - $ay-color-accent-dark-orange
// AccentAmethyst:          #55489D - $ay-color-accent-light-amethyst
// AccentDarkAmethyst:           #40357E - $ay-color-accent-dark-amethyst
// AccentMint:              #4DB595 - $ay-color-accent-light-mint
// AccentDarkMint:               #30816A - $ay-color-accent-dark-mint
// AccentPerwinkle:         #7E9CD1 - $ay-color-accent-light-perwinkle
// AccentDarkPerwinkle:          #60779F - $ay-color-accent-dark-perwinkle
// AccentRed:               #C52B1D - $ay-color-accent-light-red
// AccentDarkRed:                #94070B - $ay-color-accent-dark-red
// AccentStone:             #7D7370 - $ay-color-accent-light-stone
// AccentDarkStone:              #5F5956 - $ay-color-accent-dark-stone
// AccentMidnight:          #353F52 - $ay-color-accent-light-midnight
// AccentDarkMidnight:           #262E3E - $ay-color-accent-dark-midnight
//
// Style guide: 01-Basics~01-Colors.02AccentColors2020

$ay-color-accent-light-blue: #191640;
$ay-color-accent-dark-blue: #110B37;
$ay-color-accent-light-orange: #EF6528;
$ay-color-accent-dark-orange: #BC561D;
$ay-color-accent-light-amethyst: #55489D;
$ay-color-accent-dark-amethyst: #40357E;
$ay-color-accent-light-mint: #4DB595;
$ay-color-accent-dark-mint: #30816A;
$ay-color-accent-light-perwinkle: #7E9CD1;
$ay-color-accent-dark-perwinkle: #60779F;
$ay-color-accent-light-red: #C52B1D;
$ay-color-accent-dark-red: #94070B;
$ay-color-accent-light-stone: #7D7370;
$ay-color-accent-dark-stone: #5F5956;
$ay-color-accent-light-midnight: #353F52;
$ay-color-accent-dark-midnight: #262E3E;

// Additional Accent Colors
//
// NOTE: These colors are intended for charts only!
//
// Colors:
// AccentTeal:               #3189A1 - $ay-color-accent-light-teal
// AccentDarkTeal:               #256E81 - $ay-color-accent-dark-teal
// AccentBrightBlue:               #20C4F4 - $ay-color-accent-light-brightblue
// AccentDarkBrightBlue:               #1B9CC2 - $ay-color-accent-dark-brightblue
// AccentHeather:               #A6849A - $ay-color-accent-light-heather
// AccentDarkHeather:               #846479 - $ay-color-accent-dark-heather
// AccentBronze:               #8E7B2E - $ay-color-accent-light-bronze
// AccentDarkBronze:               #6B5D1E - $ay-color-accent-dark-bronze
// AccentJuniper:               #5E7773 - $ay-color-accent-light-juniper
// AccentDarkJuniper:               #455B58 - $ay-color-accent-dark-juniper
// AccentWheat:               #DFB804 - $ay-color-accent-light-wheat
// AccentDarkWheat:               #A88D00 - $ay-color-accent-dark-wheat
//
//
// Style guide: 01-Basics~01-Colors.03AccentColors2020

$ay-color-accent-dark-teal: #256E81;
$ay-color-accent-light-teal: #3189A1;
$ay-color-accent-dark-brightblue: #1B9CC2;
$ay-color-accent-light-brightblue: #20C4F4;
$ay-color-accent-dark-heather: #846479;
$ay-color-accent-light-heather: #A6849A;
$ay-color-accent-dark-bronze: #6B5D1E;
$ay-color-accent-light-bronze: #8E7B2E;
$ay-color-accent-dark-juniper: #455B58;
$ay-color-accent-light-juniper: #5E7773;
$ay-color-accent-dark-wheat: #A88D00;
$ay-color-accent-light-wheat: #DFB804;

$base-font-size: 1rem;
$font-size-h1: 3.9rem;
$font-size-h2: 1.875rem;
$font-size-h3: 1.6875rem;
$font-size-h4: 1.125rem;
$font-size-h5: 1.0625rem;
$header-color: $ay-black;


// Typography

$header-font-weight: 600;

$header-font: "GeoSlab703 Md BT W05 Bold";
$header-font-pl: 'Zilla Slab', serif;
$header-font-ru: 'Roboto Slab', serif;
$header-font-vi: 'Roboto Slab', serif;

$base-font: 'Open Sans', sans-serif;
$semibold: 600;


// Widths
$desktop-large: 1300px;
$desktop: 1200px;
$tablet-landscape: 1000px;
$tablet-portrait: 768px;
$phone: 480px;


// Box Shadow
$ay-shadow: none;



html {
  line-height: normal;
}

body {
	font-family: $base-font;
  color: $ay-black;
  p {
    line-height: 1.4em;

    @media print {
      font-size: 10px;
    }
  }

}

textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
     -webkit-appearance: none;
     border-radius: 0;
}

// Lightbox
.ay-lightbox {
  align-items: center;
  background-color: rgba(0,0,0, .8);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

:focus {
  outline: 1px solid $ay-black;
}

// Flex Grids
.ay-flex-grid {
	display: flex;
	justify-content: space-between;
}

.ay-flex-start-grid {
  display: flex;
  flex-wrap: wrap;
}

.ay-updated-content-link-style {
  text-decoration: underline solid $ay-primary-color--cloud-grey-dark;
  transition: text-decoration-color .5s;
  &:hover {
    text-decoration: underline solid $ay-black;
  }
}
