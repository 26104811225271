/*
Buttons

Styleguide Buttons
*/

/*
Primary Button

--white-text       - button with white text on black background initial state (default)
--black-text       - button with black text on white background initial state

Markup: buttons.hbs

Styleguide Buttons.01Primary-Button
*/

/*
Multiple Function Button

Markup: multiple-use-button.hbs

Styleguide Buttons.Multiple-Use-Button
*/

/*
Multiple Function Button with Secondary Text

Markup: multiple-use-buttom-secondary-text.hbs

Styleguide Buttons.Multiple-Use-Button.Secondary-Text
*/

/*
Multiple Function Button with Secondary Text and Quick Finder

Markup: multiple-use-button-secondary-txt-qf.hbs

Styleguide Buttons.Multiple-Use-Button.Secondary-Text-Quick-Finder
*/

.ay-button__primary {
  align-items: center;
  background-color: $ay-white;
  border: 3px solid $ay-black;
  color: $ay-black !important;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  margin-left:50%;
  padding: 15px 2em;
  position: relative;
  text-align: center;
  text-decoration: none !important;
  text-transform: uppercase;
  transition: .3s all;
  transform:translateX(-50%);
  width: auto;
  @media print {
    font-size: 16px;
  }

  &:hover, &:focus, &:active {
    background-color: $ay-black;
    color: $ay-white !important;
    cursor: pointer;
  }
  &--black-text {
    background-color: $ay-white;
    border: 3px solid $ay-black;
    color: $ay-black !important;
    &:hover, &:focus, &:active {
      background-color: $ay-black;
      color: $ay-white !important;
      cursor: pointer;
    }
  }

  &--arrow {
    margin-bottom: 25px;
    text-align: left;
    //padding-left: 30px;
    font-size: 30px;
    font-weight: 600;

    &:after {
      content: '';
      background: url(images/arrow-right-black.svg) no-repeat;
      display: inline-block;
      height: 18px;
      left: 8px;
      position: relative;
      width: 14px;
      top: -1px;
    }
    &:hover:after {
      content: '';
      background: url(images/arrow-right-white-white-border.svg) no-repeat;
    }

    @media screen and (max-width: $phone) {
      padding: 15px 20px;
      font-size: 24px;
    }
  }
}



.ay-button__secondary {
  align-items: center;
  background-color: $ay-black;
  border: 2px solid $ay-black;
  color: $ay-white;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  padding: 15px 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: .3s all;
  width: 100%;

  &:hover, &:focus, &:active {
    background-color: $ay-white;
    color: $ay-black;
    cursor: pointer;
  }
}

/*
Primary Button with Arrow

Markup: primary-button-arrow.hbs

Styleguide Buttons.Primary-Button-Arrow
*/

/*
Secondary Button with Arrow Color

Markup: secondary-button-arrow.hbs

Styleguide Buttons.Secondary-Button-Arrow.ltr
*/

.ay-button__arrow {
  align-items: center;
  background-color: $ay-white;
  border: 2px solid $ay-black;
  color: $ay-black !important;
  display: flex;
  height: 40px;
  font-size: 1.125rem;
  margin-bottom: 12px;
  padding: 0.625rem;
  position: relative;
  text-decoration: none;
  transition: .4s all;
  width: 100%;

  @media screen and (max-width: $phone) {
    font-size: 1.25rem;
    height: auto;
    padding: 1.5625rem 0.625rem;
  }

  a {
    color: $ay-general;
    text-decoration: none;

    &:hover {
      color: $ay-primary-colors--light;
      text-decoration: none;
    }
  }

  &:hover, &:active, &:focus {
    background: $ay-white;
    color: $ay-black !important;
    cursor: pointer;
    text-decoration: underline;
  }

  &:after {
    background: $ay-black url(images/arrow-right.svg) no-repeat;
    background-size: 14px;
    background-position: center center;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;

    @media screen and (max-width: $phone) {
      width: 35px;
      background-size: 13px;
    }
  }
}

//for updated arrow button on gray background color refresh
.ay-main-grey a.ay-button__arrow {
  background: $ay-white;
  color: $ay-black !important;
  &:hover {
    background: $ay-white;
    color: $ay-black !important;
  }
}

// Special class added in Liferay to control heading between buttons

.child-menu-item.menu-item-header {
  font-size: 1.125em;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
  text-transform: uppercase;
}

// start of multiple function button css

.layout-column_column-1{
  .ay-multiple-button-wrap{
    display:flex;
    justify-content:flex-end;
    width:100%;
    a{
      background:$ay-white;
      border: 3px solid $ay-black;
      color: $ay-black;
      font-family: $header-font;
      font-size: 1.875em;
      padding:20px;
      text-decoration:none;
      transition: all .5s;
      width:420px;
      &:hover {
        background:$ay-black;
        color: $ay-white;
        text-decoration: none;
      }
    }
  }

}

.ay-column__triple--col2.portlet-column{

  .ay-multiple-button-wrap{
    background:$ay-black;
    display:flex;
    flex-direction:column;
    width:420px;
    a{
      background:$ay-black;
      color: $ay-white;
      font-family: $header-font;
      font-size: 1.875em;
      line-height: normal;
      padding:20px;
      text-decoration:none;
      transition: color .5s;
      width:420px;
      span{
        color:$ay-white;
        display:block;
        font-family: $base-font;
        font-size:16px;
        font-weight:100;
        width:300px;
      }
      &:hover {
        text-decoration: underline;
        span{
          color:$ay-white;
        }
      }
    }
    .ay-multiple-button-quick-finder{
      border-top:1px solid $ay-white;
      margin:0 20px;
      padding:10px 0 20px;
      h3{
        color: $ay-white;
        font-weight:500;
      }
      ul{
        list-style:none;
        margin:12px 0;
        padding:0;
        display:flex;
        flex-wrap:wrap;
        li{
          margin:0;
          padding:0;
          a{
            font-family: $base-font;
            font-size:16px;
            font-weight:600;
            text-transform:uppercase;
            color:$ay-white;
            padding:0;
            &:after{
              content:"";
              display:inline-block;
              height:0;
              margin:0 5px 0 8px;
              width:0;
              border-top: 6px solid transparent;
              border-left: 8px solid $ay-white;
              border-bottom: 6px solid transparent;
            }
          }
        }
      }
    }
    &.ay-multiple-button-black-white {

      a {
        background: $ay-white;
        border: 3px solid $ay-black;
        color: $ay-black;
        transition: all .5s;
        span {
          color: $ay-black;
          transition: all .5s;
        }
        &:hover {
          background: $ay-black;
          color: $ay-white;
          text-decoration: none;
          span {
            color: $ay-white;
          }
        }
      }
    }
  }




}




