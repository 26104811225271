/*
Default List

Markup: lists.hbs

.ay-balcol - column wrap for really long lists.

Styleguide 01-Basics~03-Typography.30-Default-List
*/

/*
Unstyled List

Markup: lists-unstyled.hbs

.ay-balcol - column wrap for really long lists.

Styleguide 01-Basics~03-Typography.31-Unstyled-List
*/

/*
List Links

Markup: lists-links.hbs

Styleguide 01-Basics~03-Typography.35-List-Links
*/

.ay-ul  {
  list-style: none;
  padding: 0;
  margin: 0 0 0 10px;
  line-height: 1.5;

  &__unstyled li {
    margin: 0 0 0.625rem 0;

    &:before {
      content: "";
      display: none;
    }
  }

  &__styled  {
    list-style: none;
    list-style-type:none;
    padding-left:unset;
    padding: 0;
    position: relative;
    ul{
      margin-left: 15px;
    }
    li {
      margin: 0;
      //padding: 0 0 6px 10px;
      position: relative;
      line-height: 1.5em;
      //text-indent: -10px;
      &:before {
        content: "\2013";
        padding-right:.4em;
        display: inline-block;
        /* height: 10px;
        width: 10px;
        margin-left: -8px; */
      }
    }
  }

  &.ay-balcol {
    column-count: 3;
    column-gap: 1em;
    column-fill: balance;
    @media screen and (max-width:900px) {
      column-count: 2;
    }
    @media screen and (max-width:700px) {
      column-count: 1;
    }
    &>li {
      margin-right: 1em;
      page-break-inside: avoid;
      break-inside: avoid-column;
    }
  }
}

.ay-unordered-row {
  display: flex;
  margin-bottom: 1em;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: $tablet-portrait) {
    flex-direction: column;
  }

  ul {
    list-style: none;
    line-height: 1.5;
    margin: 0 20px 0 0;
    padding: 0;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: $tablet-portrait) {
      margin: 0 0 1em 0;
    }

    &.ay-balcol {
      column-count: 3;
      column-gap: 1em;
      column-fill: balance;
      @media screen and (max-width:900px) {
        column-count: 2;
      }
      @media screen and (max-width:700px) {
        column-count: 1;
      }
      &>li {
        margin-right: 1em;
        page-break-inside: avoid;
        break-inside: avoid-column;
      }
    }
  }
}

ul {
  &.ay-balcol {
    column-count: 3;
    column-gap: 1em;
    column-fill: balance;
    @media screen and (max-width:900px) {
      column-count: 2;
    }
    @media screen and (max-width:700px) {
      column-count: 1;
    }
    &>li {
      margin-right: 1em;
      page-break-inside: avoid;
      break-inside: avoid-column;
    }
  }
  &.ay-list-links {
    list-style: none;
    margin: 0;
    padding: 0 0 15px 0;
    li{
      border-bottom: 2px solid $ay-black;
      font-weight: 700;
      padding: 5px;
      a {
        color: $ay-black;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.dashed-list {
  list-style-type:none;
  padding-left:unset;
  li {
    ul{
      margin-left: 15px;
    }
    &:before {
      content:"\2013";
      padding-right:.4em;
    }
  }
}
