/*
Quick Search

Markup: quick-search.hbs 

Styleguide Quick Search
*/

.ay-quick-search {
  background-color: $ay-white;
  border-bottom: 7px solid $ay-black;
  box-shadow: $ay-shadow;
  padding: 30px 40px 0px 40px;
  max-width: 1300px;
  margin:20px auto 0;

  &__title {
    font-size: 30px;
    color: $ay-black;
    border-bottom: 1px solid $ay-black;
    padding-bottom: 14px;
    margin-bottom: 25px;
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__block {
    width: 265px;
    margin-right: 40px;
    margin-bottom: 20px;

    &__title {
      color: $ay-black;
      font-weight: $semibold;
      font-size: 16px;
      font-weight: bold;
    }

    &__subtitle {
      color: $ay-black;
      font-size: 15px;
      font-weight: $semibold;
      margin-top: 8px;
    }

    &__list {
      list-style: none;
      margin: 8px 0 0 0;
      padding: 0;
    }

    &__list--item {
      color: $ay-black;
      display: inline-block;
      font-size: 14px;
      margin-bottom: 5px;
      position: relative;
      text-decoration: none;

      &:after {
        content: "";
        background: url(images/arrow-right-black.svg) no-repeat;
        background-size: 9px;
        display: inline-block;
        height: 12px;
        margin-left: 8px;
        position: relative;
        top: 2px;
        width: 15px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
