/*
Page Title

Markup: page-title.hbs

Styleguide 01-Basics~03-Typography.10-Page-Title
*/

.ay-title-header {
  align-items: flex-start;
  background-color: transparent;
  color: $ay-black;
  display: flex;
  flex-direction: column;
  margin:  auto;
  max-width: $desktop-large;
  text-align: left;
  width: 100%;
  padding: 1.875rem 0;
  &.ay-title-header-grey{
    background-color: $ay-secondary-colors--secondary-extra-light;
    max-width: unset;
    h1,
    .h1 {
      color: $ay-black;
      margin: auto;
      max-width: $desktop-large;
      padding-left: 40px;
      width: 100%;

      @media screen and (max-width: 1080px) {
        padding-left: 20px;
      }
    }

    .ay-title-header__breadcrumbs {
      margin: auto;
      max-width: $desktop-large;
      padding-left: 40px;
      width: 100%;

      @media screen and (max-width: 1080px) {
        padding-left: 20px;
      }
    }
    .ay-breadcrumb__item {
    /*  background: url(images/arrow-breadcrumb.svg) no-repeat;
      background-position: right;
      color: $ay-black; */
    }
  }
  &.ay-title-header-refresh{
    align-items: flex-start;
    background-color: transparent;
    border-top: none;
    h1,
    .h1 {
      align-items: flex-start;
      color: $ay-black;
      margin: auto;
      max-width: $desktop-large;
      padding: 0;
      text-align: left;
      width: calc(100%);

      @media screen and (max-width: 768px){
        padding: 0 8%;
      }
    }

  }

  @media screen and (max-width: $phone) {
    padding: 0.9375rem;
  }

  @media print {
    text-align: left;
    align-items: flex-start;
    padding: 20px 0 0 0;
  }

  &__title {
    color: $ay-black;
    font-size: 3.9375rem;
    margin: 0 40px;

    @media screen and (max-width: $desktop-large) {
      margin-left: 42px;
    }

    @media screen and (max-width: 1080px) {
      margin-left: 20px;
    }

    @media screen and (max-width: $tablet-portrait) {
      line-height: 110%;
      font-size: 2.6875rem;
    }

    @media screen and (max-width: $phone) {
      font-size: 2.1875rem;
      margin-top: 140px;
      padding: 10px 15px;
    }

    @media print {
      font-size: 24px;
    }
  }

  &__subtitle {
    font-size: 3.9375rem;

    @media screen and (max-width: $tablet-portrait) {
      line-height: 110%;
      font-size: 2.6875rem;
    }

    @media screen and (max-width: $phone) {
      font-size: 2.1875rem;
      padding: 10px 15px;
    }

    @media print {
      font-size: 24px;
    }
  }
}

/*
Page Title w/ Breadcrumbs

Markup: page-title-breadcrumbs.hbs

Styleguide 01-Basics~03-Typography.10-Page-Title-Breadcrumbs
*/

.ay-title-header__breadcrumbs {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  margin: 0.375rem 0 0.375rem 40px;
  padding: 0;
  width: 100%;

  @media screen and (max-width: $desktop-large) {
    margin-left: 42px;
  }

  @media screen and (max-width: 1080px) {
    margin-left: 20px;
  }

  @media screen and (max-width: $phone) {
    margin: 6px 0 0 0;
    text-align: left;
    flex-wrap: wrap;
  }

  @media print {
    display: none;
  }
}

.ay-breadcrumb {
  display: flex;
  margin: 5px;
  line-height: normal;

  @media screen and (max-width: $phone) {
    margin: 0 5px 0 0;
  }
  &:after {
    content: "/";
    display: inline-block;
    justify-self: flex-end;
    margin-left: 15px;
    @media screen and (max-width: $phone) {
      margin-left: 5px;
      margin-top: -4px;
    }
  }
  &:last-child() {
    &:after {
        display: none;
    }
  }

  &__item {
    color: $ay-black;
    display: flex;
    font-size: 0.9375rem;
    text-decoration: none;
    text-transform: uppercase;
    text-decoration-skip: objects;
    -webkit-text-decoration-skip: objects;

    @media screen and (max-width: $phone) {
      font-size: 11px;
      background-size: 8px;
      //padding-right: 12px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ay-title-header__title {
    width: 100%;
  }
}
