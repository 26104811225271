/*
Blog

Styleguide Blog
*/

/*
Blog Tiles

Markup: blog-tiles.hbs

Styleguide Blog.Blog-Tiles
*/

/*
Blog Tiles (3 across)

.ay-three-default       - default 3-column layout
.ay-four-wide-or       - override to 4 column when scren is over 1100px wide

Markup: blog-3-columns.hbs

Styleguide Blog.Blog-Tiles.3-across
*/

/*
Blog Tiles (Fixed Height)

Markup: blog-tiles-fixed-height.hbs

Styleguide Blog.Blog-Tiles.Fixed-Height
*/

/*
Blog Tiles Title Only (Fixed Height)

Markup: blog-tiles-title-only-fixed-height.hbs

Styleguide Blog.Blog-Tiles.Title-Only-Fixed-Height
*/

/*
Blog Tiles Popular Posts

Markup: blog-tiles-popular-posts.hbs

Styleguide Blog.Blog-Tiles.Popular-Posts

*/

/*
Blog Detail Footer

Markup: blog-detail-footer.hbs

Styleguide Blog.Blog-Detail.Footer

*/

.ay-blog-tiles-wrap {
  margin: auto;
  max-width: $desktop-large;
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin: 0 10px 10px 0;
      position: relative;
      @media screen and (min-width: 1100px) {
        width: calc((100% / 4) - 7.5px);
        &:nth-child(4n + 4) {
          margin: 0 0 10px 0;
        }
      }
      @media screen and (max-width: 1099px) and (min-width: 800px) {
        width: calc((100% / 3) - 7.5px);
        &:nth-child(3n + 3) {
          margin: 0 0 10px 0;
        }
      }
      @media screen and (max-width: 799px) and (min-width: 540px) {
        width: calc((99.99% / 2) - 4.985px);
        &:nth-child(even) {
          margin: 0 0 10px 0;
        }
      }
      @media screen and (max-width: 539px) {
        width: 100%;
        margin: 0 0 10px 0;
      }

      a {
        background: #fff;
        color: $ay-black;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        text-decoration: none !important;
        &:after {
          border-bottom: 3px solid $ay-color-accent-dark-orange;
          content: "";
          margin-top: 20px;
          transition: all 0.5s;
          width: 0;
        }
        &:hover:after {
          width: 100%;
        }
        span {
          background: $ay-color-accent-light-blue;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
          color: #fff;
          display: none;
          font-size: 11px;
          left: 0;
          max-width: 350px;
          overflow: hidden;
          padding: 5px 20px;
          position: absolute;
          text-transform: uppercase;
          top: 20px;
          transition: all 1.5s;
          em {
            font-style: normal;
            position: relative;
            z-index: 3;
          }
          &:empty {
            display: none;
          }
        }
        span:before {
          background: $ay-black;
          content: "";
          height: 100%;
          left: 0;
          padding: 5px 20px;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 1;
        }
        span:after {
          background: $ay-color-accent-dark-orange;
          color: #fff;
          content: "";
          height: 100%;
          left: 0;
          padding: 5px 0;
          position: absolute;
          text-transform: uppercase;
          top: 0;
          transition: all 0.5s;
          width: 0;
          z-index: 2;
        }
        &:hover span:after {
          padding: 5px 20px;
          width: 100%;
        }
        figure {
          margin: 0;
          padding: 0;
          div {
            max-height: 220px;
            overflow: hidden;
          }
          img {
            height: 220px;
            object-fit: cover;
            overflow: hidden;
            transition: all 0.5s;
            width: 100%;
          }
          figcaption {
            padding: 1.25rem 1.875rem;
          }
          .ay-blog-tile-title {
            font-size: 20px;
            font-weight: 600;
            letter-spacing: -0.005em;
            line-height: 124%;
            margin: 0 0 14px 0;
          }
          &:hover {
            .ay-blog-tile-title {
              text-decoration: underline;
            }
          }
          .ay-blog-tile-blurb {
            color: $ay-black;
            font-size: 15px;
            font-weight: 400;
            word-wrap: break-word;
            letter-spacing: 0.005em;
          }
        }
        &:hover figure img {
          transform: scale(1.1);
        }
      }
      .ay-blog-post-td {
        background: url(images/icons-2020/visual/clock.svg) no-repeat left 5px;
        background-size: 15px;
        font-size: 11px;
        margin: 0 1.875rem;
        padding: 5px 0 5px 19px;
      }
      ul.ay-blog-tile-post-tags {
        margin: 15px 0 0 30px;
        display: none;
        li {
          align-items: center;
          color: $ay-black;
          display: flex;
          flex-wrap: wrap;
          font-size: 12px;
          justify-content: center;
          margin-right: 0;
          width: auto;
          &:after {
            background: $ay-black;
            border-radius: 100%;
            content: "";
            display: inline-block;
            height: 3px;
            width: 3px;
            margin-left: 8px;
            margin-right: 8px;
          }
          &:last-child:after {
            display: none;
          }
        }
      }
    }
  }
  &.ay-fixed-height {
    ul li a figure {
      .ay-blog-tile-title {
        height: 105px;
        max-height: 105px;
        position: relative;
        &:after {
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0,
            rgba(255, 255, 255, 0.5) 10%,
            rgba(255, 255, 255, 1) 100%
          );
          bottom: 0;
          content: "";
          display: block;
          height: 16px;
          position: absolute;
          width: 100%;
          z-index: 20;
        }
      }
      .ay-blog-tile-blurb {
        height: 76px;
        max-height: 76px;
        position: relative;
        &:after {
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0,
            rgba(255, 255, 255, 0.5) 10%,
            rgba(255, 255, 255, 1) 100%
          );
          bottom: 0;
          content: "";
          display: block;
          height: 10px;
          position: absolute;
          width: 100%;
          z-index: 20;
        }
      }
    }
    &.ay-hp-v {
      ul li {
      }
      ul li a {
        height: 100%;
        position: relative;
        &:after {
          position: absolute;
          bottom: 0;
        }
      }
      ul li a figure {
        .ay-blog-tile-title {
          height: auto;
          max-height: none;
          overflow: visible;
          &:after {
            display: none;
          }
        }
        .ay-blog-tile-blurb {
          display: none;
        }
      }
    }
  }
}

.ay-main-white {
  .ay-blog-tiles-wrap {
    ul li {
      a {
        background: $ay-secondary-colors--secondary-extra-light;
        figure {
          .ay-blog-tile-title {
            &:after {
              background: linear-gradient(
                180deg,
                rgba(245, 245, 245, 0) 0,
                rgba(245, 245, 245, 0.5) 10%,
                rgba(245, 245, 245, 1) 100%
              );
            }
          }
          .ay-blog-tile-blurb {
            &:after {
              background: linear-gradient(
                180deg,
                rgba(245, 245, 245, 0) 0,
                rgba(245, 245, 245, 0.5) 10%,
                rgba(245, 245, 245, 1) 100%
              );
            }
          }
        }
      }
    }
    &.ay-fixed-height {
      ul li {
        a {
          height: 100%;
          //position:relative;
          &:after {
            height: 100%;
            position: relative;
          }
        }
      }
    }
  }
}

.ay-main-grey {
  .ay-blog-tiles-wrap {
    &.ay-fixed-height {
      ul li {
        a {
          height: 100%;
          // position:relative;
          &:after {
            height: 100%;
            position: relative;
          }
        }
      }
    }
  }
}

.ay-main {
  .ay-blog-tiles-wrap {
    @media screen and (max-width: 1372px) {
      margin-left: 42px;
      margin-right: 30px;
      max-width: calc(100% - 72px);
    }
  }
}

// for service line page lts

 .ay-lts-row-layout .ay-services-lts-news-cards-update .ay-blog-tiles-wrap ul li {
  @media screen and (max-width: 1100px) {
    &:nth-child(4) {
      display: none;
    }
  }
  @media screen and (max-width: 799px) {
    &:nth-child(3) {
      display: none;
    }
  }
  @media screen and (max-width: 539px) {
    &:nth-child(2) {
      display: none;
    }
  }
}

.ay-blog-pop-post {
  .h3 {
    border-top: 3px solid $ay-black;
    padding: 20px 0;
  }
  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0 0 10px;
      padding: 0;
      a {
        display: flex;
        text-decoration: underlined transparent solid;
        .ay-blog-pop-post-img {
          height: 80px;
          max-height: 80px;
          max-width: 96px;
          overflow: hidden;
          width: 96px;
          img {
            width: 100%;
            transition: all 0.5s;
            object-fit: cover;
            object-position: center;
          }
          @media screen and (max-width: $phone) {
            display: none;
          }
        }
        &:hover {
          .ay-blog-pop-post-title.h4 {
            text-decoration: underline;
            text-decoration-skip: objects;
          }
          span {
            display: inline-block;
          }
          .ay-blog-pop-post-img img {
            transform: scale(1.1);
          }
        }
        .ay-blog-pop-post-title {
          span {
            background: url(images/icons-2020/visual/clock.svg) no-repeat left
              center;
            display: inline-block;
            min-width: 100%;
            margin-top: 10px;
            padding-left: 25px;
          }
        }
      }
      .ay-blog-pop-post-title {
        font-weight: 700;
        line-height: 1.175em;
        margin-left: 10px;

        width: calc(100% - 100px);
        @media screen and (max-width: $phone) {
          width: 100%;
        }
        span {
          display: block;
          font-weight: 400;
        }
      }
    }
  }
}
.ay-blog-tile-group-cta-link-hold {
  margin: 30px 0 45px 0;
}

.ay-blog-tile-group-cta-link-hold .ay-button__primary {
  margin-left: 50%;
  padding-left: 2em;
  padding-right: 2em;
  transform: translateX(-50%);
}

// blog detail footer styles
.ay-blog-footer {
  .ay-five-row-100-col-5 {
    background: transparent;
    padding-bottom: 0;
    .ay-lts-key-professionals-wrap {
      justify-content: flex-start;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
      .ay-lts-media-block.ay-lts-text-right.ay-lts-key-professionals-item {
        margin: 0;
      }
    }
  }
  .ay-blog-footer-author-links-list {
    list-style: none;
    margin: 0 0 10px;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      a {
        color: $ay-black;
        text-decoration: underline;
        text-transform: uppercase;
      }
    }
  }
  .ay-blog-footer-categories .ay-blog-footer-category-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin: 0 20px 20px 0;
      padding: 0;
      a.ay-button__primary {
        width: auto;
      }
    }
  }
  .ay-blog-footer-copyright {
    background: transparent;
    padding: 30px 0;
    text-transform: uppercase;
  }
}

.ay-blog-post-portlet {
  .ay-blog-tiles-wrap ul li {
    @media screen and (min-width: 1100px) {
      width: calc((100% / 3) - 7.5px);
      &:nth-child(4n + 4) {
        margin: 0 10px 10px 0;
      }
      &:nth-child(3n + 3) {
        margin: 0 0 10px 0;
      }
    }
  }
  &.ay-four-wide-or {
    .ay-blog-tiles-wrap ul li {
      @media screen and (min-width: 1100px) {
        width: calc((100% / 4) - 7.5px);
        &:nth-child(4n + 4) {
          margin: 0 0 10px 0;
        }
        &:nth-child(3n + 3) {
          margin: 0;
        }
      }
    }
  }
}
// for new property search page
.ay-column-3060 {
  .ay-column__triple--col1 {
    ul:not(ay-blog-tile-post-tags) {
      li {
        width: 100%;
        @media screen and (max-width: $tablet-portrait) {
          width: calc((100% / 2) - 10.5px);
        }
        @media screen and (max-width: $phone) {
          width: 100%;
        }
      }
    }
  }
  .ay-column__triple--col2 {
    .ay-blog-tiles-wrap {
      @media screen and (max-width: 1366px) {
        margin-left: 0;
        margin-right: 0;
        max-width: calc(100% - 35px);
      }
    }
    .ay-blog-tiles-wrap ul:not(ay-blog-tile-post-tags) {
      margin: 0 15px;
      @media screen and (max-width: $desktop-large) {
        margin: 0;
      }
      li {
        width: calc((100% / 2) - 10.5px);
        @media screen and (max-width: 1170px) {
          width: 100%;
        }
        @media screen and (max-width: $tablet-portrait) {
          width: calc((100% / 2) - 10.5px);
        }
        @media screen and (max-width: $phone) {
          width: 100%;
        }
      }
    }
    .ay-blog-tiles-wrap ul .ay-blog-tile-post-tags {
      justify-content: flex-start;
      li {
        width: auto;
      }
    }
  }
}
// to address style context bleed
.ay-column.ay-column__triple .ay-custom-twitter-feed--content-block a, .ay-column__single .ay-custom-twitter-feed--content-block a {
  text-decoration: underline solid transparent;
  transition: text-decoration .5s;
  &:hover {
    text-decoration: underline solid $ay-black;
  }
}

// update for class for showing blog card flags template

.ayui #ay .showFlag .ay-blog-tiles-wrap ul li a span,
.ayui #ay .showFlagandCategories .ay-blog-tiles-wrap ul li a span
 {
  display: block !important;
}
// update for class for showing categories on blog cards template
.ayui #ay .showCategory .ay-blog-tiles-wrap ul.ay-blog-tile-post-tags,
.ayui #ay .showFlagandCategories .ay-blog-tiles-wrap ul.ay-blog-tile-post-tags {
  display: flex !important;
}

