/*
Hero

Markup: hero.hbs

Styleguide Hero
*/

.ay-hero {
  display: flex;
  background-color: $ay-primary-colors--light;
  box-shadow: none;
  margin: 6px 0;
  height: 450px;
  overflow: hidden;
  width: 100%;

  @media screen and (max-width: $tablet-portrait) {
    flex-direction: column;
    height: 700px;
  }

  &__content {
    width: 50%;
    padding: 2.5rem 2.8125rem 2.5rem 2.5rem;

    @media screen and (max-width: $tablet-landscape) {
      padding: 1.875rem;
    }

    @media screen and (max-width: $tablet-portrait) {
      width: 100%;
    }
  }

  &__title {
    color: $ay-black;
    font-size: 34px;
    line-height: 120%;

    @media screen and (max-width: $desktop) {
      font-size: 32px;
    }

    @media screen and (max-width: $tablet-landscape) {
      font-size: 28px;
    }

    @media print {
      font-size: 24px;
    }

    &--link {
      color: $ay-black !important;
      display: block;
      font-size: 0.9375rem;
      font-weight: 600;
      margin: 1.25rem 0;
      text-decoration: none;
      text-transform: uppercase;

      &::after {
        background-image: url(images/arrow-right-black.svg);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        content: ' ';
        display: inline-block;
        height: 13px;
        margin-bottom: -1px;
        margin-left: 5px;
        width: 15px;
      }
    }
  }

  &__img {
    width: 50%;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;

    @media screen and (max-width: $tablet-portrait) {
      width: 100%;
      height: auto;
    }

    img {
      display: block;
      max-width: 100%;
      object-fit: cover;

      @media screen and (max-width: $tablet-portrait) {
        width: 100%;
      }
    }
  }

  &__icon {
    display: block;
    fill: transparent;
    height: 45px;
    margin-bottom: 0.9375rem;
    width: 45px;

    @media screen and (max-width: $tablet-landscape) {
      width: 35px;
      height: 35px;
      margin-bottom: 0.625rem;
    }
  }
}

.ay-hero-slider {
  margin: 0 auto;
  max-width: 1300px;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media screen and (max-width: $tablet-portrait) {
    margin-bottom: 20px;
  }

  .owl-carousel .owl-item img {
    max-width: 100%;
    width: 100%;
  }

  .owl-item img {
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d;
  }

  .owl-dots {
    margin-top: -30px;
    height: 22px;
  }

  .owl-nav {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 40px;
    pointer-events: visible;
  }

  .owl-stage-outer {
    margin-bottom: 10px;
  }
}

.ay-column-one-grey .ay-hero__title {
  color: $ay-black;
}
