/*
Statements

Markup: statements.hbs

Styleguide Statements 
*/

.ay-homepage-statement-lts {
  border-top: 3px solid $ay-black;
  margin: auto;
  max-width: $desktop-large;
  @media screen and (max-width: 1300px) {
    width: calc(99% - 62px)
  }
  header {
    margin: auto;
    padding: 50px 0;
    text-align: center;
    width: 75%;
    @media screen and (max-width: 480px) {
      width: 85%;
    }
    .ay-homepage-statement-lts-title{
      font-size: 30px;
      margin-bottom: 30px;
    }
    .ay-homepage-statement-lts-.h4 {
      font-size: 22px;
      line-height: 120%;
    }
  }
  .ay-homepage-statement-lts-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 640px) {
      flex-direction: column;
      align-items: center;
    }
    .ay-homepage-statement-lts-column {
      color: $ay-black;
      font-size: 18px;
      line-height: 120%;
      width: 32%;
      @media screen and (max-width: 900px) {
        margin-bottom: 30px;
        flex-basis: 100%;
        width: 100%;
      }

      .ay-homvepage-statement-lts-column-title{
        background-image: url(images/icons-2020/infographic/camera-iris.svg);
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 60px;
        font-size: 30px;
        margin-bottom: 10px;
        min-height: 60px;
        padding-left: 80px;
      }
      &:first-child {
        .ay-homvepage-statement-lts-column-title{
          background-image: url(images/icons-2020/infographic/chat-bubbles.svg);
        }
      }
      &:last-child {
        .ay-homvepage-statement-lts-column-title{
          background-image: url(images/icons-2020/infographic/spark.svg);
        }
      }
    }
  }
  footer{
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
    a.ay-button__primary.ay-button__primary--black-text {
      margin: 20px auto auto 50%;
      width: auto;
    }
  }
}



@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ay-statement__post {
    padding-bottom: 60px;
  }
}