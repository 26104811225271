/*
Leadership

Markup: leadership.hbs

Styleguide Leadership
*/

.ay-bios-header {
  border-bottom: 1px solid $ay-black;
  font-family: $header-font;
  font-size: 45px;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;

  @media print {
    font-size: 24px;
  }
}

.ay-bios-list {
  display: flex;
  width: 100%;
  max-width: 1000px;
  flex-wrap: wrap;
}

.ay-bio {
  display: flex;
  width: 33%;
  padding: 5px 10px 5px 0;

  @media screen and (max-width: $tablet-portrait) {
    width: 50%;
  }

  @media screen and (max-width: $phone) {
    width: 100%;
  }

  &__img {
    margin-right: 20px;
    max-width: 80px;
    min-width: 80px;
    width: 100%;

    img {
      height: auto;
      display: block;
    }
  }

  &__info {
    color: $ay-black;
    font-size: 1rem;
    position: relative;
    top: -4px;

    &-name {
      font-size: 1.125rem;
      font-weight: $semibold;
    }

    &-title {
    }
  }
}

.ay-bios-wrapper {
  &__primary {
    margin: 0 0 2.5rem 0;
    a {
      text-decoration: underline solid $ay-black;
      transition: all 0.5s;
      &:hover {
        text-decoration: underline solid $ay-primary-color--cloud-grey-dark;
      }
    }
    .ay-bios-header {
      color: $ay-black;
    }
    .ay-bio__info-name {
      color: $ay-black;
    }
  }

  &__secondary {
    margin: 0 0 2.5rem 0;

    .ay-bios-header {
      color: $ay-black;
    }
    .ay-bio__info-name {
      color: $ay-black;
    }
  }

  &__tertiary {
    margin: 0 0 2.5rem 0;

    .ay-bios-header {
      color: $ay-black;
    }
    .ay-bio__info-name {
      color: $ay-black;
    }

    .ay-bios-subtitle {
      font-size: 24px;
      color: $ay-black;
      margin: 0 0 0.9375rem 0;
    }

    &--country {
      border-bottom: 1px solid $ay-black;
      padding-bottom: 30px;
      margin-bottom: 25px;
    }
  }
}

/*
Market Leadership Ties

Markup: market-leadership-tiles.hbs

Styleguide Leadership.Market-Leadership-Tiles
*/

.ay-leadership-section {
  display: flex;
  flex-flow: row wrap;
  max-width: 1300px;
  justify-content: start;
  padding-left: 10px;
  align-items: start;
  width: 100%;
  @media screen and (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ay-leadership-tile {
    width: calc(25% - 20px);
    margin-bottom: 20px;
    margin-right: 20px;
    background-color: white;
    padding: 20px;
    @media screen and (max-width: 1300px) {
      width: calc(33% - 17px);
    }
    @media screen and (max-width: 930px) {
      width: calc(50% - 20px);
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      margin-right: 0px;
    }
    h3 {
      margin-top: unset;
    }
    a {
      color: black;
    }
  }
  .ay-leadership-address {
    margin-top: 12px;
    p {
      margin: 0px;
    }
    a {
      margin: 0px;
      font-size: smaller;
      text-decoration: none;
      text-transform: uppercase;
      &.ay-leadership-tile-office-map:after {
        border-color: transparent transparent transparent #000;
        border-style: solid;
        border-width: 4px 0 4px 6px;
        content: "";
        display: inline-block;
        height: 0;
        margin-bottom: 0;
        margin-left: 5px;
        width: 0;
      }
      &.ay-leadership-tile-office-email {
        text-transform: none;
        font-size: inherit;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .ay-leadership-leader {
    margin-top: 12px;
    p {
      margin: 0px;
    }
  }
  p {
    margin: 0px;
  }
  .ay-leadership-leader-name {
    a {
      margin: 0px;
      text-decoration: underline;
      color: black;
      font-weight: 600;
    }
  }
  .ay-leadership-leader-title {
    font-style: italic;
  }
  .ay-leadership-leader-contact-buttons {
    margin-bottom: 29px;
    margin-top: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: start;
    &--item {
      align-items: center;
      display: flex;
      height: 45px;
      justify-content: center;
      margin-top: 15px;
      padding-bottom: 5px;
      border-bottom: 4px solid transparent;
      margin-right: 10px;
      //position: relative;
      transition: border 0.5s;
      width: 45px;
      &:hover,
      &:focus {
        border-bottom: 4px solid $ay-color-accent-light-amethyst;
      }
      &[data-type]:focus:after {
        bottom: auto;
        color: $ay-black;
        content: attr(data-type);
        //font-size: 12px;
        left: auto;
        padding: 5px;
        position: absolute;
        z-index: 2;
        margin: 97px 0 0 115px;
        width: 170px;
      }

      img {
        height: 25px;
        width: 25px;
      }
    }
  }
}
