.ay-search-proff-table caption {
  text-align: right;
  display: flex;
  span:last-child {
    order: -1;
  }
  span {
    padding-left: 5px
  }
  span:first-child {
    padding-left: 0;
  }
}

.ay-search-proff-sort .ay-search__filter-holder select {
  background-position: 3% center;
}

.ay-search-proff-table > tbody > tr td table tr td:nth-child(2) {
  text-align: right;
}

#ay .ay-search-proff-table td {
  text-align: right;
}

.ay-pagination--pagination-list li:last-child,
.gsc-cursor li:last-child {
  margin-right: 15px;
  margin-left: 0;

}

.ay-pagination--pagination-list li:last-child a svg,
.gsc-cursor li:last-child a svg {
  transform: rotate(180deg);
}

.ay-pagination--pagination-list li:first-child,
.gsc-cursor li:first-child {
  margin-left: 15px;
  margin-right: 0;
}

.ay-pagination--pagination-list li:first-child svg,
.gsc-cursor li:first-child svg {
  transform: rotate(0deg);
}

.ay-proff-card__profile {
  margin-left: 0;
  margin-right: 1.875em;
  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
}

.ay-proff-card__info ul:nth-child(2) li{
  display: flex;
  strong {
    padding-left: 7px;
  }
}

.ay-proff-bio__social {
  left: auto;
  right: -65px;
  @media screen and (max-width: 768px) {
    right: 0;
  }
}

@media screen and (min-width: 768px) {
  .ay-proff-bio__social--item:nth-child(1):hover:before {
    margin-left: 0;
    width: 80px;
    left: 45px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1380px){
  .ay-proff-bio__social--item:nth-child(1):hover:before {
    margin-left: 0;
    width: 54px;
    left: 45px;
    top: -10px;
  }
}

@media screen and (min-width: 768px) {
  .ay-proff-bio__social--item:hover:before {
      margin-left: 0;
      width: 80px;
      left: 46px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1380px){
  .ay-proff-bio__social--item:hover:before {
    width: 50px;
  }
}

@media screen and (min-width: 768px) {
  .ay-proff-bio__social--item:nth-child(3):hover:before {
      margin-left: 0;
      width: 80px;
      left: 17px;
  }
}
