/*
Window Shade

Markup: simple-window-shade.hbs

Styleguide WindowShade
*/

/* reverses link and content if link is needed to be below the content */
.ay-simple-window-shade-reversed {
	display:flex;
	flex-direction: column-reverse;
}

.ay-simple-window-shade {
	color: $ay-black;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	text-decoration:none;
	transition: color .5s;
	width: 100%;
	&:hover {
		color: $ay-black;
		&:before {
			color: $ay-black;
		}
	}

	span {
		display: block;
		width: 100%;
	}
	&[data-open-title]:before,
	&[data-open-title]:focus {
		order: 2;
		content: '\A' attr(data-open-title);
		display: block;
		white-space: normal;
		color: $ay-black;
		width: auto;
		font-size: 18px;
		margin-right:5px;
		transition: color .5s;

	}
	&[data-open-title]:hover:before {
		color: $ay-black;
	}
	&.open[data-open-title]:before {
  	content: attr(data-close-title);
  }
  &[data-open-title]:after {
    content: "";
    transition: all .5s;
    display: inline-block;
    width: 12px;
    height: 12px;
    order: 3;
    background: url(images/arrow-right-black.svg) no-repeat right top;
    align-self: center;
  }
  &.open[data-open-title]:after {
    transform: rotate(90deg);
  }
  &+p,&+div{
  	display: none;
  }
  &+ * p { /* addresses margin on top of p tag funkiness */
    margin: 0 0 16px 0;
  }
}
/* takes top margin if it is on p tag */
p.ay-simple-window-shade {
	margin-top: 0;
}
