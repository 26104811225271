/*
Pardot

Styleguide Pardot
*/

/*
Pardot Button Window Shade

Markup: pardot.hbs 

Styleguide Pardot.Window-Shade
*/

.ay-partdot-shade {
  margin-bottom: 20px;
}

.ay-partdot-shade a.ay-simple-window-shade {
  background: url(images/icons-2020/action/subscribe.svg) no-repeat 30px center;
  background-color: $ay-white;
  background-size: auto 45px;
  background-position-x: 20px;
  background-position-y: 12px;
  border: 3px solid $ay-black;
  color: $ay-black;
  font-size: 95%;
  font-weight: 100;
  padding: 10px 10px 10px 90px;
  text-decoration: none !important;
  transition: all 0.5s;
  width: 100%;
  &.open {
    
    background: url(images/icons-2020/action/subscribe-white.svg) no-repeat 30px center;
    background-color: $ay-black;
    background-size: auto 45px;
    background-position-x: 20px;
    background-position-y: 12px;
    color: $ay-white;
    span {
        color: $ay-white;
      &:after() {
        transform: rotate(90deg);
        border-left: 12px solid $ay-white;
      }
    }
  }
  &:hover {
    
    background: url(images/icons-2020/action/subscribe-white.svg) no-repeat 30px  center;
    background-color: $ay-black;
    background-size: auto 45px;
    background-position-x: 20px;
    background-position-y: 12px;
    color: $ay-white;
    text-decoration: underline;
    span {
        color: $ay-white;
        &:after {
            border-left: 12px solid $ay-white;
        }
    }
  }
  span {
    color: $ay-black;
    font-size: 30px;
    font-weight: normal;
    margin-bottom: 10px;
    text-transform: uppercase;
    &:after {
      content: "";
      border-bottom: 6px solid transparent;
      border-left: 12px solid $ay-black;
      border-top: 6px solid transparent;
      display: inline-block;
      height: 0;
      margin-left: 8px;
      margin-bottom: 3px;
      transition: all 0.5s;
      width: 0;
    }
  }
}
.ay-partdot-shade.ay-pardot-shade-lts {
  text-align: center;
  margin-top: 20px;
}
.ay-partdot-shade.ay-pardot-shade-lts a.ay-simple-window-shade {
  align-items: center;
  background: $ay-white;
  display: inline-flex;
  height: auto;
  justify-content: center;
  min-width: 300px;
  padding:  0 3em;
  text-transform: uppercase;
  transition: all 0.5s;
  width: auto;
  @media screen and (max-width: 550px) {
    height: auto;
    padding: 0;
  }
  @media screen and (max-width: 1372px) {
    margin-left: 36px;
    margin-right: 36px;
    max-width: calc(100% - 72px);
    & + div {
      margin-left: 36px;
      margin-right: 36px;
      max-width: calc(100% - 72px);
    }
  }
  span {
    align-items: center;
    color: $ay-black;
    display: flex;
    font-size: 20px;
    font-weight: 600;
    justify-content: center;
    margin-top: 7px;
    transition: all 0.5s;
    @media screen and (max-width: 550px) {
      line-height: 25px;
    }
    &:before {
      bottom: auto;
      content: "";
      background: url(images/icons-2020/action/subscribe.svg) no-repeat center;
      background-position: center 4px !important;
      display: inline-block;
      height: 45px;
      margin-bottom: 0px;
      margin-right: 10px;
      position: relative;
      top: auto;
      transition: all 0.5s;
      width: 32px;
    }
    &:after {
      content: "";
      border-bottom: 8px solid transparent;
      border-left: 12px solid $ay-black;
      border-top: 8px solid transparent;
      display: inline-block;
      height: 0;
      margin-left: 10px;
      margin-bottom: -2px;
      transition: all 0.5s;
      width: 0;
    }
  }
  &.open { 
    min-width:    100%;
    max-width: 1300px;
    width: 100%;
  }
  &:hover,
  &.open {
    background: $ay-black;
   
    text-decoration: none;
     

    span {
      color: $ay-white;
      &:before {
        background: url(images/icons-2020/action/subscribe-white.svg) no-repeat center;
      }
      &:after {
        border-left: 12px solid $ay-white;
      }
    }
  }
}
// for updated 1-2 30-60 using pardot form keeps button align
 .ay-column.ay-column__triple.ay-column-3060 .ay-partdot-shade a.ay-simple-window-shade,
.ay-column.ay-column__triple.ay-column-3060  .ay-partdot-shade a.ay-simple-window-shade:hover {
    background-size: auto 35px;
    background-position-x: 10px;
    background-position-y: 13px;
    padding: 10px 10px 10px 61px;
    /*width: 85%; */
}

