/*
Blocks

Styleguide Blocks
*/


/*
Blocks Light

Markup: blocks-light.hbs

Styleguide Blocks.Blocks-Light
*/

//removes annoying print links versions
@media print {
    a[href]:after {
        content: none !important;
    }
}

.ay-block__single {
    background: $ay-primary-colors--light;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5625rem;
    padding: 3rem;
    max-width: 680px;
    width: 100%;
    &--full-bleed {
        padding: 0;
    }
}

.ay-block__light {
    background: $ay-white;
    color: $ay-black;
    display: flex;
    flex-direction: column;
    font-family: $base-font;
    font-size: 15px;
    margin-bottom: 1.5625rem;
    margin-left: auto;
    margin-right: auto;
    max-width: $desktop-large;
    width: 100%;

    @media print {
      padding-left: 0;
      box-shadow: none;
    }

    &.ay-main-white {
        background: $ay-white;
    }

    &--content {
        padding: 1.5625rem 40px 0.8125rem 1.875rem;
        line-height: 1.5em;
        .elipsesis {
            &:after {
                content: " ...";
            }
            p,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                display: none;
            }
        }
        a {
            color: $ay-black;
            text-decoration: none;
        }
        @media screen and (max-width: $tablet-landscape) {
            padding: 30px 20px 10px 20px;
            font-size: 14px;
        }
        @media screen and (max-width: $tablet-portrait) {
            padding-bottom: 30px;
            //display: none;
        }

        @media print {
         padding: 0;
        }


        .h2,
        .h3,
        h2,
        h3 {
            margin-bottom: 20px;
        }
        .h4 {
            line-height: 1.4;
            margin-bottom: 10px;
            &~.h5 {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
        ul {
            margin: 1em 0;
            padding: 0;
            li {
                line-height: 1.4;
                &:before {
                    display: none;
                }
            }
        }

        p {
          @media print {
            font-size: 10px;
          }
        }
    }
    &--heading {
        font-size: 16px;
        font-weight: $semibold;
        line-height: 1;
        text-transform: uppercase;
        &--blue {
            color: $ay-black;
            @media screen and (max-width: $phone) {
                margin-bottom: 5px;
            }
        }
        &--green {
            color: $ay-black;
            margin-bottom: 10px;
        }
        &--orange {
            color: $ay-black;
        }
    }
    &--link {
        color: $ay-black !important;
        display: block;
        font-size: 15px;
        font-weight: 600;
        margin: 10px 0;
        text-decoration: none !important;
        text-transform: uppercase;
        &::after {
            background-image: url(images/arrow-right-black.svg);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            content: ' ';
            display: inline-block;
            height: 14px;
            margin-bottom: -1px;
            margin-left: 5px;
            width: 10px;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    &__title {
        color: $ay-white;
        font-family: $header-font;
        font-size: 30px;
        padding: 12px 30px;
        @media screen and (max-width: $tablet-landscape) {
            padding: 15px 20px;
            font-size: 24px;
        }
        @media print {
          font-size: 24px;
        }
        &--default {
          background: $ay-black;
        }
        &--dark-blue {
          background: $ay-black;
        }
        &--blue {
            background: $ay-black;
        }
        &--green {
            background: $ay-black;
        }
        &--orange {
            background: $ay-black;
        }
    }
    &__quote {
        &:last-child {
            padding-bottom: 0;
        }
        &--name {
            display: block;
            font-style: italic;
            margin: 0 0 .75em;
        }
    }
}


/*
Blocks Full Color

--blue      - the default color
--amathyst     - Used with certain conditions
--perwinkle    - Used with certain conditions
--stone      - Used with certain conditions
--red       - Used with certain conditions!

Markup: blocks-full-color.hbs

Styleguide Blocks.Blocks-Full-color
*/

.ay-block__full-color {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    background: $ay-black;
    &--blue {
        background: $ay-black;
    }
    &--amathyst {
        background: $ay-black;
    }
    &--perwinkle {
        background: $ay-black;
    }
    &--stone {
        background: $ay-black;
    }
    &--red {
        background: $ay-black;
    }
    p {
        margin: 0 0 .75em 0;
    }
    @media print {
        color: #000;
        background: transparent;
    }
    &__title {
       // background: $ay-color-accent-dark-blue;
        color: $ay-white;
        font-family: $header-font;
        font-size: 30px;
        padding: 12px 30px 12px 30px;
        word-spacing: 1px;
        @media print {
            color: #000;
            background: transparent;
            font-size: 24px;
        }
        @media screen and (max-width: $tablet-landscape) {
            padding: 15px 20px;
            font-size: 24px;
        }
        @media screen and (max-width: $phone) {
            line-height: 130%;
        }
    }
    &--content {
        background: $ay-secondary-colors--secondary-extra-light;
        color: $ay-black;
        font-family: $base-font;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.5em;
        padding: 1.5625rem 1.875rem 0.8125rem 1.875rem;
        width: 100%;
        a {
            color: $ay-black;
            text-decoration: underline;
        }
        @media screen and (max-width: $tablet-landscape) {
            padding: 19px 25px 19px 25px;
        }
        @media screen and (max-width: $phone) {
            line-height: 1.65em;
        }
    }
}


/*
Blocks CTA

Blocks for Call-to-Action elements

Styleguide Blocks.Blocks-Call-to-Action
*/


/*
Blocks CTA - Download

Markup: blocks-cta-download.hbs

Styleguide Blocks.Blocks-Call-to-Action.Blocks-Download
*/


/*
Blocks CTA - Video

Markup: blocks-cta-video.hbs

Styleguide Blocks.Blocks-Call-to-Action.Blocks-Video
*/

.ay-block__cta {
    background-position: 30px 30px;
    background-repeat: no-repeat;
    color: $ay-primary-colors--light;
    display: inline-flex;
    font-family: $base-font;
    font-size: 17px;
    min-height: 150px;
    text-decoration: none;
    width: 100%;
    a {
        color: $ay-primary-colors--light !important;
        text-decoration: none;
    }
    @media screen and (max-width: $tablet-landscape) {
        flex-direction: column;
        margin-left: 0px;
    }
    @media screen and (max-width: $tablet-portrait) {
        background-position: 25px 30px;
    }
    &--video {
        background-color: $ay-black;
        background-image: url("images/icons-2020/visual/video-white.svg");
        background-size: 40px;
        background-position: 29px 24px;
        @media screen and (max-width: $tablet-landscape) {
            padding: 0px 30px 30px 0px;
        }
        @media screen and (max-width: $phone) {
            padding-right: 20px;
        }
    }
    &__content {
        padding: 80px 39px 25px 30px;
        @media screen and (max-width: $tablet-portrait) {
            padding-left: 25px;
            padding-bottom: 0;
            margin-top: 8px;
        }
        &--download {
            background-color: $ay-black;
            background-image: url(images/icons-2020/visual/document-white.svg);
            background-size: 36px auto;
            background-position: 30px 30px;
            background-repeat: no-repeat;
            line-height: 1.5;
            margin-bottom: 27px;
            margin-left: 20px;
            padding: 85px 30px 27px 30px;
            width: calc(100% - 30px);
            @media screen and (max-width: $tablet-landscape) {
                margin-left: 0px;
            }
            @media screen and (max-width: $tablet-portrait) {
                background-position: 25px 30px;
                padding-left: 25px;
                width: 100%;
            }
            @media screen and (max-width: $phone) {
                padding-right: 20px;
            }
            &:first-child {
                margin-left: 0px;
            }
        }
    }
    &--link {
        color: #f8f8f8;
        font-size: 15px;
        display: block;
        margin-top: 10px;
        text-decoration: none;
        text-transform: uppercase;
        @media screen and (max-width: $phone) {
            font-size: 14px;
        }
        &::after {
            background-image: url(images/arrow-right.svg);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            content: ' ';
            display: inline-block;
            height: 15px;
            margin-bottom: -1px;
            margin-left: 5px;
            width: 18px;
        }
    }
}


/*
Blocks Key Facts

Markup: blocks-key-facts.hbs

Styleguide Blocks.Blocks-Key-facts
*/

.ay-block__key-facts {
    @extend .ay-block__light;
    background: $ay-black;
    color: $ay-white;
    padding-bottom: 15px;
    &--stats {
        border-bottom: 2px solid $ay-white;
        display: flex;
        flex-flow: column;
        padding-bottom: 30px;
        @media screen and (max-width: $tablet-landscape) {
            line-height: 1;
        }
        @media screen and (max-width: $phone) {
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
        &--fact-header {
            color: $ay-white;
            font-family: $header-font;
            font-size: 38px;
            line-height: 59px;
            @media screen and (max-width: $tablet-portrait) {
                font-size: 22px;
                line-height: 40px;
            }
            @media screen and (max-width: $phone) {
                font-size: 24px;
                line-height: 120%;
            }
            @media screen and (max-width: 320px) {
                font-size: 18px;
            }
        }
        &--fact-label {
          font-family: $base-font;
            color: $ay-white;
            margin-top: -10px;
            line-height: 1.3;
            @media screen and (max-width: $tablet-landscape) {
                padding-top: 5px;
            }
            @media screen and (max-width: $phone) {
                font-size: 65%;
                padding-top: 10px;
            }
        }
        &--row {
            display: flex;
            margin-top: 5px;
            width: 100%;
            @media screen and (max-width: $tablet-portrait) {
                margin-bottom: 20px;
            }
            &--item {
                color: $ay-secondary-colors--medium;
                display: flex;
                flex-flow: column;
                font-family: $header-font;
                font-size: 22px;
                padding-right: 35px;
                @media screen and (max-width: $phone) {
                    padding-right: 5%;
                }
            }
        }
    }
    &__list {
        font-weight: bold;
        list-style: none;
        padding: 0;
        margin-top: -2px;
        margin-left: 0;
        &--item {
            line-height: 150%;
            &--link {
                color: $ay-white;
                text-decoration: none;
                font-weight: $semibold;
                line-height: 1.6;
                &:hover {
                    color: $ay-primary-colors--medium-light;
                }
            }
        }
    }
    &--heading {
        font-family: $base-font;
        font-size: 20px;
        font-weight: $semibold;
        padding-top: 20px;
        text-transform: uppercase;
        padding-bottom: 10px;
        @media screen and (max-width: $tablet-landscape) {
            line-height: 1.2;
            padding-bottom: 10px;
        }
        &--green {
            color: $ay-white;
        }
    }
    &--link {
        color: $ay-white !important;
        font-size: 15px;
        font-weight: 600;
        margin-top: 10px;
        text-decoration: none;
        text-transform: uppercase;
        &::after {
            background-image: url(images/arrow-right-white.svg);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            content: ' ';
            display: inline-block;
            height: 15px;
            margin-bottom: -2px;
            margin-left: 5px;
            width: 10px;
        }
        &:hover {
            text-decoration: underline;
        }
        @media screen and (max-width: $phone) {
            line-height: 1;
            font-size: 14px;
        }
    }
    &__title {
        background-color: $ay-black;
        color: $ay-white;
        font-family: $header-font;
        font-size: 30px;
        padding: 12px 30px;
        @media screen and (max-width: $tablet-landscape) {
            padding: 10px 20px;
        }
        @media screen and (max-width: $tablet-landscape) {
            padding: 15px 20px;
            font-size: 24px;
        }
        @media print {
          font-size: 24px;
        }

        &--green {
            background: $ay-secondary-colors--medium;
            opacity: 0.8;
        }
    }
    &--content {
        padding: 0.75rem 1.875rem 1.875rem 1.875rem;
        @media screen and (max-width: $tablet-landscape) {
            padding: 10px 20px 10px 20px;
        }
    }
}


/*
Blocks Quote

Markup: blocks-quote.hbs

Styleguide Blocks.Blocks-Quote
*/

.ay-block__quote {
    @extend .ay-block__cta;
    background-color: $ay-black;
    flex-direction: column;
    margin: 0px 0px 30px 0px;
    &--name {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: bold;
        margin: 30px 30px 20px 30px;
        text-transform: uppercase;
        &::before {
            background-image: url(images/icons-2020/visual/quote.svg);
            background-repeat: no-repeat;
            content: ' ';
            display: inline-block;
            height: 43px;
            padding: 0px 33px 0px 25px;
            width: 60px;
        }
        @media screen and (max-width: $tablet-portrait) {
            margin-left: 25px;
        }
    }
    &__content {
        font-size: 28px;
        font-style: italic;
        font-weight: 300;
        line-height: 40px;
        padding: 0px 30px 48px 30px;
        @media screen and (max-width: $tablet-landscape) {
            font-size: 20px;
        }
        @media screen and (max-width: $tablet-portrait) {
            padding-left: 25px;
        }

        @media print {
          font-size: 18px;
        }
    }
}


/*
Blocks Image

Markup: blocks-image.hbs

Styleguide Blocks.Blocks-Image
*/

.ay-block__image {
    background-color: $ay-primary-color--cloud-grey-dark;
    &__content {
        background: url("images/block-image.png");
        background-size: cover;
        height: 719px;
        @media print {
            display: none;
        }
        @media screen and (max-width: $phone) {
            height: 268px;
            background-position: center center;
        }
    }
    &__content-two {
        background: url("images/block-image-2.png");
        background-size: cover;
        height: 637px;
        @media screen and (max-width: $phone) {
            height: 268px;
        }
    }
}

.ay-block__image--only {
    display: flex;
    flex-direction: column;
    margin: 0 0 2em 0;
    overflow: hidden;
    img {
        display: block;
        width: auto;
        max-width: 100%;
          @media print {
            max-width: 50%;
            width: 100%;
          }
    }
}


/*
Blocks Infograph

Markup: blocks-infograph.hbs

Styleguide Blocks.Infograph
*/

.ay-block-infograph {
    background-color: $ay-primary-colors--light;
    color: $ay-black;
    display: flex;
    flex-flow: column;
    font-size: 15px;
    line-height: 150%;
    padding: 0.5em 0 2.5em 0;
    &__heading {
        color: $ay-black;
        font-family: $base-font;
        font-size: 16px;
        @media screen and (max-width: $tablet-portrait) {
            line-height: 1.4;
            margin-bottom: 10px;
        }
    }
    &__row {
        display: flex;
        margin: 30px 20px 0 20px;
        width: auto;
        @media screen and (max-width: $tablet-portrait) {
            flex-direction: column;
        }
    }
    &__item {
        width: 80%;
        @media screen and (max-width: $tablet-portrait) {
            width: 100%;
            text-align: center;
        }
        &:last-child {
            padding: 0px 20px 0px 50px;
            @media screen and (max-width: $tablet-portrait) {
                padding: 0px 20px;
            }
        }
        &:first-child {
            width: 15%;
            padding-left: 20px;
            @media screen and (max-width: $tablet-portrait) {
                align-items: center;
                display: flex;
                flex-direction: column;
                padding-left: 0;
                text-align: center;
                width: 100%;
            }
        }
    }
    &__icon {
        display: block;
        height: 100px;
        width: 100%;
        margin-top: 5px;
        fill: transparent;
        @media screen and (max-width: $tablet-portrait) {
            display: inline-block;
            height: 80px;
            margin: 0 0 20px 0;
        }
    }
}


/*
Blocks Media

Markup: blocks-media.hbs

Styleguide Blocks.Media
*/

/*
Blocks Media Right to Left

Markup: blocks-media-rtl.hbs

Styleguide Blocks.Media.Right to left
*/


/*
Blocks Media Contact

Markup: blocks-media-contact.hbs

Styleguide Blocks.Media.Blocks-Media-Contact
*/

.ay-media-post {
    border-bottom: 2px solid $ay-black;
    padding: 0.9375rem 0;
    &:first-child {
        padding-top: 0;
    }
    &-icon {
        display: block;
        height: 45px;
        width: 45px;
        fill: transparent;
    }
    &__main {
        display: flex;
        &--content {
            flex-grow: 2;
            position: relative;
                h5 {
                    line-height: 1.3;
                    text-transform: none;
                }
            a {
                color: $ay-black;
                text-decoration: none;
                &:hover {
                    color: $ay-black;
                    text-decoration: underline;
                    .ay-media-post__main--subtitle {
                        color: $ay-black;
                        text-decoration: none;
                    }
                }
                .ay-media-post__main--subtitle {
                    display: block;
                    padding: 5px 0;
                    color: $ay-black;
                    font-size: $base-font-size;
                }
            }
        }
        &--media {
            display: inline-block;
            max-width: 50px;
            width: 100%;
            margin-right: 10px;
            &.largerThumb {
                max-width: 129px;
                img {
                    width: 129px;
                }
            }
            img {
                width: 50px;
                max-width: 100%;
            }
        }
    }
    &__title {
        color: $ay-black;
        display: block;
        font-family: $base-font;
        font-size: 13px;
        font-weight: $semibold;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
    }
}

.ay-block-media-video {
    display: block;
    fill: transparent;
    height: 50px;
    margin-top: 22px;
    width: 50px;
}

// Media Card
.ay-block-media-contact {
    display: flex;
    flex-direction: column;
    &__cover {
        background-color: $ay-black;
        color: $ay-white;
        padding: 2.5rem 2.5rem 0.625rem 2.5rem;
        a {
            color: $ay-white !important;
            text-decoration: underline;
            font-size: 14px;
            margin-bottom: 0.625rem;
        }
        @media screen and (max-width: $phone) {
            font-size: 1.125rem;
            padding: 1.25rem;
        }
    }
    &__profile {
        line-height: 150%;
        padding-bottom: 1.25rem;
    }
    &__h2 {
        background: url(images/icons-2020/communication/communication-phone-white.svg) no-repeat;
        background-size: 38px;
        color: $ay-white;
        font-weight: 400;
        font-size: 1.4375rem;
        line-height: 1;
        margin-bottom: 0.625rem;
        min-height: 40px;
        padding: 0.3125rem 0;
        padding-left: 50px;
        @media screen and (max-width: $phone) {
            padding-left: 2rem;
        }
    }
    &__h3 {
        color: $ay-white;
        font-family: $base-font;
        font-size: 1.4375rem;
        margin-bottom: 0.3125rem;
        a {
          color: $ay-white !important;
          font-weight: 100;
          font-size: 1rem;
        }
    }
    &__footer {
        background-color: #E9E9E9;
        color: $ay-general;
        display: flex;
        align-items: center;
        font-size: 1.4375rem;
        padding: 5px 15px 5px 38px;
        &--icon {
            margin-right: 20px;
            display: block;
            fill: transparent;
            height: 45px;
            width: 45px;
            @media screen and (max-width: $phone) {
                width: 40px;
                height: 40px;
            }
        }
        &-links {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;
            &__item {
                display: flex;
                margin-right: 2.3%;
                align-items: center;
                line-height: 1.2;
                &:last-child {
                    margin-right: 0;
                }
                @media screen and (max-width: $phone) {
                    margin-right: 0.25rem;
                    width: 100%;
                    max-width: 100%;
                }
                a {
                    color: $ay-black !important;
                    position: relative;
                    text-decoration: none;
                    background: url(images/arrow-right-darker.svg) no-repeat;
                    background-size: 13px;
                    background-position: center left;
                    padding-left: 18px;
                    font-size: 18px;
                    @media screen and (max-width: $tablet-portrait) {
                        background-size: 12px;
                        font-size: 16px;
                    }
                    @media screen and (max-width: $phone) {
                        padding-right: 1.25rem;
                    }
                }
            }
        }
        @media screen and (max-width: $phone) {
            font-size: 0.8125rem;
            padding-left: 1.25rem;
        }
    }
    @media screen and (max-width: $tablet-portrait) {
        font-size: 1.0625rem;
    }
    @media screen and (max-width: $phone) {
        flex-direction: column;
        justify-content: flex-start;
    }
}

.ie10 .ay-block__cta__content--download {
    @media screen and (max-width: 1500px) and (min-width: $tablet-landscape) {
        width: 48%;
    }
}


/*
Blocks Blogger

Markup: blocks-blogger.hbs

Styleguide Blocks.Blogger
*/

.ay-block__blogger {
    background-color: $ay-black;
    flex-direction: column;
    margin: 30px 0 30px 0;
    &-title {
        align-items: center;
        color: $ay-white;
        display: flex;
        font-family: $header-font;
        font-size: 25px;
        font-weight: $semibold;
        line-height: 1;
        text-decoration: none;
        padding: 1.25rem 1.875rem 1rem 1rem;
        @media screen and (max-width: $tablet-portrait) {
            padding: 1em;
            line-height: 1.3;
        }
        @media screen and (max-width: $phone) {
            font-size: 1.375rem;
        }

        @media print {
          font-size: 18px;
        }

        &:before {
            background-image: url(images/blogger.svg);
            background-repeat: no-repeat;
            content: "";
            position: relative;
            display: inline-block;
            height: 43px;
            width: 57px;
            @media screen and (max-width: $tablet-portrait) {
                margin-right: 15px;
            }
            @media screen and (max-width: $phone) {
                margin-right: 10px;
            }
        }
        &:hover {
            text-decoration: none;
        }
    }
    &-more {
        color: $ay-white !important;
        font-size: 14px;
        font-weight: $semibold;
        text-decoration: none;
        text-transform: uppercase;
        &::after {
            background-image: url(images/arrow-right-white.svg);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            content: ' ';
            display: inline-block;
            height: 14px;
            margin-bottom: -1px;
            margin-left: 5px;
            width: 18px;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    &-content {
        padding: 0 3rem 3rem 1.875rem;
        @media screen and (max-width: $tablet-portrait) {
            display: none;
        }
        &__item {
            color: $ay-white !important;
            display: flex;
            flex-direction: column;
            margin-bottom: 0.625rem;
            text-decoration: none;
            font-size: 19px;
            span {
                &:hover {
                    text-decoration: underline;
                }
            }
            @media screen and (max-width: $phone) {
                font-size: 18px;
            }
            small {
                font-weight: bold;
                font-size: 12px;
            }

            @media print {
              font-size: 16px;
            }
        }
        @media screen and (max-width: $phone) {
            padding-left: 1.875rem;
        }
    }
}

/*
Blocks Media Post Multi

Markup: blocks-media-post-multi.hbs

Styleguide Blocks.Blocks-Media-Post-Multi
*/

.ay-media-post-multi{
    padding: 1.5625em 1.875em .8125em 1.875em;
    line-height: 1.5em;
    background:$ay-white;
    border-top:10px solid $ay-color-accent-light-orange;
    margin-bottom: 1.5625em;
    ul{
        list-style:none;
        margin:0;
        padding:0;
        li{
            margin-bottom:.85em;
            padding-top: 13px;
            h4{
                color: $ay-black;
                display: block;
                font-size: 13px;
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: .55em;
            }
            a{
                text-decoration: none !important;
                color:$ay-black;
                display:flex;
                @media screen and (max-width: 450px){
                    flex-direction:column;
                }
                aside{
                    width:160px;
                    min-width: 100px;
                    margin-right:20px;
                    overflow: hidden;
                    @media screen and (max-width: 450px){
                        width:100%;
                        margin-right:0;
                        margin-bottom:20px;
                    }
                    img{
                        width:160px;
                        height:160px;
                        object-fit:cover;
                        transition: all .5s;
                    }
                }
                &:hover {
                  img{
                    transform: scale(1.1);
                  }
                }
            }
        }
    }
    &__blurb{
        width:calc( 100% - 180px);
        @media screen and (max-width: 450px){
            width:100%;
        }
        h3{
            font-family: $base-font;
            margin-top: -6px;
            text-decoration: underline;
        }
        &--subtitle{
            display: block;
            padding: 0 0 5px 0;
            color: $ay-black;
            font-size: 14px;
        }
        &--date{
            color: $ay-black;
            display: block;
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: .95em;
        }
    }
}

/*
Blocks Video Post

Markup: blocks-video-post.hbs

Styleguide Blocks.blocks-video-post
*/

.ay-media-post.ay-no-border{
  border-bottom:none;

}

.ay-media-post__main.ay-video-post{
  flex-direction:column;
  border-bottom:none;
  .ay-video-post__header{
    display:flex;
    border-bottom: 2px solid $ay-color-accent-light-red;
    padding-bottom: .9375em;
    margin-bottom: .9375em;
  }
}

.ay-video-wrapper, .ay-video-firefox-extra-wrap{
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
  iframe, object, embed  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  a{
    position:absolute;
    z-index:100;
    height:100%;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    &:after{
       content:"";
       display:block;
       width:80px;
       height:56px;
       background:url('images/youtube-play-button.svg') no-repeat center top;
       background-size:80px auto;
    }
    &:hover:after{
      background-position:center bottom;
    }
  }
}

/* start of fix for firefox bug */
@-moz-document url-prefix() {
  .ay-video-firefox-extra-wrap{
    width: 578.4px;
    max-width: 100%;
    position: static;
    padding-bottom: initial;
    padding-top: initial;
    height:  auto;
    overflow: auto;
  }
}
/* end of fix for firefox bug */


/*
Blocks LTS Media

Markup: blocks-lts-media.hbs

Styleguide Blocks.Media.LTS-Media
*/

.ay-lts-media-block {
  background: $ay-white;
  display: flex;
  height: auto;
  justify-content: space-between;
  margin: auto;
  max-width: $desktop-large;
  width: 100%;
  figure img {
    transition: all .5s;
  }
  &:hover figure img {
    transform: scale(1.1);
  }
  @media screen and (max-width: 1372px) {
    margin-left: 36px;
    margin-right: 36px;
    max-width: calc(100% - 72px);
  }

  &+.ay-lts-media-block {
      margin-top:20px;
  }
  &-content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2%;
    width: 50%;
    @media screen and (max-width: 550px) {
        padding-left: 26px;
    }
    &>h3,
    &>p,
    &>.ay-lts-media-block-read-more,
    &>.ay-simple-window-shade {
      width:100%;
    }
    h2,
    h3 {
      font-family: $base-font;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      padding-top: 25px;
      width: 92%;
      &+p {
          margin-top: 16px;
      }
    }
    &>p {
      color: $ay-black;
      font-size: 18px;
      line-height: 1.4em;
      width: 92%;
    }
    &-date {
      color: $ay-black;
      font-size: 12px;
      margin:20px 0 15px 0;
      text-transform: uppercase;
    }
    .ay-simple-window-shade + * {
        width: 92%;
    }
  }
  &-media {
    width: 50%;
    img {
      display: block;
      width: 100%;
      height:auto;
      object-fit: cover;
    }
    .ay-video-wrapper {
      padding-bottom: 56.03%;
    }
  }
  figure {
    display: flex;
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;

    a {
        margin-bottom: 0;
    }
    a:after {
        display: none;
    }

  }
  @media screen and (max-width: $tablet-portrait) {
    flex-direction: column-reverse;
    &-content,
    &-media {
      width: 100%;
    }
    &-media {
      padding-bottom: 20px;
    }
  }
  &-read-more,
  .ay-simple-window-shade,
  a {
    color: $ay-black;
    font-size: 1em;
    margin-bottom: 15px;
    //margin-top: auto;
    text-decoration: none;
    text-transform: uppercase;
    transition: .5s;
    &:hover{
        text-decoration: underline;
    }
    &:after {
      border-color: transparent transparent transparent $ay-black;
      border-style: solid;
      border-width: 6px 0 6px 8px;
      content: "";
      display: inline-block;
      height: 0;
      margin-bottom: 0;
      margin-left: 5px;
      width: 0;
    }
  }
  .ay-hp-cta {
    border-bottom: 0px solid transparent;
    color: $ay-black;
    font-size: 18px;
    font-weight: normal;
    padding-bottom: 3px;
    text-decoration: none;
    text-transform: uppercase;
    transition: .5s;
    &:hover {
      border-bottom: 3px solid $ay-black;
      padding-bottom: 0;
      text-decoration: none;
      transition: .5s;
    }
    &:after {
        margin-bottom: 0;
    }
  }
  &.ay-lts-text-right{
    flex-direction: row-reverse;

      .ay-lts-media-block-content {

        //font-size: 1.4em;
        //line-height: 120%;
        width: 50%;

        &-bb-red {
          border-bottom: 5px solid $ay-color-accent-light-red;
          @media screen and (max-width: $tablet-portrait) {
            flex-direction: column;
            &.ay-lts-media-block-content {
              width: 100%;
            }
          }
        }
        h3 {
          width: 95%;
        }
        p {
          width: 95%;
        }
      }
      @media screen and (max-width: $tablet-portrait) {
        flex-direction: column-reverse;
        .ay-lts-media-block-content {
          width: 95%;
        }
      }
    }
    &-dark {
      background: $ay-secondary-colors--medium-medium;
      .ay-lts-media-block-content {
        background: $ay-secondary-colors--medium-medium !important;
      }
    }
}
/*
Blocks LTS White Papers

Markup: blocks-white-papers.hbs

Styleguide Blocks.Media.LTS-White-Paper
*/

.ay-lts-list-thumbs-container,
.ay-lts-list-column-wrap-container {
  background: $ay-secondary-colors--medium-medium;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: $desktop-large;
  width: 100%;
  @media screen and (max-width: 1372px) {
    margin-left: 42px;
    margin-right: 30px;
    max-width: calc(100% - 72px);
  }
  header {
    align-items: center;
    background: $ay-black;
    color: $ay-white;
    display: flex;
    font-family: $header-font;
    font-size: 32px;
    justify-content: center;
    min-height: 50px;
    height: auto;
    line-height: 34px;
    padding: 8px 20px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 40px 40px 20px;
    @media screen and (max-width: 1150px) {
      flex-wrap: wrap;
    }
    li{

      font-size: 26px;
      justify-content: flex-end;
      margin: 0;
      max-width: 245px;

      padding: 0;
      text-transform: uppercase;
      width: calc(100% / 4);
      @media screen and (max-width: 1150px) {
        margin-bottom: 35px;
        max-width: none;
        width: calc(100% / 2);
      }
      @media screen and (max-width: 750px) {
        max-width: none;
        width: 100%;
      }
      a {
        display: flex;
        flex-direction: column;
        color: $ay-black;
        min-height: 100%;
        text-decoration: none;
        @media screen and (max-width: 1150px) {
          max-width: 90%;
        }
        @media screen and (max-width: 750px) {
          max-width: none;
        }
        @media screen and (max-width: 412px) {
          max-width: none;
        }
      }
      h4 {
        align-items: flex-end;
        color: $ay-black;

        display: flex;
        font-size: 26px;
        line-height: normal;
        margin-bottom: 25px;
        text-transform: initial;
        transition: all .5s;
        @media screen and (max-width: 1372px) {
          font-size: 1.55vw;
          line-height: normal;
        }
        @media screen and (max-width: 1150px) {
          font-size: 26px;
         line-height: normal;
        }
        @media screen and (max-width: 412px) {
          font-size: 7vw;
          line-height: normal;
        }
      }
      figure {
        margin: 0;
        padding: 0;
        @media screen and (max-width: 750px) {
          display: flex;
        }
        @media screen and (max-width: 500px) {
          display: block;
        }
        figcaption {
          font-size: 19px;
          line-height: 26px;
          margin-top: 25px;
          text-transform: none;
          @media screen and (max-width: 750px) {
            margin-left: 30px;
            margin-top: 0;
          }
          @media screen and (max-width: 500px) {
            margin-left: 0;
            margin-top: 25px;
          }
        }
      }
    }
  }
  .ay-lts-media-block-read-more {
    display: inline-block;
    margin-left: auto;
    padding-bottom: 20px;
    padding-right: 40px;
  }

}
/*
Blocks LTS Local Reports

Markup: blocks-local-reports.hbs

Styleguide Blocks.Media.LTS-Local-Reports
*/
.ay-lts-list-column-wrap-container {
  ul.ay-lts-list{
    flex-direction: row;
    flex-wrap: wrap;
    padding: 25px 25px 15px;
    @media screen and (max-width: 750px) {
      flex-direction: column;
    }
    li {
      border-bottom: 1px solid $ay-general;
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 0;
      max-width: none;
      padding: 15px 0;
      text-transform: none;
      width: calc(50% - 25px);
      @media screen and (max-width: 750px) {
        width: 100%;
        display: none;
        &:nth-child(-n+5){
            display: block;
        }
      }
      a {
        max-width: none;
      }
    }
  }
  .ay-lts-media-block-read-more {
    margin-left: auto;
    padding-bottom: 0;
    padding-right: 25px;
    @media screen and (max-width: 750px) {
      margin-left: auto;
    }
  }
}

#main-content .ay-main.ay-main-grey,
#main-content .ay-main.ay-main-white {
    padding-bottom: 40px;
}

/*
Blocks Property Launchpad Search Header

Markup: blocks-property-launchpad-search-header.hbs

Styleguide Blocks.Property.Property Launchpad Search
*/
//for styleguide use only
.kss-modifier__example .portlet-layout {
    width: 100%;
}

    .ay-property-launchpad-search-header {
        align-items: center;
        background-image: url(images/Website_Prop-Search-Banner-min.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        min-height: 330px;
        max-width: $desktop-large;
        overflow: visible;
        padding: 15px 0;
        position: relative;
        width: 100%;
        &::after {
            background: rgba(17, 11, 55,.25);
            content: "";
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        &.fixed-bg {
            background-attachment: fixed;
            background-position: top center;
        }
        h1,
        .h1,
        h4,
        .h4 {
            color: $ay-white;
            position: relative;
            text-align: center;
            text-shadow: 0px 1px 2px #000;
            z-index: 5;
        }
        @media screen and (max-width: $tablet-portrait){
            h1,
            .h1, {
                font-size: 2.5em;
            }
        }
        h4,
        .h4 {
            font-size: $font-size-h2;
            line-height: 120%;
            padding: 20px 0;
        }
        form{
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 5;
            @media screen and (max-width: 1183px) and (min-width: 980px) {
                max-width:  calc(100% - 62px);
                margin-left: auto;
                margin-right: auto;
        }

            @media screen and (max-width: 980px) {
                margin: 0 15px;
            }
            fieldset {
                border: none;
                display: flex;
                margin: 0 auto;
                padding: 0;
                width: 100%;
                select{
                    background: #fff url(images/arrow-select-black-left.svg) no-repeat 94% center;
                    background-position: 94% center;
                    background-size: 15px;
                    border-right: 4px solid $ay-primary-color--cloud-grey-dark;
                    font-size: 16px;
                    line-height: 19px;
                    padding: 5px 30px 5px 15px;
                    width: auto;
                    @media screen and (max-width: 665px) {
                    border-bottom: 4px solid $ay-primary-color--cloud-grey-dark;
                    &:nth-of-type(2){
                        border-right: none !important;
                    }
                    }
                    &:focus {
                        background: #fff url(images/arrow-select-black.svg) no-repeat 94% center;
                        background-size: 15px;
                    }
                }
                input[type="text"] {
                    border: none;
                    font-size: 16px;
                    padding-left: 20px;
                    padding-right: 20px;
                    width: 100%;
                    &::placeholder {
                        font-size: 16px;
                    }
                }
                @media screen and (max-width: 665px){
                    flex-wrap: wrap;
                    select {
                        width: 50%;
                    }
                    input[type="text"] {
                        min-height: 30px;
                        line-height: 35px;
                        width: 100%;
                    }
                }
                input[type="submit"] {
                    background: $ay-white url(images/icons-2020/action/search.svg) no-repeat 25% center;
                    background-size: 75%;
                    border: none;
                    color: transparent;
                    font-size: 0;
                    width: 30px;
                }
            }
            a {
                align-self: flex-end;
                background-color:#0003;
                border-radius:3px;
                color: $ay-white !important;
                display: block;
                font-size: 14px;
                margin-top: 15px;
                padding:3px 6px;
                text-decoration:none;
                text-transform:uppercase;
                &:after {
                    border-color: transparent transparent transparent #FFF;
                    border-style: solid;
                    border-width: 6px 0 6px 8px;
                    content: "";
                    display: inline-block;
                    height: 0;
                    margin-bottom: 0;
                    margin-left: 5px;
                    width: 0;
                }

                &:hover {
                color: $ay-white;
                text-decoration:underline;
                }
            }
        }
    }

/*
Blocks Custom Twitter Feed

Markup: blocks-custom-twitter.hbs

ay-custom-twitter--link-always       - list a tags underline always underlined
ay-custom-twitter--list-hover       - list a tags underline on list item hover

Styleguide Blocks.Custom-Twitter-Feed
*/

.ay-custom-twitter-feed {
    &--wrapper {
        .h2,
        .h3 {
            background: url(images/twitter1.svg) no-repeat left center;
            background-size: contain;
            display: inline-block;
            margin-bottom: 30px;
            padding: 0 0 0 40px;
        }
        .h2 {
            padding: 0 0 0 45px;
        }
        &.ay-custom-twitter--list-hover {
            li  {
               a{
                   text-decoration: underline;
                   text-decoration-color: $ay-white;
                   transition: text-decoration .5s;
                   &.ay-twitter-inner-flag-link {
                       text-decoration: none;
                   }
                }
                &:hover {
                    a{
                        text-decoration: underline;
                        text-decoration-color: $ay-black;
                        &.ay-twitter-inner-flag-link {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        &.ay-custom-twitter--link-always {
            li  {
               a{
                   text-decoration: underline;
                   text-decoration-color: $ay-black;
                   transition: text-decoration .5s;
                   &.ay-twitter-inner-flag-link {
                       text-decoration: none;
                   }
                }
                &:hover {
                    a{
                        text-decoration: underline;
                        text-decoration-color: $ay-black;
                        &.ay-twitter-inner-flag-link {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    &-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        justify-content: flex-start;
        margin: 0;
        padding: 0;
        li{
            background: $ay-white;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin: 0 15px 15px 0;
            padding: 0;
            position: relative;
            width: calc(((100% / 4) - 15px) + 3.75px);
            @media screen and (min-width: 1191px) {
                &:nth-child(4n + 4) {
                    margin-right: 0
                }
            }

            @media screen and (max-width: 1190px) and (min-width: 881px) {
                width: calc(((100% / 3) - 15px) + 5px);
                &:nth-child(3n + 3) {
                    margin-right: 0
                }
            }
            @media screen and (max-width: 880px) and (min-width: 691px) {
                width: calc(((100% / 2) - 15px) + 7.5px);
                &:nth-child(2n + 2) {
                    margin-right: 0
                }
            }
            @media screen and (max-width: 690px) {
                width: 100%;
                margin-right: 0;
            }
            &:after {
                background: $ay-white;
                bottom: 0;
                content:'';
                height: 3px;
                position: absolute;
                transition: all .5s;
                width: 0;
            }
            &:hover:after{
                background: $ay-color-accent-dark-orange;
                width: 100%;
            }
            a {
                color: $ay-black;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
                &.ay-twitter-inner-flag-link {
                    color: $ay-white !important; //to ensure white color of link in flag
                    background: $ay-black;

                    display: inline-block;
                    font-size: 11px;
                    margin: 15px 0 0 0;
                    padding: 5px 20px;
                    text-decoration: none;
                    transform: translateX(-5px);
                    width: auto;
                    &.ay-retweet {
                        background: $ay-black url(images/icons-2020/social/social-twitter-retweet-reverse.svg) no-repeat 20px center;
                        background-size: 15px;
                        padding-left: 45px;
                    }
                }
                &[href^='https://t.co'],
                &[href^='https://buff.ly/'] {
                    overflow-wrap: break-word;
                    word-break: break-all;
                }
            }
            .ay-custom-twitter-feed--content-block {
                font-size: 15px;
                padding: 20px 30px 0;
            }
            .ay-custom-twitter-feed--date {
                font-size: 15px;
                margin-top: auto;
                padding: 20px 30px ;
                time {
                    background: url(images/icons-2020/visual/clock.svg) no-repeat left center;
                    background-size: 15px;
                    padding-left: 20px;
                    abbr {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    &--footer {
        display: block;
        padding: 20px 0;
    }
}

/*
Blocks Custom Twitter Feed 30% and 60% column

Markup: blocks-custom-twitter-60.hbs

Styleguide Blocks.Custom-Twitter-Feed-60
*/

.ay-column__triple.ay-column-3060,
.ay-column.ay-column__triple {
        #column-2 .ay-custom-twitter-feed,
        .ay-column__triple--col2 .ay-custom-twitter-feed,
        .ay-column__triple--col3 .ay-custom-twitter-feed{
            &-list {
                li{
                    width: 100%;
                    margin-left: 15px;
                    margin-right: 15px;
                }
            }
        }
        #column-3 .ay-custom-twitter-feed,
        .ay-column__triple--col1 .ay-custom-twitter-feed{
            &-list {
                li{
                    width: calc(((100% / 3) - 15px) + 5px);
                    @media screen and (min-width: 1301px)  {
                        &:nth-child(4n + 4) {
                            margin-right: 15px;
                        }
                        &:nth-child(3n + 3) {
                            margin-right: 0;
                        }
                    }
                    @media screen and (max-width: 1300px)  and (min-width: 1125px)  {
                        width: calc(((100% / 2) - 15px) + 7.5px);
                        &:nth-child(4n + 4) {
                            margin-right: 15px;
                        }
                        &:nth-child(2n + 2) {
                            margin-right: 0;
                        }
                    }
                    @media screen and (max-width: 1124px)    {
                        width: 100%;
                        margin-left: 15px;
                        margin-right: 15px;
                    }
                }
            }

    }
}

/*
Blocks Custom Twitter Feed 60% and 30% column

Markup: blocks-custom-twitter-60-30.hbs

Styleguide Blocks.Custom-Twitter-Feed-60-30
*/
