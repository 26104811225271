/*
Error Pages

Styleguide z99-Error-Pages
*/



/*
404 page

Markup: 404.hbs

Styleguide z99-Error-Pages.404-page
*/


.ay-404-wrapper {
  background: #fff url(images/ay-404-bg.png) no-repeat right top;
  background-size:contain;
  margin: 2em auto;
  padding: 5.3125em 100px;
  position: relative;
  width: calc(100%);
  overflow: visible;
  max-width: 1300px;
  min-height:325px;
  transition:background .5s;
  @media screen and (max-width: 1340px){
    margin-left: 42px;
    margin-right: 30px;
    max-width: calc(100% - 72px);
  }
  @media screen and (max-width: 850px){
    background-position:120% top;
  }
  @media screen and (max-width: 770px){
    background-position:140% top;
  }
  @media screen and (max-width: 730px){
    background-position:155% top;
  }

  @media screen and (max-width: 705px){
    background-position:175% top;
  }

  @media screen and (max-width: 675px){
    background-position:195% top;
  }

  @media screen and (max-width: 640px){
    background-position:215% top;
  }

  @media screen and (max-width: 630px){
    background-image: none;
  }

  @media screen and (max-width: 480px){
    margin-top:180px;
  }
   
  &--inner-text {
    max-width:420px;
    a{
      color: $ay-black;
      text-decoration: underline;
    }
    h2{
      font-weight:500;
      padding-bottom:15px;
    }
    &__search{
    background-color: $ay-white;
    border: 1px solid $ay-black;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 14px;
    margin-right: 3px;
    max-width: 250px;
    padding: .3em;
    height: 25px;
    width: 100%;
    }
  }
}
