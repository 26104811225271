// Logos
//
// Logos used in the UI framework
//
// Styleguide 01-Basics~02-Logos


/*
Primary Logo

Markup: logo.hbs 

Styleguide 01-Basics~02-Logos.Primary-Logo
*/

.ay-logo {
	width:260px;
	height: auto;
}

/*
Raw SVG Logo

Markup: raw-svg-logo.hbs

Styleguide 01-Basics~02-Logos.Raw-SVG-Logo
*/