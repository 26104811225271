.ay-footer {
  .ay-footer-social {
    ul {
      li {
        &:first-child {
          a{
            img{
              margin-left: 0;
              margin-right: 5px;
            }
          }
        }
        &:last-child {
          margin-right: 5px;
      }
      }
    }
  }
}
