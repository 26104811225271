/*
Layout Grids

Markup: columns.hbs

Styleguide 01-Basics~05-Layout-Grids
*/

.ay-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  padding: 0.625rem 0;
  position: relative;
  width: 100%;
  align-items: center;
  &.ay-main-grey,
  &.ay-main-white .ay-lts-media-block-content,
  &.ay-main-white .ay-lts-key-professionals-item {
    background: $ay-secondary-colors--secondary-extra-light;
  }
  &.ay-main-grey,
  &.ay-main-white {
    padding-bottom: 25px;
    padding-top: 25px;
    .portlet-body > .ay-service-lts-title,
    .portlet-body > div[style="text-align: center"]  .ay-service-lts-title {
      padding-top: 0;
    }
  }
  .ay-column-one-grey {
    background: $ay-secondary-colors--secondary-extra-light;
    margin-top: -8.75px;
    padding-bottom: 15px;
    width: 100vw;
  }
}

.ay-column {
  color: $ay-black;
  display: block;
  max-width: 1300px;
  width: 100%;

  &__title {
    font-size: 2.8125rem;
    color: $ay-black;
    margin-bottom: 32px;
  }

  @media screen and (max-width: $phone) {
    flex-direction: column;
  }
}

/*
Single Column

Markup: single-column.hbs

Styleguide 01-Basics~05-Layout-Grids.01-Single-Column
*/

.ay-column__single {
  background-color: $ay-primary-color--cloud-grey-light;
  box-shadow: none;
  margin: 2em auto;
  padding: 5.3125rem 100px;
  overflow: visible;
  &.ay-main-grey.ay-white-alt.ay-single-column-double-row-white-trans {
    background-color: transparent !important;
    box-shadow: none;
    margin: 0;
    padding: 0;
    .portlet-layout.row-fluid{
      &:first-child {
        background-color: $ay-white;
        box-shadow: none;
        margin: 2em auto;
        padding: 5.3125rem 100px 50px;
        overflow: visible;
        @media screen and (max-width: $phone) {
          padding: 3.75em 8%;
        }
      }
    }
  }
  &.ay-main-grey {
    background: $ay-secondary-colors--secondary-extra-light;
    &:before{
      background: $ay-secondary-colors--secondary-extra-light;
      content:"";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top:0;
      width: 100%;
      z-index: -1;
    }
    &.ay-white-alt{
      background: $ay-white;
    }
      .ay-hr{
        display: none;
      }
      a.ay-button__arrow{
        background: $ay-white;
        color: $ay-black;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }

  }

  //changed to visible for overflow for single because of professionals social labels
  @media print {
    padding: 0.625rem;
    margin: 0.625rem auto;
    box-shadow: none;
    font-size: 10px;
  }

  @media screen and (max-width: $tablet-portrait) {
    padding: 3.75rem 8%;
  }

  @media screen and (max-width: $phone) {
    padding: 1.25rem 1.25rem;
    .ay-news-release .h2,
    .ay-news-release h2,
    .ay-news-release__content {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--full {
    padding: 0;
  }

  &--blank {
    background: none;
    box-shadow: none;
    padding: 0;
    margin: 0 auto;
  }

  &--boiler {
    padding: 2.5rem 5%;
    font-size: 1rem;
  }

  .h2, h2 {
    margin-bottom: 12px;
  }

  .h3, h3 {
    margin-bottom: 15px;
  }

  .h4, h4 {
    margin-bottom: 10px;
    line-height: 1.3;
  }

  .ay-subtitle {
    font-family: $header-font;

    @media screen and (max-width: $phone) {
      line-height: 1.3;
      margin-bottom: 0;
    }
  }

  a {
    color: $ay-black;
    //text-decoration: underline;
    &:hover {
      //text-decoration: underline;
    }

    .h3, h3 {
      margin-bottom: 0;
    }
    @extend .ay-updated-content-link-style;
  }

  p {
    line-height: 1.5;
    margin-bottom: 15px;

    @media print {
      font-size: 10px;
    }
  }
}

/*
Two Column

Markup: two-column.hbs

Styleguide 01-Basics~05-Layout-Grids.02-Two-Column
*/

.ay-column__double {

  max-width: 1300px;
  width: calc(100% - 2.8em);
  margin: 1em auto;
  margin-top: 0px;

  @media print {
    width: inherit;
  }


  .h2, h2 {
    margin-bottom: 12px;
  }

  &--col1 {
    padding: .5em 0 1em 0;

    // left column padding-left change to 0 from 1em to align with grid
    margin-top: 1em;

    @include grid-column(6);

    &__66 {
      @include grid-column(8);
    }

    @media screen and (max-width: $tablet-portrait) {
      width: 100%;
      margin: 0;
      float: none;
      padding-left: 0;
    }

    @media screen and (max-width: $phone) {
      display: block;
      padding: 3px;
    }

    @media print {
      clear: both;
      width: 100%;
      padding: 0;
    }
  }

  &--col2 {
    @include grid-column(6);

    &__33 {
      @include grid-column(4);
    }
    padding: .5em 0 1em 0;

    //padding-right changed to 0 from 1em to align with grid
    margin-top: 1em;

    @media screen and (max-width: $tablet-portrait) {
      width: 100%;
      margin: 0;
      float: none;
      padding-right: 0;
    }

    @media screen and (max-width: $phone) {
      margin-bottom: 1.25rem;
      display: block;
      padding: 3px;
    }

    @media print {
      clear: both;
      width: 100%;
    }
  }

  @media screen and (max-width: $phone) {
    &--col1, &--col2 {
      @include grid-column(12);
    }
  }
}

/*
One-Two Column 60/30

This is the specialized 60-30 used on office landing pages

Markup: one-two-column.hbs

Styleguide 01-Basics~05-Layout-Grids.03-One-Two-Column
*/
 .ay-column__single--triple-top {}

.ay-column__triple {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 1.5625em;

  @media screen and (max-width: $desktop-large) {
    margin-left: 42px;
    margin-right: 30px;
    max-width: calc(100% - 72px);
  }

  @media screen and (max-width: 1079px) {
    margin-left: 0;
  }

  @media screen and (max-width: 999px) {
    margin-left: 0;
    margin-right: 0;
    max-width: calc(100% - 40px);
  }

  @media print {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  &--col1 {
    width: calc( 100% - 438px);
    height: 100%;

    @media screen and (max-width: 930px) {
      width: calc(100%);
      order: 2;
    }

    @media print {
      width: calc(100%);
    }
  }

  &--col2 {
    width: 420px;

    @media screen and (max-width: 931px) {
      width: 100%;
      margin-right: 0;
      order: 1;
    }

    @media print {
      width: 100%;
    }
  }

  &--col3 {
    width: 420px;
    margin-left: auto;

    @media screen and (max-width: 931px) {
      width: 100%;
      margin-left: 0;
      margin-top: 0 !important;
      order: 3;
    }
    @media print {
      width: 100%;
    }
  }
}

//addresses firefox's issue with flexbox and margin-top
.aui.firefox #ay .ay-column__triple--col1 {
  height: auto;
}

//update to columns to make them work better
.ay-column.ay-column__triple {
  width: 100%;
  height: 100%;
  max-width: 1300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  a {
    color: $ay-black;
    @extend .ay-updated-content-link-style;
  }
}

.kss-modifier__example .ay-column.ay-column__triple {
  justify-content: space-between;
}

.rcAll {
  width: 420px;
}

@media screen and (max-width: 930px) {
  .ay-column__triple--col1 {
    width: calc(100%);
    order: 2;
  }
  .ay-column__triple--col2,
  .ay-column__triple--col3 {
    width: 100%;
  }
  .ay-column__triple--col2 {
    order: 1;
  }
  .ay-column__triple--col3 {
    margin-left: 0;
    order: 3;
  }
}

#ay .ay-column.ay-column__triple.portlet-layout {
  justify-content: space-between;
}

@media screen and (max-width: 1300px) {
  #ay .ay-column.ay-column__triple.portlet-layout {
    margin-left: 42px;
    margin-right: 30px;
    max-width: calc(100% - 72px);
  }
}

@media screen and (max-width: 1080px){
  #ay .ay-column.ay-column__triple.portlet-layout {
    margin-left: 20px;
    margin-right: 40px;
    max-width: calc(100% - 68px);
  }
}


@media screen and (max-width: 999px) {
  #ay .ay-column.ay-column__triple.portlet-layout {
    margin-left: 20px;
    margin-right: 20px;
    max-width: calc(100% - 40px);
  }
}

#ay.ay-body-background.ay-alt-white {
  background: $ay-secondary-colors--secondary-extra-light;
}

/*
One-Two Column 30/60

This is the specialized 30/60 used on property search

Markup: one-two-column-33-66.hbs

Styleguide 01-Basics~05-Layout-Grids.04-One-Two-Column-33-66
*/

.ay-column.ay-column__triple.ay-column-3060.portlet-layout {
  @media screen and (max-width: $tablet-portrait) {
    flex-direction: column;
  }
  .ay-column__triple--col1 {
    width: 420px;
    h2,
    .h2 {
     color: $ay-black;
     font-size: 24px;
     margin-bottom: 15px;
     padding: 18px 20px 0 0;
    }
    h4,
    .h4 {
      margin-bottom: 15px
    }
    .ay-list-links {

    }
    .ay-main.ay-main-grey {
      align-items: flex-start;
    }
    .ay-blog-tiles-wrap {
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0;
      //width: 85%;
      @media screen and (max-width: $tablet-portrait) {
          max-width: none;
          justify-content: space-between;
      }
      ul {
        margin: 0;
        li {
          margin: 0 0 10px 0;
          @media screen and (max-width: $tablet-portrait) and (min-width: $phone) {
            width: calc((100% - 15px)/ 2);
            &:nth-child(2n - 2){
              margin-left: 10px;
            }
          }
          a {
            background: transparent;
            div img {
              display: none;
            }
            span {
              display: none;
              top: auto;
              width: fit-content;
              position: relative;
            }
            figure figcaption {
              padding: 20px 0;
            }
            .ay-blog-post-td {
              display: none;
              padding: 5px 0 5px 20px;
              margin-left: 0;
            }
            .ay-blog-tile-post-tags {
              display: none;
            }
            &:after {
              border-bottom-color: $ay-black;
              width: 100%;
            }
          }
        }
      }
    }
    .portlet-body {
      width: 85%;
      @media screen and (max-width: $tablet-portrait) {
        width: 100%;
      }
    }
    @media screen and (max-width: 930px) {
      order: 1;
    }
    @media screen and (max-width: 900px) {
      width: 50%;
    }
    @media screen and (max-width: $tablet-portrait) {
      width: 100%;
      iframe {
        width: 100%;
      }
    }
  }
  .ay-column__triple--col2 {
    ul.ay-blog-tile-post-tags {
      margin: 0 29px;
    }
    width: calc(100% - 420px);
    @media screen and (max-width: 930px) {
      order: 2;
    }
    @media screen and (max-width: 900px) {
      width: 50%;
    }
    @media screen and (max-width: $tablet-portrait) {
      width: 100%;
    }
  }
}
