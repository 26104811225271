/*
Email-Modal

Markup: email-modal.hbs 

Styleguide Email-Modal
*/

.ay-lightbox.ay-email-lightbox {
    &.ay-lightbox--on {
        position: fixed;
        .ay-sec-close {
            align-items: center;
            color: #ccc;
            display: flex;
            font-size: 30px;
            height: 30px;
            position: absolute;
            right: 20px;
            text-decoration: none;
            top: 10px;
        }
        form {
            background: #ffffff;
            display: block;
            max-width: 500px;
            padding: 20px;
            width: 100%;
            input[type=text] {
                box-shadow: none;
                height: auto;
            }
        }
    }
}