/*
Professionals

Markup: professionals.hbs

Styleguide Professionals
*/

/*
Professionals - Right to Left

Markup: professionals-rtl.hbs

Styleguide Professionals.Professional Right to Left
*/

/*
Professionals Empty

Markup: professionals-empty.hbs

Styleguide Professionals.Empty
*/

/*
Professionals Card

Markup: professionals-card.hbs

Styleguide Professionals.Proff-Card
*/

/*
Professionals Contact

Markup: professionals-contact.hbs

Styleguide Professionals.Proff-Contact
*/

/*
Professionals Properties

Markup: professionals-properties.hbs

Styleguide Professionals.Proff-Properties
*/

/*
Professionals Page Example

Markup: professionals-page.hbs

Styleguide Professionals.Proff-Page
*/

/*
Professionals Search

Markup: professionals-search.hbs

Styleguide Professionals.Search
*/

.ay-spec-list {
  display: inline-block;

  &:after {
    content: ", ";
  }

  &:last-child:after {
    content: "";
  }
}

.ay-proff-card {
  display: flex;
  color: $ay-black;
  max-width: 600px;
  width: 100%;

  @media screen and (max-width: $tablet-portrait) {
    flex-direction: column;
  }

  &__profile {
    display: flex;
    flex-direction: column;
    margin-left: 1.875rem;

    @media screen and (max-width: $tablet-portrait) {
      margin: 15px 0 0 0;
    }

    .ay-button__proff {
      background: $ay-black;
      color: $ay-white;
      font-size: 18px;
      max-width: 180px;
      margin: 20px 0;
      padding: 10px;

      &:hover {
        background: $ay-white;
      }

      &:after {
        height: 16px;
      }
    }
  }

  &__name {
    margin-top: -10px;
  }

  &__img {
    display: block;
    max-width: 180px;
    overflow: hidden;
    width: 100%;

    img {
      display: block;
      height: 225px;
      max-width: 100%;
      object-fit: contain;
      width: 180px;
    }
  }

  &__name {
    font-size: 48px;
    color: $ay-black;

    @media print {
      font-size: 24px;
    }

    @media screen and (max-width: $tablet-portrait) {
      font-size: 32px;
    }
  }

  &__title {
    font-size: 1.25rem;
    color: $ay-black;
    font-weight: $semibold;

    span {
      font-weight: normal;
    }

    &--mobile {
      display: none;

      @media screen and (max-width: $tablet-portrait) {
        display: block;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    max-width: 500px;

    @media screen and (max-width: $tablet-portrait) {
      flex-direction: column;
      font-size: 16px;
    }

    ul {
      margin: 20px 0 0 0;
    }

    li {
      line-height: 1.5em;

      strong {
        color: $ay-black;
      }
      a {
        color: $ay-black;
      }
      a.office-link {
        color: $ay-black;
      }
    }
  }
}

// Bio
.ay-proff-bio {
  position: relative;
  border-top: 2px solid $ay-black;
  margin-top: 30px;
  padding-top: 5px;

  @media screen and (max-width: $tablet-portrait) {
    margin-top: 90px;
  }

  ul {
    margin: 0 0 0 20px;
    padding: 0;

    &:first-child {
      margin-top: 20px;
    }

    @media screen and (max-width: $phone) {
      margin-left: 12px;

      li:before {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &__social {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    left: -65px;
    position: absolute;
    top: 25px;
    width: 55px;

    @media print {
      display: none;
    }

    @media screen and (max-width: $tablet-portrait) {
      align-items: flex-start;
      flex-direction: row;
      left: 0;
      width: 100%;
      top: -50px;
    }

    &--item {
      &:hover:before {
        @media screen and (min-width: $tablet-portrait) {
          content: attr(data-title);
          position: absolute;
          font-size: 10px;
          margin-left: -70px;
          margin-top: 8px;
          width: auto;
          color: $ay-black;
        }

        @media screen and (max-width: $tablet-portrait) {
          content: attr(data-title);
          position: absolute;
          font-size: 10px;
          margin-left: -30px;
          margin-top: 40px;
          width: auto;
          background: #fff;
          padding: 5px;
          color: $ay-black;
        }
      }

      &:nth-child(1) {
        svg {
          height: 29px;
          margin-left: 0;
        }
        &:nth-child(4) {
          height: 27px;
        }
        &:hover:before {
          @media screen and (min-width: $tablet-portrait) {
            margin-left: -72px;
            margin-top: 3px;
          }

          @media screen and (max-width: $tablet-portrait) {
            margin-left: -20px;
          }
        }
      }

      &:nth-child(3) {
        &:hover:before {
          @media screen and (min-width: $tablet-portrait) {
            margin-left: -40px;
          }

          @media screen and (max-width: $tablet-portrait) {
            margin-left: -10px;
          }
        }
      }
    }

    &--icon {
      color: $ay-primary-color--cloud-grey-dark;
      display: block;
      height: 30px;
      margin-bottom: 12px;
      width: 100%;
      //fill: transparent;
      //stroke: $ay-black;
      @media screen and (max-width: $tablet-portrait) {
        width: 30px;
        margin-right: 10px;
      }

      &:hover {
        color: $ay-black;
      }
    }
  }
}

// Contact
.ay-ul__proff {
  margin-right: 4.375rem;
  margin-top: 0;
  line-height: 1.5em;

  strong {
    color: $ay-black;
  }

  a {
    color: $ay-black;
    &:hover {
      text-decoration: underline;
    }
    &.nostyle {
      color: $ay-black;
      text-decoration: none;

      &:hover {
        color: $ay-black;
        text-decoration: underline;
      }
    }
  }
}

.ay-proff-contact {
  &__social {
    display: inline-block;
    color: $ay-black !important;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &--icon {
      display: inline-block;
      fill: transparent;
      height: 20px;
      width: 20px;
      margin-right: 5px;
      position: relative;
      top: 3px;
    }
  }
}

// Properties
.ay-prop__title {
  background-color: $ay-black;
  color: $ay-white;
  font-size: 34px;
  padding: 15px 48px;
  text-align: left;

  @media screen and (max-width: $phone) {
    padding: 15px 20px;
    font-size: 24px;
  }
}

.ay-prop {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;
  width: 100%;

  @media screen and (max-width: $tablet-portrait) {
    display: block;
  }

  tr {
    @media screen and (max-width: $tablet-portrait) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border-bottom: 1px solid #ccc;
      padding: 20px;

      td {
        font-size: 16px;
        border-bottom: none;
        display: block;

        // added for ms browsers Edge and IE yay
        &:first-child {
          // image td
          order: 1;
          width: 40%;
          padding: 0;

          img {
            // make sure the image grows and flows properly
            width: 100%;
            max-height: 92px;
            max-width: 130px;

            @media screen and (max-width: 375px) {
              width: 130px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  td {
    padding: 20px 5px;
    border-bottom: 1px solid $ay-black;

    &:first-child {
      width: 20%;
      padding-left: 48px;
    }

    &:nth-child(2) {
      width: 30%;

      @media screen and (max-width: $tablet-portrait) {
        // name and link
        order: 4;
        width: 100%;
        padding: 10px 0 0 0;
      }

      @media screen and (max-width: $phone) {
        padding-top: 0;
      }
    }

    &:nth-child(3) {
      width: 25%;

      @media screen and (max-width: $tablet-portrait) {
        // address
        order: 5;
        width: 100%;
        padding: 0;
      }

      @media screen and (max-width: $phone) {
        width: 100%;
        margin-left: 0;
      }
    }

    &:nth-child(4) {
      width: 15%;

      @media screen and (max-width: $tablet-portrait) {
        // type of property
        font-weight: $semibold;
        height: 20px;
        order: 2;
        padding: 0;
        width: 30%;
      }

      @media screen and (max-width: $phone) {
        width: 100%;
        margin-left: 0;
      }
    }

    &:nth-child(5) {
      width: 10%;

      @media screen and (max-width: $tablet-portrait) {
        // rental/sale etc
        order: 3;
        width: 30%;
        font-weight: $semibold;
        height: 80px;

        // this may need to be updated depending on other labels other than "industrial" and "for sale"
        padding: 0;
        margin-left: -30%;
        margin-top: 30px;
      }

      @media screen and (max-width: $phone) {
        width: 100%;
        margin-left: 0;
        margin-top: 0;
        height: auto;
      }
    }

    a {
      color: $ay-black;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    @media screen and (max-width: $tablet-landscape) {
      font-size: 14px;
    }
  }

  &-img {
    @media screen and (max-width: $tablet-landscape) {
      max-width: 100%;
      height: auto;
    }
  }

  // support for MS IE11/10 Browsers
  @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) and (max-width: $tablet-portrait) {
    tr {
      td {
        &:first-child {
          width: 30%;

          // addresses over sized images on IE10/IE11
        }
      }
    }
  }
}

// Search Professionals
// Sorting Filter

.ay-search-proff-sort {
  display: flex;
  align-items: center;

  @media screen and (max-width: $tablet-portrait) {
    display: none;
  }

  // Mobile Sort
  &__mobile {
    display: none;

    @media screen and (max-width: $tablet-portrait) {
      align-items: center;
      background-color: $ay-white;
      display: flex;
      flex-direction: column;
      margin: 0 21px 20px 21px;
      padding: 20px 8%;
      width: auto;

      .ay-search {
        height: 38px;
      }

      .ay-button__secondary {
        font-size: 13px;
        font-weight: normal;
        margin: 20px 0 0 0;
        padding: 10px 0;
        width: 40%;
      }
    }

    @media screen and (max-width: $phone) {
      margin: 0 0 20px 0;
      padding: 0;
      box-shadow: none;
    }
  }

  .ay-search__filter-holder {
    width: 100%;
    margin: 0 10px;

    @media screen and (max-width: $tablet-portrait) {
      background: $ay-white;
      padding: 1.25rem 0 0 0;
      margin: 0;
    }

    // Select Dropdown
    select {
      appearance: none;
      background: $ay-white url(images/arrow-select-black.svg) no-repeat 97%
        center;
      background-size: 15px;
      border-radius: 0;
      border: 1px solid $ay-black;
      color: $ay-black;
      font-size: 0.8125rem;
      outline: none;
      padding: 0 0.75rem;
      width: 100%;
      line-height: normal;
      min-height: 32px;

      &::-moz-focus-inner {
        border: 0;
      }

      option {
        &::-moz-focus-inner {
          border: 0;
        }
      }

      @media screen and (max-width: $tablet-portrait) {
        width: 100%;
      }

      @media screen and (max-width: $phone) {
        font-size: 16px;
      }
    }
  }

  // Search Field
  input[type="search"] {
    background: none;
    border: 1px solid $ay-black;
    color: $ay-black;
    height: 32px;
    padding-left: 0.6em;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    margin-right: 10px;
    width: 100%;

    @media screen and (max-width: $tablet-portrait) {
      background: $ay-secondary-colors--medium-medium;
      border: none;
    }

    &::placeholder {
      color: $ay-secondary-colors--extra-dark;
      font-size: 16px;
      opacity: 1;
    }

    @media screen and (max-width: $phone) {
      font-size: 16px;
    }
  }

  // Submit Button
  input.ay-search-proff-sort__submit {
    background: $ay-black
      url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iI0ZGRkZGRiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K)
      no-repeat;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    background-position: center center;
    background-size: 22px;
    border: none;
    height: 32px;
    min-width: 40px;
    position: relative;
    text-indent: -9999px;
    width: auto;

    @media screen and (max-width: $tablet-portrait) {
      display: none;
    }
  }
}

// Table
.ay-search-proff-table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 15px;
  margin: 10px 0 0;
  padding: 0;
  text-align: left;
  width: 100%;

  @media screen and (max-width: $tablet-landscape) {
    margin: 0 30px;
    width: auto;
  }

  @media screen and (max-width: $phone) {
    margin: 0;
  }

  caption {
    width: 100%;
    text-align: left;
    padding: 1em 0;

    @media screen and (max-width: $tablet-landscape) {
      padding-bottom: 0;
    }
  }

  & > tbody > tr {
    td:nth-child(2) {
      white-space: pre;
      @media screen and (max-width: 999px) {
        white-space: normal;
      }
    }

    td table tr td:nth-child(2) {
      white-space: normal;
    }
  }

  > tr {
    border-bottom: 2px solid #ccc;

    @media screen and (max-width: $tablet-landscape) {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #ccc;
      width: 100%;
      overflow: hidden;
      padding: 20px 0;
    }
  }

  tr {
    @media screen and (max-width: $tablet-landscape) {
      border-bottom: 1px solid #ccc;
      display: block;
      padding: 20px 0;
    }
  }

  td {
    padding: 10px;
    vertical-align: middle;

    @media screen and (max-width: $tablet-landscape) {
      display: inline-block;
      width: 100%;
      padding: 1px 0 1px 0;
      font-size: 14px;

      &:first-child {
        order: 1;
        width: 100%;
        padding: 0;
      }
    }

    a {
      color: $ay-black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &.no-break {
        white-space: pre;
      }
      /*&[href^="tel:"]{
        color:$ay-general;
        cursor:text;
        &:hover{
            text-decoration: none;
        }
      }*/
    }
  }

  // Table header
  &__header {
    @media screen and (max-width: $tablet-landscape) {
      display: none;
      padding: 0 !important;
      border: none !important;
    }

    background-color: $ay-black;

    td {
      background-color: $ay-black;
      color: $ay-white;
      padding: 10px;

      @media screen and (max-width: $tablet-landscape) {
        display: none;
        padding: 0;
      }
    }
  }

  // First Cell Styles
  &__name-image {
    border-collapse: collapse;
    border-spacing: 0;
    width: auto;

    @media screen and (max-width: $tablet-landscape) {
      width: 100%;
      margin-bottom: 10px;
    }

    tr {
      border-bottom: none;

      @media screen and (max-width: $tablet-landscape) {
        display: flex;
        padding-bottom: 0;
        padding-top: 0;
        margin: 0;
      }

      td {
        vertical-align: top;

        img {
          max-width: 45px;

          &[src=""],
          &[error="true"] {
            display: none;
          }
        }

        @media screen and (max-width: $tablet-landscape) {
          padding-left: 0;
          width: 80%;
          font-size: 18px;
          padding-left: 15px;

          &:first-child {
            border: none;
            order: 0;
            width: 20%;
            padding: 0;
            margin: 0;
          }

          img {
            max-width: 100%;
            width: 100%;
          }
        }

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }

  // Mobile Header
  &__title--mobile {
    display: none;

    @media screen and (max-width: $tablet-landscape) {
      background-color: $ay-black;
      color: $ay-white;
      display: block;
      font-size: 16px;
      font-weight: bold;
      padding: 10px 30px;
    }
  }
}

//added styles for displaying type of numbers
.aui.mobile #ay .ay-phone-type:after {
  content: " (" attr(title) ")";
}
@media screen and (max-width: 768px) {
  .aui #ay .ay-proff-card__holder.ay-no-bio .ay-proff-bio__social {
    position: relative;
  }
}
//this adds an alternative layout for case studies listings
//location hash of #ayAltLayout
//will only work when you're logged out.
@media screen and (min-width: 768px) {
  .aui
    body:not(.dockbar-ready)
    #ay
    #ayAltLayout:target
    .ay-search-proff-table.ay-services--case-studies-list
    tr {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .aui
    body:not(.dockbar-ready)
    #ay
    #ayAltLayout:target
    .ay-search-proff-table.ay-services--case-studies-list
    tr
    td {
    width: calc(100% / 5);
  }

  .aui
    body:not(.dockbar-ready)
    #ay
    #ayAltLayout:target
    .ay-search-proff-table.ay-services--case-studies-list
    tr
    td:last-child {
    /* position:absolute; */
    display: none;
  }

  .aui
    body:not(.dockbar-ready)
    #ay
    #ayAltLayout:target
    .ay-search-proff-table.ay-services--case-studies-list
    tr:not(:first-child)
    td.ay-cs-details {
    display: block;
    width: calc(100%);
    margin-left: calc(100% / 5);
    margin-top: -16px;
  }

  .aui
    body:not(.dockbar-ready)
    #ay
    #ayAltLayout:target
    .ay-search-proff-table.ay-services--case-studies-list
    tr:not(:first-child)
    td.ay-cs-details
    div {
    padding-left: 5px;
  }

  .aui
    body:not(.dockbar-ready)
    #ay
    #ayAltLayout:target
    .ay-search-proff-table.ay-services--case-studies-list
    tr:nth-child(even) {
    background: rgba(0, 32, 91, 0.03);
  }

  .aui
    body:not(.dockbar-ready)
    #ay
    #ayAltLayout:target
    .ay-search-proff-table.ay-services--case-studies-list
    tr:not(:first-child)
    td.ay-cs-details:before {
    content: attr(data-mobile-title);
    color: #fff;
    background: #00205b;
    width: 100%;
    padding: 10px 5px;
    margin: 5px 0;
    display: block;
    position: relative;
    z-index: 1;
  }

  .aui
    body:not(.dockbar-ready)
    #ay
    #ayAltLayout:target
    .ay-search-proff-table.ay-services--case-studies-list
    tr:not(:first-child)
    td.ay-cs-details.ay-none:before {
    display: none;
  }
}
// fix for ie11 issue and keeping it from messing up other browsers
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ay-proff-bio__social--icon {
    width: 60px;
  }
}

/*
Professionals Key Contact

Markup: professionals-key-contacts.hbs

Styleguide Professionals.Key-Contacts
*/
.ay-five-row-100-col-5 {
  background: $ay-secondary-colors--medium-medium;
  padding: 10px 0;
  width: 100vw;
}
.ay-lts-key-professionals-wrap {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 25px auto 25px auto;
  max-width: $desktop-large;
  padding-bottom: 25px;
  width: calc(100vw - 72px);
  @media screen and (max-width: 1372px) {
    margin-left: 36px;
    margin-right: 36px;
    max-width: calc(100% - 72px);
  }
  @media screen and (max-width: 969px) {
    align-items: center;
    flex-direction: column;
  }
  .ay-lts-media-block.ay-lts-text-right {
    @media screen and (max-width: 768px) {
      flex-direction: row-reverse;
    }
  }

  .ay-lts-key-professionals-item {
    align-items: flex-start;
    background: transparent;
    flex-wrap: wrap;
    margin: 0 0 12px 0;
    width: calc(50% - 8px);
    @media screen and (max-width: 969px) {
      width: 100%;
      max-width: none;
    }
    &:nth-child(even) {
      margin-left: 12px;
      @media screen and (max-width: 969px) {
        margin-left: 0;
      }
    }
    .ay-lts-media-block-media {
      background: transparent;
      img {
        max-height: 151px;
        object-fit: cover;
        transform: scale(1) !important;
      }
    }
    .ay-lts-media-block-content {
      font-size: 12px;
      min-height: 105px;
      width: calc(100% - 105px);
      a:after {
        display: none;
      }
      .ay-lts-email-phone-link {
        color: $ay-black;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      .ay-lts-media-block-phone-links {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        margin-top: auto;
        max-height: 14px;
        width: 100%;
        .ay-lts-media-block-read-more {
          margin-bottom: 0;
          margin-right: 15px;
          &:after {
            transform: rotate(90deg);
            transition: all 0.5s;
          }
          span:last-child {
            display: none;
          }
        }
        .ay-lts-media-block-get-in-touch {
          background: url(images/professional-mail-blue.svg) no-repeat right
            center;
          background-size: 23px auto;
          color: $ay-primary-colors--medium-light;
          margin-bottom: 0;
          margin-right: 15px;
          padding-right: 30px;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
      h3 {
        padding-top: 5px;
        font-size: 18px;
        line-height: 20px;
        & + ul {
          margin-bottom: 3px;
          margin-top: 3px;
        }
        a {
          color: $ay-black;
          text-decoration: none;
          transition: color 0.5s;
          &:hover {
            color: $ay-black;
            text-decoration: underline;
          }
        }
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        &.bold {
          font-weight: 700;
        }

        li {
          ul {
            display: flex;
            flex-wrap: wrap;

            li {
              text-transform: uppercase;
              &:after {
                content: ", ";
                margin-right: 5px;
              }
              &:last-child:after {
                display: none;
              }
            }
          }
        }
      }
    }
    .ay-lts-media-block-media {
      max-width: 105px;
    }
    &.open {
      .ay-lts-media-block-read-more {
        &:after {
          transform: rotate(-90deg) !important;
        }
        span:first-child {
          display: none;
        }
        span:last-child {
          display: inline-block !important;
        }
      }
      .ay-lts-media-block-form {
        height: auto;
        max-height: 1500px;
        padding: 25px;
      }
    }
    .ay-lts-media-block-form {
      border-top: 5px solid $ay-black;
      height: 5px;
      margin: 0;
      max-height: 0;
      overflow: hidden;
      padding: 0 25px;
      transition: max-height 0.75s;
      width: 100%;

      a {
        color: $ay-black;
      }
      label {
        display: block;
        text-transform: uppercase;
      }
      input[type="text"],
      textarea {
        border: 1px solid $ay-black;
        height: 38px;
        margin: 5px 0 10px 0;
        width: 100%;
      }
      textarea {
        height: 118px;
      }
      .fieldset {
        border: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        h4,
        div {
          width: 100%;
          color: $ay-black;
          font-weight: normal;
          font-size: 15px;
          text-transform: uppercase;
        }
        h4 {
          margin-bottom: 10px;
        }
        label {
          // display: inline-block;
          width: 49%;
          text-transform: none;
          margin-bottom: 10px;
        }
      }
      input.ay-button__primary {
        background: $ay-black;
        display: block;
        margin: 20px auto 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        width: auto;
        &:hover {
          background: $ay-white;
        }
      }
    }
  }
  /* goes here */
  gap:25px !important;
  align-items:stretch;
  justify-content:start;
  margin:unset;
  max-width:100%;

  .ay-lts-media-block {
    @media screen and (max-width: 768px) {
      width: 100% !important;
      margin: unset !important;
    }
  }

  .ay-lts-key-professionals-item {
    @media screen and (max-width: 969px) {
      width: 100%;
      max-width: 100%;
    }
    h3 {
      font-size:1.4rem !important;
    }
  }


  * {
    text-transform:unset !important;
    font-family:'Open sans', sans-serif;
  }
  a{
    &.ay-lts-email-phone-link {
      font-size:1rem;
      max-height:unset;
    }
  }
  .ay-lts-media-block-content {
    * {
      margin:unset !important;
      padding:unset !important;
    }
    :not(h3)  {
      line-height:1.5;
    }
  ul{
    &.bold {
      li {
        font-weight:800;
      }
    }
  }
    h3 {
      font-size: 1.6875rem !important;
      font-weight: 600;
      line-height: 110% !important;
      a  {
        line-height:120%;
      }
      & + ul > li {
        margin-top: 4px !important;
        font-size: 1rem;
      }
    }
  }
}
/*
Professionals Key Contact Column 33%

Markup: professionals-key-contacts-col33.hbs

Styleguide Professionals.Key-Contacts-Col-33
*/
.ay-column__double--col2__33,
.ay-column__double.ay-column__double--66-33 .ay-column__triple--col2 {
  .ay-lts-key-professionals-wrap {

    width: 100%;
    flex-direction: column;
    .ay-lts-key-professionals-item {
      width: 100%;
      @media screen and (max-width: 1372px) and (min-width: 769px) {
        max-width: none;
      }
      .ay-lts-media-block-content {
        width: 63%;
        @media screen and (max-width: 1372px) and (min-width: 769px) {
          order: 2;
          padding-left: 0;
          width: 100%;
        }
      }
      &:nth-child(even) {
        margin-left: 0;
      }
      .ay-lts-media-block-media {
        @media screen and (max-width: 1372px) and (min-width: 769px) {
          margin: 0 auto 0 0;
          max-width: none;
          width: 75%;
        }
      }

    }
  }
}

/*
Professionals Team Contacts

Markup: professionals-teams.hbs

Styleguide Professionals.Key-Contacts-Col-33.Teams
*/

#ay
  .ay-column__double--col2__33
  .ay-team-members
  .ay-lts-key-professionals-wrap
  .ay-lts-key-professionals-item
  .ay-lts-media-block-content,
#ay
  .ay-column__double.ay-column__double--66-33
  .ay-column__triple--col2
  .ay-team-members
  .ay-lts-key-professionals-wrap
  .ay-lts-key-professionals-item
  .ay-lts-media-block-content {
  width: 74%;
}

@media screen and (max-width: 1372px) and (min-width: 769px) {
  #ay .ay-team-members .ay-lts-key-professionals-wrap {
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }

  #ay
    .ay-column__double--col2__33
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-media,
  #ay
    .ay-column__double.ay-column__double--66-33
    .ay-column__triple--col2
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-media {
    max-width: none;
    width: 100%;
  }

  #ay
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-media
    img {
    max-height: none;
    width: 100%;
  }
}

@media screen and (max-width: 769px) {
  #ay
    .ay-column__double--col2__33
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-content,
  #ay
    .ay-column__double.ay-column__double--66-33
    .ay-column__triple--col2
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-content {
    width: calc(100% - 105px);
  }
}

@media screen and (max-width: 680px) {
  #ay
    .ay-column__double--col2__33
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-content,
  #ay
    .ay-column__double.ay-column__double--66-33
    .ay-column__triple--col2
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-content {
    width: calc(100% - 105px);
  }
}

@media screen and (max-width: 546px) {
  #ay
    .ay-column__double--col2__33
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-content,
  #ay
    .ay-column__double.ay-column__double--66-33
    .ay-column__triple--col2
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-content {
    width: calc(100% - 105px);
  }
}

@media screen and (max-width: 530px) {
  #ay
    .ay-column__double--col2__33
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-content,
  #ay
    .ay-column__double.ay-column__double--66-33
    .ay-column__triple--col2
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-content {
    width: calc(100% - 105px);
  }
}

@media screen and (max-width: 510px) {
  #ay
    .ay-column__double--col2__33
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-content,
  #ay
    .ay-column__double.ay-column__double--66-33
    .ay-column__triple--col2
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-content {
    width: 100%;
  }
  #ay .ay-team-members .ay-lts-key-professionals-wrap {
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }

  #ay
    .ay-column__double--col2__33
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-media,
  #ay
    .ay-column__double.ay-column__double--66-33
    .ay-column__triple--col2
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-media {
    max-width: none;
    width: 100%;
  }

  #ay
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-media
    img {
    max-height: none;
    width: 100%;
  }

  #ay
    .ay-column__double--col2__33
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item,
  #ay
    .ay-column__double.ay-column__double--66-33
    .ay-column__triple--col2
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item {
    flex-direction: column-reverse;
  }

  #ay
    .ay-column__double--col2__33
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-content,
  #ay
    .ay-column__double.ay-column__double--66-33
    .ay-column__triple--col2
    .ay-team-members
    .ay-lts-key-professionals-wrap
    .ay-lts-key-professionals-item
    .ay-lts-media-block-content {
    padding-left: 0;
  }
}

/*
Professionals Blog Authors

Markup: professionals-blog-authors.hbs

Styleguide Professionals.Blog-Authors
*/

.ay-lts-key-professionals-wrap.ay-blog-detail-authors {
  justify-content: flex-start;
  margin: auto;
  width: 70%;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  .ay-lts-key-professionals-item {
    width: 100%;
    &:nth-child(even) {
      margin-left: 0;
    }
  }
  .ay-lts-key-professionals-item .ay-lts-media-block-content {
    h3 {
      &+ul {
        margin-bottom: 3px;
        margin-top: 3px;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
        text-indent: 0;
        &::before {
          display: none;
        }
      }
    }
    &:only-child {
      width: 100%;
      padding-left: 0;
    }
  }
}
// updates for professional email model
.ay-proff-card__info-inline.ay-contact-button {
  strong {
    font-size: 13px;
  }
}
#ay-professional-contact-form.ay-lightbox.ay-email-lightbox {
  display: none;
  .ay-sec-close {
    color: $ay-white;
  }
  .close-via-background {
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
  #ay-contact-form{
    z-index: 20;
    position: relative;
  }
}

#ay-professional-contact-form.ay-lightbox.ay-email-lightbox.ay-lightbox--on {

  align-items: center;

  display: flex;

  flex-direction: column;

  justify-content: center;
  position: fixed;
}
/*
Professionals Bio Detail Page No Bio

Markup: professionals-no-bio.hbs

Styleguide Professionals.Bio-No-Bio
*/
//no bio detail styles
.ay-proff-card__holder {
  margin-left: 76px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
  &.ay-no-bio {
    .ay-hr {
      display: none;
    }
  }
}

/*
Professionals Key Contact 2

Markup: professionals-key-contacts-2.hbs

Styleguide Professionals.Key-Contacts-2
*/

/*.ay-search-proff-table__name-image tr td img, .ay-proff-card__img img, .ay-lts-key-professionals-wrap .ay-lts-key-professionals-item .ay-lts-media-block-media img {
  background: #fff;
} */

.ay-proff-card__holder .ay-proff-card .ay-proff-card__img picture {

  display: block;
  img {
    height: auto;
    width: 100%;
    max-width: 180px;
    max-height: 225px;
    object-fit: contain;
  }
}

.ay-search-proff-table__name-image picture {
  background: #fff;
  display: block;
}

.ay-lts-key-professionals-wrap .ay-lts-key-professionals-item:nth-child(even) {
  margin-left: 0;
}

.ay-lts-key-professionals-wrap .ay-lts-key-professionals-item .ay-lts-media-block-media img {
  min-height: 126px;
}

 .ay-lts-key-professionals-wrap {
  justify-content: start;
  margin: unset;
  max-width: 100%;
  width: 100%;
  gap:25px !important;
  align-items:stretch;
  justify-content:start;
  margin:unset;
  max-width:100%;
}

 .ay-lts-key-professionals-wrap .ay-lts-key-professionals-item {
  background: $ay-white!important;
  padding: 15px;
  margin: unset;
  width: calc((100% - 25px) / 2);
  align-self: stretch;
}

 .ay-lts-key-professionals-wrap .ay-lts-key-professionals-item {
  background: #fff;
}


@media screen and (max-width: 768px) {
  .ay-lts-key-professionals-wrap .ay-lts-media-block {
    width: calc(100% - 40px) !important;
    margin: unset !important;
  }
}

@media screen and (max-width: 969px) {
  .ay-lts-key-professionals-item {
    width: 100%;
    max-width: 100%;
  }
}
.ay-lts-key-professionals-item h3 {
  font-size: 1.4rem !important;
}

 .ay-lts-key-professionals-wrap * {
  text-transform: unset !important;
  font-family: "Open sans", sans-serif;
}

a.ay-lts-email-phone-link {
  font-size: 1rem;
  max-height: unset;
}
 .ay-lts-key-professionals-wrap .ay-lts-media-block.ay-lts-text-right.ay-lts-key-professionals-item .ay-lts-media-block-content {
  width: calc(100% - 126px);
}
 .ay-lts-key-professionals-wrap .ay-lts-media-block-content * {
  margin: unset !important;
  padding: unset !important;
}
 .ay-lts-key-professionals-wrap .ay-lts-media-block-content :not(h3) {
  line-height: 1.5;
}
 .ay-lts-key-professionals-wrap .ay-lts-media-block-content ul.bold li {
  font-weight: 800;
}
 .ay-lts-key-professionals-wrap .ay-lts-media-block-content h3 {
  font-size: 1.6875rem !important;
  font-weight: 600;
  line-height: 110% !important;
}
 .ay-lts-key-professionals-wrap .ay-lts-media-block-content h3 a {
  line-height: 120%;
}
 .ay-lts-key-professionals-wrap .ay-lts-media-block-content h3 + ul > li {
  margin-top: 4px !important;
  font-size: 1rem;
}







@media screen and (min-width: 1080px) {
  .columns-2[id^="_com_liferay_nested_portlets_web_portlet_NestedPortletsPortlet_INSTANCE"] .portlet-layout.row {
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 1080px) {
  .columns-2[id^="_com_liferay_nested_portlets_web_portlet_NestedPortletsPortlet_INSTANCE"] .portlet-layout.row .portlet-column{
    flex: 0 0 85%;
    max-width: 85%;
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;

  }
}

@media screen and (max-width: 480px) {
  .columns-2[id^="_com_liferay_nested_portlets_web_portlet_NestedPortletsPortlet_INSTANCE"] .portlet-layout.row .portlet-column{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ayui #ay .columns-1.ay-column #column-1 .columns-2[id^="_com_liferay_nested_portlets_web_portlet_NestedPortletsPortlet_INSTANCE"] .portlet-column-first #ay-artTitle {
    width: 100%;
  }

}



.portlet-column[id^="_com_liferay_nested_portlets_web_portlet_NestedPortletsPortlet_INSTANCE"],
#column-1.portlet-column.portlet-column-only.col-md-12 {

  padding-left: 0;
  padding-right: 0;
}


.ayui #ay .ay-news-release__social--icon {
  fill: black;
  stroke: none;
}

.ayui #ay .ay-news-release__content {
  margin-left: unset !important;
  margin-right: unset !important;
  padding-left: unset !important;
  padding-right: unset !important;
  max-width: unset !important;
  width:unset !important;
}
.ayui #ay .ay-news-release__content h2 {
  font-size:20px;
  padding-left:unset;
  padding-right:unset;
}

/*
Professionals Bio Detail Page No Bio -  Refresh

Markup: professionals-no-bios-refresh.hbs

Styleguide Professionals.Bio-No-Bio-refresh
*/

.ay-proff-card__holder  .ay-proff-card, .ay-proff-card__holder  .ay-proff-card__profile, .ay-proff-card__holder  .ay-proff-card__info {
  max-width: none;
  width: 100%;
}

.ayui #ay .ay-proff-card__holder  .ay-proff-card__info {
  min-width: 100%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  &> :first-child{
    width: 100%;
    strong {
      display: inline-block;
      width: 79px;
      @media screen and (max-width: 768px) {
        width: 120px;
      }
    }}
    &.ay-sbs {
      &> :first-child{
        max-width: fit-content;//203px;
        width: auto;
        margin-right: 25px;
        @media screen and (max-width: 768px) {
          width: 100%;
          max-width: none;
        }
        li {
          &:has(a[href*="mailto:"]) strong{

            width: 38px;
            @media screen and (max-width: 768px) {
              width: 50px;
            }
          }
        }
      }
      &> :last-child {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        min-width: calc(78% - 200px);
        &:has(ul:first-child:last-child) {
          min-width: unset;
        }
        &:has(ul+ul) {
          min-width: calc(78% - 200px);
        }
        ul.ay-ul {
          margin-right: 40px;
          width: auto;

        }
      }
    }


  &> :nth-child(n+1){
    margin-right: 40px;
  }

}
