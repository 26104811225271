// Research
//
// Research Types
//
// Styleguide Research

/*
Research Page

Markup: research.hbs

Styleguide Research.Page
*/

/*
Research Reports

Markup: research-reports.hbs

Styleguide Research.Reports
*/

/*
Research Quick Links

Markup: research-quicklinks.hbs

Styleguide Research.Quicklinks
*/

/*
Research Quick Links - Right to Left

Markup: research-quicklinks-rtl.hbs

Styleguide Research.Quicklinks.Right to Left
*/

.ay-research-reports {
  background-color: $ay-secondary-colors--extra-light;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 100%;

  @media screen and (max-width: $phone) {
    margin: 30px 0 30px 0;
  }

  &__title {
    background-color: $ay-black;
    color: $ay-white;
    font-size: 30px;
    padding: 12px 47px;

    @media screen and (max-width: $phone) {
      font-size: 28px;
      padding: 12px 20px;
    }

    @media print {
      font-size: 24px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 520px;
    padding: 3.75rem 0;
    width: 100%;

    @media screen and (max-width: 1000px) {
      justify-content: center;
    }
  }

  &__item {
    color: #868686;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0.625rem 0.625rem 1.5625rem 0.625rem;
    max-width: 220px;
    width: 100%;

    @media screen and (max-width: $phone) {
      text-align: center;
      margin-bottom: 1.875rem;
      max-width: 100%;

      span {
        max-width: 250px;
      }
    }

    &:nth-child(even) {
      margin-right: 0;
    }

    &--link {
      color: inherit;
      display: block;
      font-size: 16px;
      text-decoration: none;
      line-height: 120%;

      @media screen and (max-width: $phone) {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
      }

      span {
        line-height: 1.5;
        color: $ay-black;
      }

      img {
        display: block;
        height: 216px;
        margin-bottom: 0.75rem;
        max-width: 100%;
        width: 166px;
      }
    }
  }

  &__subtitle {
    color: $ay-black;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 17px;
  }
}

.ay-research-quicklinks {
  margin-bottom: 30px;

  &__title {
    background: $ay-black;
    color: $ay-white;
    font-size: 23px;
    font-weight: $semibold;
    line-height: 1.3;
    padding: 15px 30px;
    //text-transform: uppercase;

    @media screen and (max-width: $tablet-portrait) {
      padding: 1em;
    }

    @media screen and (max-width: $phone) {
      padding: 12px 20px;
      font-size: 20px;
    }
  }

  &-icon {
    width: 30px;
    height: 30px;
    fill: transparent;
    margin-right: 10px;
    stroke: $ay-black;
    @media screen and (max-width: $phone) {
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }
  }

  &__content {
    background-color: #EEE;
    padding: 0.875rem 0;
  }

  &__links {
    display: flex;
    list-style: none;
    margin: 0 0 0 15px;
    padding: 0;
    width: 100%;

    @media screen and (max-width: $tablet-portrait) {
      padding: 0 0 0 1em;
    }

    @media screen and (max-width: $phone) {
      width: auto;
      padding-left: 5px;
    }

    &--item {
      font-size: 20px;
      padding: 0 0.625rem;
      line-height: 1.5;

      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }

      @media screen and (max-width: $phone) {
        font-size: 14px;
        padding: 0;
        width: 50%;
      }

      &:first-child {
        border-right: 1px solid $ay-black;

        @media screen and (max-width: $phone) {
          margin-right: 20px;
        }
      }

      a {
        color: $ay-black;
        display: flex;
        text-decoration: none;
        justify-content: center;
        &:hover {
          text-decoration: underline;
        }
        @media screen and (max-width: $phone) {
          align-items: center;
        }
      }
    }
  }
}
