.ay-media-post__main--media {
  margin-left: 10px;
  margin-right: 0;
}

.ay-block__light--link:after {
  margin-left: 0;
  margin-right: 5px;
  transform: rotate(180deg);
}
