// Search
//
// Search Types
//
// Styleguide Search

/*
Search Results

Markup: search-results.hbs

Styleguide Search.Search-Results
*/

/*
Search + Login Header

Markup: search-header.hbs

Styleguide Search.Search-Header
*/

.ay-header-search-login {
  display: flex;
  justify-content: flex-end;
  height: 25px;
  position: relative;
  width: auto;

  input:focus,
  select:focus,
  button:focus {
    outline: none;
  }

  &__search {
    align-items: center;
    border-right: 1px solid $ay-black;
    display: flex;
    margin: 0;
    padding-right: 10px;
    width: 100%;

    input[type="search"] {
      background-color: $ay-white;
      border: 1px solid $ay-black;
      font-family: $base-font;
      font-weight: 300;
      font-size: 14px;
      margin-right: 3px;
      max-width: 150px;
      padding: .3em;
      height: 25px;
      width: 100%;
    }

    input[type="submit"] {
      background: url(images/icons-2020/action/search.svg) no-repeat;
      background-size: 22px;
      background-position: center center;
      border: none;
      cursor: pointer;
      height: 30px;
      opacity: .8;
      text-indent: -9999px;
      width: 39px;
    }
  }

  &__login {
    align-items: center;
    display: flex;
    border: none;
    color: $ay-black !important;
    font-size: 10px;
    font-weight: 500;
    line-height: 11px;
    opacity: .8;
    margin-left: 15px;
    text-decoration: none;
    width: 18%;

    @media screen and (max-width: 1100px) {
      line-height: 1.3;
    }

    &:hover {
      text-decoration: none;
      color: $ay-secondary-colors--extra-dark;
    }
  }
}

/*
Basic Search Field

Markup: search-field.hbs

Styleguide Search.Search-Field
*/

.ay-search {
  align-items: center;
  background-color: $ay-white;
  display: flex;
  height: 34px;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  top: 4px;
  width: 100%;

  input:focus,
  select:focus,
  button:focus {
    outline: none;
  }

  input[type="search"] {
    background: none;
    box-shadow: none;
    border: 1px solid $ay-black;
    height: 34px;
    padding-left: 0.625rem;
    width: 100%;
    font-family: $base-font;
    font-weight: 300;
  }

  input[type="submit"] {
    background: url(images/icons-2020/action/search.svg) no-repeat;
    background-size: 20px;
    background-position: center center;
    border: none;
    cursor: pointer;
    height: 100px;
    opacity: .8;
    text-indent: -9999px;
    width: 39px;
  }

  input::placeholder {
    color: transparent;
  }

  &__mobile {
    display: none;

    @media screen and (max-width: $tablet-portrait) {
      display: flex;
      margin: 0 24px 15px 24px;
      height: 36px;
      width: auto;

      input[type="search"] {
        &::placeholder {
          opacity: 1;
          color: $ay-general;
        }
      }
    }
  }
}

.ay-search-main {
  padding: 6.25rem 12%;

  @media screen and (max-width: $tablet-portrait) {
    padding: 0 0 1.25rem 0;
  }

  .ay-search {
    input[type="search"] {
      &::placeholder {
        opacity: 1;
        color: $ay-general;
      }
    }

    @media screen and (max-width: $tablet-portrait) {
      display: none;
    }
  }
}

.ay-search__mobile-holder {
  display: none;
  padding: 1.25rem;

  @media screen and (max-width: $tablet-portrait) {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    margin: 0 auto;
    max-width: 100%;
    width: calc(100% - 2.8em);
  }

  .ay-search {
    background: $ay-secondary-colors--medium-medium;
    padding: 1.125rem 0;

    input {
      &::placeholder {
        color: $ay-secondary-colors--extra-dark;
        opacity: 1;
      }
    }
  }

  .ay-button__secondary {
    font-size: 13px;
    font-weight: normal;
    margin: 20px 0 0 0;
    padding: 10px 0;
    width: 40%;
  }

  .ay-search__filter-holder {
    @media screen and (max-width: $tablet-portrait) {
      width: 100%;
      background: $ay-white;
      padding: 1.875rem 0 0 0;
    }

    select {
      appearance: none;
      background: $ay-secondary-colors--medium-medium url(images/arrow-select.svg) no-repeat 97% center;
      background-size: 15px;
      border-radius: 0;
      border: none;
      color: $ay-secondary-colors--extra-dark;
      font-size: 0.8125rem;
      outline: none;
      padding: 0.75rem;
      line-height:.95;

      &::-moz-focus-inner {
        border: 0;
      }

      option {
        &::-moz-focus-inner {
          border: 0;
        }
      }

      @media screen and (max-width: $tablet-portrait) {
        width: 100%;
      }
    }
  }
}

// Search Header
.ay-search-table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 40px 0 0;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;

  @media screen and (max-width: $tablet-portrait) {
    display: block;
    overflow: hidden;
    margin: 0;
  }

  thead {
    background-color: $ay-black;
    width: 100%;
    display: inline-block;

    th {
      color: $ay-white;
      font-size: 14px;
      font-weight: $semibold;
      margin: 0;
      padding: 12px;
      text-transform: uppercase;
      text-align: center;
      vertical-align: middle;
      width: 200px;

      @media screen and (max-width: 1024px) {
        width: auto;
      }

      @media screen and (max-width: $tablet-portrait) {
        display: table-row;
        with: auto;

        &:first-child {
          display: table-cell;
          overflow: hidden;
          padding: 10px 0 10px 20px;
          width: 100%;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          display: none;
          text-indent: -9999px;
          padding: 0;
          overflow: hidden;
          width: 0;
        }
      }
    }

    .ay-search-table__sort {
      color: $ay-white;
      text-decoration: none;

      &--active {
        text-decoration: underline;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__results {
    padding: 1.5em 0;

    @media screen and (max-width: $tablet-portrait) {
      padding: 1em 1.5em;
    }
  }
}

/*
Search Results

Markup: search-results.hbs

Styleguide Search.Search-Results
*/

// Search Listing

.ay-search-results {
  @media screen and (max-width: $tablet-portrait) {
    padding: 0 1.5em;
  }

  &__row {
    padding: 1.8em 0;
    border-bottom: 1px solid $ay-black;
    line-height: 1.5em;

    @media screen and (max-width: $tablet-portrait) {
      padding: 1em 0;
    }

    .ay-link-download {
      color: $ay-black;
      display: block;
      font-weight: 600;
      text-decoration: none;
      text-transform: uppercase;

      @media screen and (max-width: $tablet-portrait) {
        padding-top: 12px;
      }

      &::after {
        background-image: url(images/arrow-right-black.svg);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        content: ' ';
        display: inline-block;
        height: 14px;
        margin-bottom: -1px;
        margin-left: 5px;
        width: 18px;
      }
    }
  }

  .h4 {
    font-weight: $semibold;
    text-transform: none;
  }
}
