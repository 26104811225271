#___gcse_0 {

  .gsc-webResult.gsc-result {
    border-left: none;
  
    &:hover {
      border-left: 0;
    }
  }

  .gsc-result-info {
    @extend .ay-search-table__results;
  
    color: $ay-general;    
    font-family: $base-font;
    font-size: 16px;
    line-height: normal;
  }
  
  .gs-title {
    text-decoration: none;
  
    a {
      @extend .h4;
    
      font-family: $base-font;
      line-height: 1.3;
      margin-bottom: 10px;
      text-decoration: none;
      text-transform: none;
    }
  }

  .gs-bidi-start-align.gs-snippet {
    @extend .ay-search-results__row;

    color: $ay-general;    
    font-family: $base-font;
    font-size: 14px;
    padding: 10px 0 1.8em;
  }

  .gs-fileFormat,
  .gs-fileFormatType {
    color: $ay-primary-colors--medium;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
  }

  .gs-fileFormatType {

    &::after {
      background-image: url(images/arrow-right-red.svg);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      content: ' ';
      display: inline-block;
      height: 14px;
      margin-bottom: -1px;
      margin-left: 5px;
      width: 18px;
    }
  }

  .gs-bidi-start-align.gs-visibleUrl.gs-visibleUrl-long {
    display: none;
  }

  .gs-image {
    padding-right: 10px;
  }
}

/* Pagination */
.gsc-cursor-box.gs-bidi-start-align {
  @extend .ay-pagination;

  border-top: none;
}

.gsc-cursor {
  @extend .ay-pagination--pagination-list;
}

.gsc-cursor-page.gsc-cursor-current-page {
  color: $ay-primary-colors--medium-light;
  font-family: $base-font;
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 5px;
  padding-bottom: 0;
  text-shadow: none;
}

.gsc-cursor-page {
  color: $ay-general;
  font-family: $base-font;
  font-size: 14px;
  text-decoration: none;
}