/*
Typography

The primary typeface used across the AY Digital brand is Open Sans

Styleguide 01-Basics~03-Typography
*/

/*
Content Headings

Markup: headings.hbs

Styleguide 01-Basics~03-Typography.20-Headings
*/

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: $header-color;
  font-family: $header-font;
  font-size: $base-font-size;
  font-weight: normal; // for safari's sake
  margin: 0;
  line-height: 100%;
}

h1, .h1, h2, .h2, h3, .h3, .ay-bios-header, .ay-header-options .ay-header-site-title {
  &[lang|=pl] {
    font-family: $header-font-pl;
  }
}

h1, .h1, h2, .h2, h3, .h3, .ay-bios-header, .ay-header-options .ay-header-site-title {
  &[lang|=ru] {
    font-family: $header-font-ru;
  }
}

h1, .h1, h2, .h2, h3, .h3, .ay-bios-header, .ay-header-options .ay-header-site-title {
  &[lang|=vi] {
    font-family: $header-font-vi;
  }
}




.h1, h1 {
  font-size: $font-size-h1;
  @media screen and (max-width: 450px) {
    font-size: 12.3vw;
  }
}

.h2, h2 {
  font-size: $font-size-h2;
  line-height: 120%;
  padding: 0;

  @media print {
    font-size: 18px;
  }
}

.h3, h3 {
  font-family: $header-font;
  font-size: $font-size-h3;
  line-height: 120%;

  @media print {
    font-size: 18px;
  }
}

h3.ay-title-lts-h3 {
  color: $ay-secondary-colors--extra-dark;
  font-size: 51px;
  margin-top: 26px;
  margin-bottom: 15px;
}

.h3 ~ .h4:first-of-type {
  // padding-top: 20px;
}

.h4, h4 {
  font-family: $base-font;
  font-size: $font-size-h4;
  color: $ay-black;
  font-weight: 600;
  line-height: 120%;
  padding: 0;

  @media print {
    font-size: 16px;
  }

  &--gray {
    color: $ay-general;
  }
}

.h4 ~ p, h4 ~ p {
  margin-top: 5px;
}

.h4 ~ ul, .h5 ~ ul, h4 ~ ul, h5 ~ ul {
  margin-top: 15px;
}

.h5, h5 {
  font-family: $base-font;
  font-size: $font-size-h5;
  color: $ay-black;
  font-weight: 600;
  padding: 0;
  text-transform: uppercase;
}

.h5 ~ p, h5 ~ p {
  margin-top: 5px;
}

h6, .h6 {
  color: $ay-primary-colors--medium-light;
  font-weight: normal;
}

.ay-hr {
  border: 0;
  height: 0;
  border-top: 1px solid $ay-black;
  border-bottom: 1px solid $ay-black;
  margin: 2.25rem 0;
}

.ay-subtitle {
  border-bottom: 1px solid $ay-black;
  font-size: 45px;
  margin-bottom: 0.625rem;
  padding-bottom: 0.3125rem;
  line-height: 1.5;

  @media print {
    font-size: 24px;
  }
}

.ay-service-lts-title {
  color: $ay-black;
  text-align: center;
  padding-bottom: 25px;
  padding-top: 25px;
}
