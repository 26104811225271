/*
Icons

Markup: icon.hbs

Styleguide 01-Basics~04-Icons
*/

.ay-icons {

  background: $ay-white;

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-left: 0;
  }

  li{
    display: flex;
    width: 125px;
    min-width: 125px;
    height: 125px;
    border: $ay-black;
    border-width: 1px;
    border-style: solid;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    margin: 1rem;
    padding-top: 1rem;

    img {
      display: inline-block;
      height: auto;
      margin: 0 auto;
      width: 60px;
      max-width: 60px;
      max-height: 60px;
    }

    p {
      text-align: center;
      flex-basis: 100%;
    }

    &.reverse {
      background: $ay-secondary-colors--medium-medium-dark;
      color: $ay-primary-colors--light;
      &-black {
        background: $ay-black;
        color: $ay-white;
      }
    }

  }
}

/*
Infographics

Markup: info-graphic-icons.hbs

Styleguide 01-Basics~04-Icons.10-Infographics
*/


/*
Social

Markup: social-icons.hbs

Styleguide 01-Basics~04-Icons.20-Social
*/

/*
Favicons

Markup: favicons.hbs

Styleguide 01-Basics~04-Icons.30-Favicons

*/
